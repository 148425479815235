import React, { useEffect, useState } from "react";
import { Tabs, Typography, Row, Col, Form, Divider, Input, Select, Radio, Checkbox, Switch, Tooltip, notification, Modal } from "antd";
import { ExclamationCircleOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import moment from 'moment';

import api from "../../services/api";
import { useStateValue } from "../../state";
import { getEmpresa } from "../../services/auth";
import { manutencaoActions, selectPaginadoActions } from "../../actions";
import { Data, FormGW, FormularioDinamico, InputPreco, SelectPaginacao } from "../../components";
import { isObjetoDiffVazio, validarCnpj, validarCpf, consultaCNPJ, retornaSoNumeros, HasValue, validarTelefone } from "../../services/funcoes";
import { Endereco, Email, Telefone, Ocupacao, Nacionalidade, OutrosContatos } from '../pessoas/pages';
import { validarIE } from "../../services/funcoesIE";
import { trataRetornoFormularioDinamico, trataSetaDadosFormularioDinamico } from "../../services/funcoes";

export default function ManutencaoFornecedor({ formulario, carregando, aoSalvar, editando }) {

    const [pessoaJuridica, setPessoaJuridica] = useState(false);
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [dadosEstrangeiro, setDadosEstrangeiro] = useState(false);
    const [regimeTributario, setRegimeTributario] = useState([]);
    const [cpfCnpj, setCpfCnpj] = useState("");
    const [elementosFormulario, setElementosFormulario] = useState([]);
    const [listaCidades, setListaCidades] = useState(false);
    const [filtroCdd, setFiltroCdd] = useState(null);
    const [desabilitarCfpCnpj, setDesabilitarCpfCnpj] = useState(false);
    const [mask, setMask] = useState("");
    const [{ ui, manutencao }, dispatch] = useStateValue();

    const switchEstrangeiro = () => {
        setDadosEstrangeiro(!dadosEstrangeiro)
    };

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.for_id) {
            listaFornecedor(manutencao.dados.for_id);
        }
    }, []);

    useEffect(() => {
        if (ui.showDrawer && !!manutencao.dados && !!manutencao.dados.for_id && editando.editando) {
            let lista = [];
            if (!!manutencao.dados.pef_rguf) {
                lista.push({ name: "pef_rguf", campo: "Uf", value: manutencao.dados.pef_rguf });
                carregarCidades(manutencao.dados.pef_rguf);
            }
            if (!!manutencao.dados.pef_codigocidadenascimento) {
                lista.push({ name: "pef_codigocidadenascimento", campo: "IdCidade", value: manutencao.dados.pef_codigocidadenascimento });
            }
            if (lista.length > 0) {
                editando.setEditando(false);
                dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            }
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [ui.showDrawer, manutencao.dados]);

    useEffect(() => {
        if (!!manutencao.dados) {
            let _cpfCnpj = formulario.getFieldValue().cpfCnpj ? formulario.getFieldValue().cpfCnpj : manutencao.dados.cpfCnpj;
            _cpfCnpj = _cpfCnpj.replace(/[^\d]+/g, '');
            if (HasValue(_cpfCnpj)) {
                if (_cpfCnpj.length === 11) {
                    if (validarCpf(_cpfCnpj)) {
                        setDesabilitarCpfCnpj(true);
                    }
                }
                else if (_cpfCnpj.length === 14) {
                    if (validarCnpj(_cpfCnpj)) {
                        setDesabilitarCpfCnpj(true);
                    }
                } else {
                    setDesabilitarCpfCnpj(false);
                }
            }
            if (!!manutencao.dados.cpfCnpj) {
                if (manutencao.dados.cpfCnpj.length > 11) {
                    setPessoaJuridica(true);
                } else {
                    setPessoaJuridica(false);
                }
            }
        } else {
            setFocusCpfCnpj();
        }
    }, [manutencao.dados]);

    function setFocusCpfCnpj() {
        setTimeout(
            () => {
                try {
                    let element = document.getElementById("cpf");
                    if (element) {
                        element.focus();
                    }
                } catch (error) {
                    console.log('error :>> ', error);
                }
            }, 300);
    }
    const salvarCamposAdicionais = (values) => {

        var camposAdicionais = [];
        elementosFormulario?.map((elemento) => {
            values = trataRetornoFormularioDinamico(values, elemento);
            let fornecedorcampopersonalizados = { ...manutencao.dados };
            let forDados = fornecedorcampopersonalizados.fornecedorcampopersonalizado?.filter(f => f.cap_id == elemento.cap_id)[0];
            if ((!!forDados ? forDados.fcp_valor : "") !== (!!values[elemento.cap_nomeelemento] ? values[elemento.cap_nomeelemento] : "")) {
                camposAdicionais.push({ fcp_id: !!forDados ? forDados.fcp_id : null, for_id: values.for_id, cap_id: elemento.cap_id, fcp_valor: values[elemento.cap_nomeelemento] });
            }
        });
        return camposAdicionais;
    }

    const salvarManutencao = valores => {
        if (!!valores.pej_emailpessoacontato) {
            if (!validarEmail(valores.pej_emailpessoacontato)) {
                notification.warning({ message: 'Aviso', description: 'E-mail do contado inválido' });
                return false;
            }
        }
        let dadosform = formulario.getFieldValue();
        valores = { ...valores, ...dadosform };
        valores.pessoatelefones = !!manutencao.dados.telefones && manutencao.dados.telefones.length > 0 ? manutencao.dados.telefones : [];
        valores.enderecos = !!manutencao.dados.enderecos && manutencao.dados.enderecos.length > 0 ? manutencao.dados.enderecos : [];
        valores.pessoaemails = !!manutencao.dados.emails && manutencao.dados.emails.length > 0 ? manutencao.dados.emails : [];
        valores.pessoasocupacoes = !!manutencao.dados.ocupacoes && manutencao.dados.ocupacoes.length > 0 ? manutencao.dados.ocupacoes : [];
        valores.pessoaoutrocontatos = !!manutencao.dados.pessoaoutrocontatos && manutencao.dados.pessoaoutrocontatos.length > 0 ? manutencao.dados.pessoaoutrocontatos : [];
        valores.pessoanacionalidades = !!manutencao.dados.listaNacionalidade && manutencao.dados.listaNacionalidade.length > 0 ? manutencao.dados.listaNacionalidade : [];
        valores.pes_fisicajuridica = pessoaJuridica ? 2 : (dadosEstrangeiro ? 3 : 1);

        valores.cpfCnpj = valores.cpfCnpj.replaceAll('.', '').replaceAll('/', '').replaceAll('-', '');
        if (!!valores.idEstrangeiro && dadosEstrangeiro) {
            valores.isEstrangeiro = true;
            valores.pes_estrangeiro = true;
        } else {
            valores.isEstrangeiro = false;
            valores.pes_estrangeiro = false;

            if (valores.cpfCnpj.length === 11) {
                valores.pes_fisicajuridica = 1;
                valores.pef_cpf = valores.cpfCnpj;
                if (!validarCpf(valores.cpfCnpj)) {
                    notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                    return false;
                }
            } else if (valores.cpfCnpj.length === 14) {
                valores.pes_fisicajuridica = 2;
                valores.pej_cnpj = valores.cpfCnpj;
                if (!validarCnpj(valores.cpfCnpj)) {
                    notification.warning({ message: 'Aviso', description: 'CNPJ informado é inválido' });
                    return false;
                }
            } else {
                notification.warning({ message: 'Aviso', description: 'CPF/CNPJ informado é inválido' });
                return false;
            }
        }

        if (!!valores.cpfCnpj) {
            if (valores.pes_fisicajuridica === 1) {
                valores.pef_cpf = valores.cpfCnpj.replace(/\D/g, '');
                valores.pef_rguf = valores.enderecos?.filter((end) => (end.pee_enderecoprincipal === true))[0].est_sigla;
            } else {
                valores.pej_cnpj = valores.cpfCnpj.replace(/\D/g, '');
                valores.pej_inscuf = valores.enderecos.length > 0 ? valores.enderecos.filter((end) => (end.pee_enderecoprincipal === true))[0].est_sigla : '';
            }
        }
        if (!!valores.pej_inscricaoestadual && !validarIE(valores.pej_inscricaoestadual, valores.pej_inscuf)) {
            notification.warning({ message: 'Aviso', description: 'Inscrição Estadual informada é inválida' });
            return false;
        } else if (!!!valores.pej_inscricaoestadual) {
            valores.pej_inscricaoestadual = "";
        }

        valores.for_valorfretegratis = parseFloat(valores.for_valorfretegratis);
        valores.for_codigobancoagencia = !!valores.for_codigobancoagencia ? valores.for_codigobancoagencia : '';
        valores.pef_apelido = !!valores.pef_apelido ? valores.pef_apelido : '';
        valores.for_regimetributariofiscal = !!valores.regimeTributario ? valores.regimeTributario : null;
        valores.for_observacao = !!valores.for_observacao ? valores.for_observacao : '';
        valores.fornecedorcampopersonalizados = salvarCamposAdicionais(valores);
        if (!!formulario.getFieldValue().regimeTributario) {
            valores.regimeTributario = {
                key: formulario.getFieldValue().regimeTributario,
                value: regimeTributario.filter(item => (item.key === formulario.getFieldValue().regimeTributario))[0].value
            }
        }

        carregando(true);

        if (!!valores.for_id) {
            api.put(`Fornecedor/Editar`, valores).then(
                (res) => {
                    notification.success({ message: 'Aviso', description: `Fornecedor editado com sucesso!` });
                }
            ).catch(
                (erro) => {
                    notification.error({ message: 'Erro ao salvar dados do Fornecedor.', description: JSON.stringify(erro.response) });
                    console.log(erro);
                }
            ).finally(
                () => {
                    carregando(false);
                    aoSalvar();
                }
            );
        } else {

            api.post(`Fornecedor/Incluir`, valores).then(
                (res) => {
                    notification.success({ message: 'Aviso', description: `Fornecedor incluído com sucesso!` });
                }
            ).catch(
                (erro) => {
                    notification.error({ message: 'Erro ao salvar dados do Fornecedor.', description: JSON.stringify(erro.response) });
                    console.log(erro);
                }
            ).finally(
                () => {
                    carregando(false);
                    aoSalvar();
                }
            );
        }
    };
    function carregarCidades(UfEstado) {
        if (!!UfEstado) {
            setListaCidades(true);
            setFiltroCdd(` &UfEstado=${UfEstado}`);
        }
        else {
            setListaCidades(false);
        }
    }

    function validarEmail(email) {
        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        return reg.test(email);
    };


    function carregaCamposPersonalizados() {
        api.get(`CamposPersonalizados/ListarTodos?TipoEntidade=2&PageNumber=1&PageSize=1000&Order=+cap_ordem`).then(res => {
            if (res.status === 200) {
                setElementosFormulario(res.data.items);
            }
        }).catch(
            (erro) => {
                console.log(erro);
                alert('Erro ao carregar Campos personalizados!');
            }
        );
    };

    function carregaDadosCamposAdicionais() { //Campos Adicionais
        let camposPersonalizados = { ...manutencao.dados };
        camposPersonalizados?.fornecedorcampopersonalizado?.map(d => {
            let elemento = elementosFormulario.filter(f => f.cap_id === d.cap_id)[0];
            if (!!elemento)
                trataSetaDadosFormularioDinamico(formulario, elemento, d.fcp_valor);
        })
    }

    useEffect(() => { if (!!elementosFormulario && elementosFormulario.length > 0) carregaDadosCamposAdicionais(); }, [elementosFormulario]);
    useEffect(() => {
        if (manutencao?.dados?.fornecedorcampopersonalizado?.length > 0)
            carregaDadosCamposAdicionais();
    }, [manutencao.dados]);

    useEffect(() => {
        popularDados();
    }, []);

    function popularDados() {
        listarRegimeTrib();
        carregaCamposPersonalizados();
    };

    function listarRegimeTrib() {
        api.get('Enum/Listar?nome=RegimeTributarioFiscal').then(
            (res) => {
                setRegimeTributario(res.data);
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        )
    };

    function validarCpfExiste(cpfCnpj) {
        api.get(`PessoaFisica/VerificarCpfExistente?cpf=${cpfCnpj}`).then(
            res => {
                if (res.status === 200) {
                    if (res.data.pes_fornecedor) {
                        Modal.confirm({
                            title: 'Aviso',
                            icon: <ExclamationCircleOutlined />,
                            content: "O CPF informado já esta cadastrado no sistema!\n Deseja atualizar seus dados?",
                            okText: 'Sim',
                            cancelText: 'Não',
                            centered: true,
                            onOk() {
                                listarFornecedorCpfCnpj(cpfCnpj);
                            },
                            onCancel() {
                                formulario.resetFields();
                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                            }
                        });
                    } else {
                        listarFornecedorCpfCnpj(cpfCnpj);
                    }
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function validarCnpjExiste(cpfCnpj) {
        api.get(`PessoaJuridica/VerificarCnpjExistente?cnpj=${cpfCnpj}`).then(
            res => {
                if (res.status === 200) {
                    if (res.data.pes_fornecedor) {
                        Modal.confirm({
                            title: 'Aviso',
                            icon: <ExclamationCircleOutlined />,
                            content: "O CNPJ informado já esta cadastrado no sistema!\n Deseja atualizar seus dados?",
                            okText: 'Sim',
                            cancelText: 'Não',
                            centered: true,
                            onOk() {
                                listarFornecedorCpfCnpj(cpfCnpj);
                            },
                            onCancel() {
                                formulario.resetFields();
                                dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                            }
                        });
                    } else {
                        listarFornecedorCpfCnpj(cpfCnpj);
                    }
                } else {
                    receitaWS(cpfCnpj);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function listaFornecedor(for_id) {
        if (!!!for_id) {
            return;
        }
        api.get(`Fornecedor/BuscarFornecedor/${for_id}`).then(
            (res) => {
                let valores = res.data;
                if (res.data.pes_fisicajuridica === 2) {
                    setPessoaJuridica(true);
                } else {
                    setPessoaJuridica(false);
                }
                if (!!res.data && isObjetoDiffVazio(res.data)) {
                    valores.for_datainclusao = !!valores.for_datainclusao ? moment(valores.for_datainclusao) : null;
                    valores.for_dataalteracao = !!valores.for_dataalteracao ? moment(valores.for_dataalteracao) : null;
                    valores.pes_datainclusao = !!valores.pes_datainclusao ? moment(valores.pes_datainclusao) : null;
                    valores.pes_dataalteracao = !!valores.pes_dataalteracao ? moment(valores.pes_dataalteracao) : null;
                    valores.pef_datanascimento = !!valores.pef_datanascimento ? moment(valores.pef_datanascimento) : null;
                    valores.pef_dataalteracao = !!valores.pef_dataalteracao ? moment(valores.pef_dataalteracao) : null;
                    valores.pef_rgdataexpedicao = !!valores.pef_rgdataexpedicao ? moment(valores.pef_rgdataexpedicao) : null;
                    valores.pej_datafundacao = !!valores.pej_datafundacao ? moment(valores.pej_datafundacao) : null;
                    valores.id_rguf = valores.est_id;
                    valores.regimeTributario = !!valores.for_regimetributariofiscal ? valores.for_regimetributariofiscal : null;
                    formulario.setFieldsValue(valores);
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    const receitaWS = (cpfCnpj) => {
        if (!HasValue(formulario.getFieldsValue().pes_nome)) {
            consultaCNPJ(cpfCnpj).then((retorno) => {
                let dados = retorno;
                if (dados.status === "OK" && dados.situacao === "ATIVA") {
                    let dadosForm = {};

                    if (dados.efr !== "") {
                        dadosForm.pes_orgaopublico = true;
                    }
                    dadosForm.pes_nome = dados.nome;
                    if (!!dados.abertura) {
                        let dia = dados.abertura.substring(0, 2);
                        let mes = dados.abertura.substring(3, 5);
                        let ano = dados.abertura.substring(6, 10);
                        dadosForm.pej_datafundacao = moment(new Date(ano, mes, dia));
                    }
                    dadosForm.pej_nomefantasia = dados.fantasia;


                    let listaEnderecos = [];
                    let tipoEndereco = {};
                    tipoEndereco.pais = "BRASIL";
                    tipoEndereco.pse_id = 33;
                    tipoEndereco.tpe_id = 4;
                    tipoEndereco.tpe_descricao = "COMERCIAL";
                    tipoEndereco.pee_numero = dados.numero;
                    tipoEndereco.pee_ativo = true;
                    tipoEndereco.pee_enderecoprincipal = true;
                    tipoEndereco.cep_cep = retornaSoNumeros(dados.cep);
                    tipoEndereco.pee_complemento = dados.complemento;
                    tipoEndereco.bai_nome = dados.bairro;
                    tipoEndereco.log_logradouro = dados.logradouro;
                    tipoEndereco.cid_descricao = dados.municipio;
                    tipoEndereco.est_sigla = dados.uf;
                    tipoEndereco.enderecoCompleto = tipoEndereco.log_logradouro + ', ' + tipoEndereco.pee_numero + ' - ' + tipoEndereco.bai_nome + ' - ' + tipoEndereco.pee_complemento + ' - ' + tipoEndereco.cep_cep + ' - ' + tipoEndereco.cid_descricao + ' - ' + tipoEndereco.uf;
                    listaEnderecos.push(tipoEndereco);

                    let listaTelefones = [];
                    if (dados.telefone !== "" && validarTelefone(retornaSoNumeros(dados.telefone).substring(0, 10))) {
                        let tpt = {};
                        tpt.tpt_id = 1;
                        tpt.tpt_descricao = "COMERCIAL";
                        tpt.psc_ativo = true;
                        tpt.psc_numero = retornaSoNumeros(dados.telefone).substring(0, 10);
                        tpt.psc_principal = true;
                        listaTelefones.push(tpt);
                    }

                    let listaEmails = [];
                    if (dados.email !== "" && validarEmail(dados.email)) {
                        let email = {};
                        email.pem_email = dados.email;
                        email.pem_emailprincipal = true;
                        email.pem_enviarboleto = false;
                        email.pem_enviarcartacobranca = false;
                        email.pem_enviarnfe = false;
                        email.pem_enviarpromocao = false;
                        email.pem_enviarvencparcela = false;
                        email.pem_ativo = true;
                        listaEmails.push(email);
                    }
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ telefones: listaTelefones }, ...{ emails: listaEmails }, ...{ enderecos: listaEnderecos } } } });
                    formulario.setFieldsValue(dadosForm);

                }
                if (dados.status === "OK" && dados.situacao === "BAIXADA") {
                    notification.warning({ message: 'Aviso', description: 'Empresa/CNPJ ' + dados.situacao + ' na receita!' });
                }
            }, (erro) => {
                console.log('ERRO:', erro);
            });
        }
    }

    function listarFornecedorCpfCnpj(cpfCnpj) {
        cpfCnpj = cpfCnpj.replaceAll('-', '').replaceAll('/', '').replaceAll('.', '');
        if (cpfCnpj === null || cpfCnpj === "") {
            return false;
        }
        api.get(`Fornecedor/BuscarFornecedorCpfCnpj/${cpfCnpj}`).then(
            (res) => {
                let valores = res.data;
                if (res.data.pes_fisicajuridica === 2) {
                    setPessoaJuridica(true);
                } else {
                    setPessoaJuridica(false);
                }
                if (!!res.data && isObjetoDiffVazio(res.data)) {
                    valores.for_ativo = true;
                    valores.for_datainclusao = !!valores.for_datainclusao ? moment(valores.for_datainclusao) : null;
                    valores.for_dataalteracao = !!valores.for_dataalteracao ? moment(valores.for_dataalteracao) : null;
                    valores.pes_datainclusao = !!valores.pes_datainclusao ? moment(valores.pes_datainclusao) : null;
                    valores.pes_dataalteracao = !!valores.pes_dataalteracao ? moment(valores.pes_dataalteracao) : null;
                    valores.pef_datanascimento = !!valores.pef_datanascimento ? moment(valores.pef_datanascimento) : null;
                    valores.pef_dataalteracao = !!valores.pef_dataalteracao ? moment(valores.pef_dataalteracao) : null;
                    valores.pef_rgdataexpedicao = !!valores.pef_rgdataexpedicao ? moment(valores.pef_rgdataexpedicao) : null;
                    valores.pej_datafundacao = !!valores.pej_datafundacao ? moment(valores.pej_datafundacao) : null;
                    valores.id_rguf = valores.est_id;
                    valores.regimeTributario = !!valores.for_regimetributariofiscal ? valores.for_regimetributariofiscal : 1;
                    let lista = [];
                    if (!!valores.pef_rguf) {
                        lista.push({ name: "pef_rguf", campo: "Uf", value: valores.pef_rguf });
                    }
                    if (!!valores.pef_codigocidadenascimento) {
                        lista.push({ name: "pef_codigocidadenascimento", campo: "IdCidade", value: valores.pef_codigocidadenascimento });
                    }
                    if (lista.length > 0) {
                        editando.setEditando(false);
                        dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                    }
                    formulario.setFieldsValue(valores);
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: valores } });
                    editando.setEditando(true);
                } else {
                    if (cpfCnpj.length === 14) {
                        setPessoaJuridica(true);
                        receitaWS(cpfCnpj);
                    }
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
                if (cpfCnpj.length === 14) {
                    setPessoaJuridica(true);
                    receitaWS(cpfCnpj);
                }
            }
        );
    }

    function validarFisicoJuridico(event) {
        let valorCampo = event.currentTarget.value.replace(/\D/g, '');
        if (valorCampo.length === 11) {
            if (!validarCpf(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                formulario.setFieldsValue({ cpfCnpj: "" })
                setFocusCpfCnpj();
                return false;
            }
        } else if (valorCampo.length === 14) {
            if (!validarCnpj(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CNPJ informado é inválido' });
                formulario.setFieldsValue({ cpfCnpj: "" })
                setFocusCpfCnpj();
                return false;
            }
        }
        setPessoaJuridica(valorCampo.length === 14);
    };

    function validarCpfCnpjExistente(cpfCnpj) {
        if (cpfCnpj) {
            if (cpfCnpj.length === 14) {
                validarCnpjExiste(cpfCnpj);
            } else if (cpfCnpj.length === 11) {
                validarCpfExiste(cpfCnpj);
            } else {
                notification.warning({ message: 'Aviso', description: 'O CFP/CNPJ informado é inválido. Deve conter 11 ou 14 dígitos' });
            }
        } else {
            notification.warning({ message: 'Aviso', description: 'O CFP/CNPJ não foi informado' });
        }
    };

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formulario} name="manutencaoFornecedor" onFinish={salvarManutencao}>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Fornecedor" key="1">
                        <Row align="middle" gutter={[8, 8]}>
                            <Col span={24}>
                                <Form.Item hidden name="for_id">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="pes_id">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="pef_id">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="pej_id">
                                    <Input />
                                </Form.Item>
                                <Form.Item hidden name="for_codigo">
                                </Form.Item>
                                <Form.Item hidden name="for_datainclusao">
                                </Form.Item>
                                <Form.Item hidden name="pej_inscuf" />
                            </Col>
                            {!desabilitarCfpCnpj &&
                                <Col span={24}>
                                    <Form.Item>
                                        <Switch onClick={switchEstrangeiro} /> Fornecedor Estrangeiro
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <Row gutter={[8, 0]}>
                            {!!dadosEstrangeiro === false &&
                                <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={3}>
                                    <Form.Item label="CPF/CNPJ" name="cpfCnpj" rules={[{ required: true, message: 'Informe o CPF ou CNPJ.' }]}>
                                        <CpfCnpj className='ant-input ant-input-sm'
                                            placeholder="Informe o CPF ou CNPJ"
                                            id="cpf"
                                            value={cpfCnpj}
                                            disabled={desabilitarCfpCnpj}
                                            onBlur={valor => { validarFisicoJuridico(valor); validarCpfCnpjExistente(valor.currentTarget.value.replace(/\D/g, '')) }}
                                            onChange={(event, type) => {
                                                setCpfCnpj(event.target.value);
                                                setMask(type === "CPF");
                                            }} />
                                    </Form.Item>
                                </Col>
                            }
                            {!!dadosEstrangeiro &&
                                <Col xs={24} sm={7} md={5} lg={4} xl={4} xxl={3}>
                                    <Form.Item label="ID Estrangeiro" id="idEstrangeiro">
                                        <Input placeholder="Informe o documento"
                                            suffix={
                                                <Tooltip title="Documento de Identificação, Passaporte">
                                                    <QuestionCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                                </Tooltip>
                                            } />
                                    </Form.Item>
                                </Col>
                            }
                            <Col xs={24} sm={17} md={10} lg={10} xl={10} xxl={pessoaJuridica ? 8 : 12}>
                                <Form.Item label="Nome" name="pes_nome" rules={[{ required: true, message: 'Informe o nome' }]}>
                                    <Input placeholder="Informe o nome" />
                                </Form.Item>
                            </Col>
                            {!pessoaJuridica &&
                                <>
                                    <Col xs={24} sm={8} md={7} lg={5} xl={6} xxl={5}>
                                        <Form.Item label="Sexo" name="pef_sexo">
                                            <Radio.Group options={[{ label: 'Feminino', value: 'F' }, { label: 'Masculino', value: 'M' }]} />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={5} lg={5} xl={4} xxl={4}>
                                        <Data label="Data de Nascimento" name="pef_datanascimento" />
                                    </Col>
                                    <Col xs={24} sm={8} md={6} lg={5} xl={5} xxl={5}>
                                        <Form.Item label="Apelido" name="pef_apelido">
                                            <Input placeholder="Informe o Apelido (Opcional)" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={6} lg={4} xl={5} xxl={5}>
                                        <Form.Item label="RG" name="pef_rg">
                                            <Input maxLength={10}
                                                placeholder="Informe o RG" />
                                        </Form.Item>
                                    </Col>
                                </>
                            }
                            {!pessoaJuridica &&
                                <>
                                    <Col xs={24} sm={8} md={7} lg={6} xl={5} xxl={6}>
                                        <Form.Item label="Órgão Expedidor" name="pef_rgorgaoexpedidor">
                                            <Input placeholder="Informe órgão expedidor" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={4}>
                                        <Data label="Data de Expedição" name="pef_rgdataexpedicao" />
                                    </Col>
                                    <Col xs={24} sm={8} md={5} lg={5} xl={5} xxl={4}>
                                        <Form.Item label="UF RG" name="pef_rguf">
                                            <SelectPaginacao url="Estado/Listar" placeholder="Selecione um Estado" allowClear={true} form={formulario} selecionarRegUnico="est_sigla" nameLabel="est_sigla" nameValue="pef_rguf" onClearFunction={(UfEstado) => carregarCidades(UfEstado)} onSelectFunction={(UfEstado) => carregarCidades(UfEstado)}
                                                conteudo={
                                                    est => (
                                                        <Select.Option value={est.est_sigla} key={est.est_id}>{est.est_sigla} - {est.est_descricao}</Select.Option>)
                                                } />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={4}>
                                        <Form.Item label="Cidade Nascimento" name="pef_codigocidadenascimento" disabled={listaCidades == true}>
                                            <SelectPaginacao url="Cidade/Listar" placeholder="Selecione a Cidade de Nascimento" nameLabel="cid_descricao" nameValue="pef_codigocidadenascimento" form={formulario} filtroExtra={filtroCdd} selecionarRegUnico="cid_id"
                                                conteudo={
                                                    cid => (<Select.Option value={cid.cid_id} key={cid.cid_id}>{cid.cid_id} - {cid.cid_descricao}</Select.Option>)
                                                } />
                                        </Form.Item>
                                    </Col>
                                </>
                            }
                            {!pessoaJuridica &&
                                <>
                                    <Col xs={24} sm={10} md={8} lg={6} xl={6} xxl={6}>
                                        <Form.Item label="Nome do Pai" name="pef_nomepai">
                                            <Input placeholder="Informe o nome do pai do cliente" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={6}>
                                        <Form.Item label="Nome da Mãe" name="pef_nomemae">
                                            <Input placeholder="Informe o nome da mãe do cliente" />
                                        </Form.Item>
                                    </Col>

                                </>
                            }
                            {pessoaJuridica &&
                                <>
                                    <Col xs={24} sm={16} md={9} lg={10} xl={10} xxl={9}>
                                        <Form.Item label="Nome Fantasia" name="pej_nomefantasia">
                                            <Input placeholder="Informe o Nome Fantasia" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                        <Data label="Data de Fundação" name="pej_datafundacao" />
                                    </Col>
                                    <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                        <Form.Item label="IE" name="pej_inscricaoestadual">
                                            <Input placeholder="Informe a Inscrição Estadual" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                        <Form.Item label="Inscrição Municipal" name="pej_inscricaomunicipal">
                                            <Input placeholder="Informe a Inscrição Municipal" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={5} lg={5} xl={6} xxl={4}>
                                        <Form.Item label="Regime Tributário Fiscal" name="regimeTributario" required rules={[{ required: true, message: 'Selecione o Regime Tributário' }]}>
                                            <Select placeholder="Selecione um Regime Tributário Fiscal">
                                                {regimeTributario.map(reg => (
                                                    <Select.Option value={reg.key} key={reg.key}>{reg.value}</Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={7} lg={7} xl={6} xxl={6}>
                                        <Form.Item label="Contato" name="pej_pessoacontato">
                                            <Input placeholder="Informe a Pessoa para Contato" />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                                        <Form.Item label="E-mail do Contato" name="pej_emailpessoacontato">
                                            <Input placeholder="Informe o e-mail do Contato" />
                                        </Form.Item>
                                    </Col>
                                </>
                            }
                            <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={4}>
                                <Data label="Data de Cadastro" name="for_datainclusao" disabled />
                            </Col>
                            <Col xs={24} sm={8} md={5} lg={4} xl={4} xxl={4}>
                                <Data label="Data de Alteração" name="for_dataalteracao" disabled />
                            </Col>
                            <Col>
                                <Form.Item name="for_ativo" valuePropName="checked" initialValue={true} className="t-mob-573">
                                    <Checkbox> Fornecedor Ativo? </Checkbox>
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item name="pef_produtorrural" valuePropName="checked" initialValue={false} className="t-mob-573">
                                    <Checkbox > Produtor Rural </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className="m-t-16">
                            <Row align="middle">
                                <Col span={24}>
                                    <Typography.Title level={3}>
                                        <img src={require("../../assets/i-financeiro.png").default} alt="Informações Financeiras" /> Informações Financeiras
                                    </Typography.Title>
                                    <Divider orientation="left" plain>
                                        Adicione as informações financeiras aqui.
                                    </Divider>
                                </Col>
                            </Row>
                            <Row gutter={[8, 0]} className="m-t-8">
                                <Col xs={24} sm={8} md={8} lg={5} xl={4}>
                                    <InputPreco label={'Valor do Frete (R$)'} name='for_valorfretegratis' />
                                </Col>
                                <Col xs={24} sm={8} md={8} lg={4} xl={4}>
                                    <Form.Item label="Prazo Médio de Entrega" name="for_prazomedioentrega">
                                        <Input placeholder="Informe o Prazo Médio de Entrega" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={8} lg={5} xl={5}>
                                    <InputPreco label='Preço Mínimo de Compra (R$)' name='for_valorminimocompra' />
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={5} xl={5}>
                                    <Form.Item label="Código do Banco/Agência" name="for_codigobancoagencia">
                                        <Input placeholder="Informe o Código do Banco/Agência" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={12} lg={5} xl={6}>
                                    <Form.Item label="Conta Corrente" name="for_numerocontacorrente">
                                        <Input placeholder="Informe a Conta Corrente" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Row align="middle" gutter={[0, 8]}>
                            <Col span={24}>
                                <Form.Item label="Observação" name="for_observacao">
                                    <Input.TextArea placeholder="Informe uma observação" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row align="middle" gutter={[0, 8]}>
                            {pessoaJuridica &&
                                <Col span={24}>
                                    <Form.Item name="pej_baixadoreceita" valuePropName="checked" initialValue={false}>
                                        <Checkbox> Baixado da Receita? </Checkbox>
                                    </Form.Item>
                                </Col>}
                        </Row>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Endereços" key="2">
                        <Endereco />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Telefones" key="3">
                        <Telefone />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="E-mails" key="4">
                        <Email />
                    </Tabs.TabPane>
                    {!pessoaJuridica &&
                        <Tabs.TabPane tab="Ocupações" key="5">
                            <Ocupacao />
                        </Tabs.TabPane>
                    }
                    {!!dadosEstrangeiro &&
                        <Tabs.TabPane tab="Nacionalidade" key="6">
                            <Nacionalidade />
                        </Tabs.TabPane>
                    }
                    <Tabs.TabPane tab="Outros Contatos" key="7">
                        <OutrosContatos />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Campos Adicionais" key="8">
                        <Row align="middle" gutter={[0, 16]}>
                            <Col span={24}>
                                <Typography.Title level={3}>
                                    <img src={require("../../assets/i-grade.png").default} alt="Campos Adicionais" /> Campos Adicionais
                                </Typography.Title>
                                <Divider orientation="left" plain>
                                    Adicione abaixo as informações adicionais.
                                </Divider>
                            </Col>
                            <Col span={24}>
                                <FormularioDinamico formulario={formulario} parametrosFiltros={elementosFormulario} />
                            </Col>
                        </Row>
                    </Tabs.TabPane>
                </Tabs>
            </FormGW>
        </div>
    );
}