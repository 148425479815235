import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Steps, Input, notification, Modal, Switch, Typography } from "antd";
import { ExclamationCircleOutlined, ExclamationOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from '../../services/api';
import { useStateValue } from "../../state";
import { listagemActions, selectPaginadoActions } from "../../actions";
import { MaskFormat } from "../../ValueObjects";
import { SelectIncluir, FormGW, Data, InputCpfCnpj, DrawerCliente, SelectPaginacao } from '../../components';
import DrawerVendedores from "../../components/drawerVendedores";
import { TabItens, TabTransporte, TabFaturamento } from './tabPage';
import { ModalEmail, ModalEndereco, ModalEnderecoNew, ModalTelefone } from "../../components/modals";
import { getNomeUsuario, getIdUsuario, getEmpresa, getLocal } from '../../services/auth';
import { compararDatas, imprimirRelatorioJasper, validaForm, calculaValorProporcional, isObjetoDiffVazio } from "../../services/funcoes";
import SelectPaginado from "../../components/selectPaginado";
import { CalcularImpostoItem } from "../../services/calculaImposto";
import { Divider } from "@material-ui/core";

export default function ManutencaoPedidos({ formulario, carregando, aoSalvar, clientes, dadosOrcamento, mostraBtnSalvar, proximaPag, pages, exibirDrawerPedidos }) {
    const [{ manutencao, ui }, dispatch] = useStateValue();
    const [dadosEndereco, setDadosEndereco] = useState([]);
    const [dadosEnderecoLocal, setDadosEnderecoLocal] = useState({});
    const [dadosContribuinte, setDadosContribuinte] = useState({});
    const [listaTelefone, setListaTelefone] = useState([]);
    const [dadosTelefone, setDadosTelefone] = useState([]);
    const [dadosVeiculos, setDadosVeiculos] = useState([]);
    const [dadosEmail, setDadosEmail] = useState([]);
    const [listaEmail, setListaEmail] = useState([]);
    const [pessoaFisica, setPessoaFisica] = useState(true);
    const [abreModalEndereco, setAbreModalEndereco] = useState(false);
    const [abreModalTelefone, setAbreModalTelefone] = useState(false);
    const [abreModalEmail, setAbreModalEmail] = useState(false);
    const [formularioTelefone] = Form.useForm();
    const [formularioEmail] = Form.useForm();
    const [dadosItens, setDadosItens] = useState([]);
    const [totalPedido, setTotalPedido] = useState(0);
    const [dadosFormasPagamento, setDadosFormasPagamento] = useState([]);
    const [emiteTransferencia, setEmiteTransferencia] = useState(false);
    const [tipoPreco, setTipoPreco] = useState([]);
    const [filiaisEmpresa, setFiliaisEmpresa] = useState([]);
    const [custo, setCusto] = useState({});
    const [abrirDrawerVendedores, setAbrirDrawerVendedores] = useState(false);
    const [formVendedores] = Form.useForm();
    const [openDrawerCliente, setOpenDrawerCliente] = useState(false);

    const [listaIndicadorPresenca, setListaIndicadorPresenca] = useState([]);
    const switchTransferencia = () => {
        setEmiteTransferencia(!emiteTransferencia);
    };

    useEffect(() => {
        if (exibirDrawerPedidos) {
            if (!manutencao.dados && clientes) {
                const { formasPagamento, itens, ...restoClientes } = clientes;
                manutencao.dados = {
                    ...restoClientes,
                    formaPgto: formasPagamento.map((forma, index) => {
                        if (index === 0) {
                            const { orf_id, orc_id, orf_valortotal, acrescimo, desconto, disabled, orf_observacao, total, ...restoForma } = forma;
                            return { ...restoForma, pfp_valor: total };
                        }
                        return forma;
                    }),
                    itens: itens.map((item, index) => {
                        const { disabled, grade, oit_desconto, oit_id, oit_observacao, oit_qtde, oit_valortotal, oit_valorunitario, orc_id, ump_casasdecimais, ump_id, ...restoItem } = item;
                        return {
                            ...restoItem,
                            descricaograde: grade,
                            pdi_quantidade: oit_qtde,
                            pdi_valortotal: oit_valortotal,
                            pdi_valorunitario: oit_valorunitario,
                            valorItemDesconto: oit_valortotal,
                            pdi_numeroitem: index + 1
                        };
                    })
                };
                setDadosFormasPagamento(manutencao.dados.formaPgto)
                dadosOrcamento.formasPagamento = manutencao.dados.formaPgto;
                formulario.setFieldsValue({ ven_id: dadosOrcamento.ven_id });
                if (clientes.pes_id) {
                    formulario.setFieldsValue({ pes_id: clientes.pes_id });
                } else {
                    formulario.setFieldsValue({ pes_id: clientes.pes_nome });
                    notification.warning({ message: 'Aviso!', description: 'Realize o cadastro do Cliente' });
                }
            }
            carregarDadosInciais();
            if (!!manutencao.dados) {
                formulario.setFieldsValue(manutencao.dados);
                if (!!manutencao.dados.pes_id) {
                    formulario.setFieldsValue({ pes_id: manutencao.dados.pes_id });
                    carregarDadosCliente(manutencao.dados.pes_id)
                }
                if (!!manutencao.dados.loc_id_transferencia) {
                    formulario.setFieldsValue({ loc_id_transferencia: manutencao.dados.loc_id_transferencia });
                }
                if (!!manutencao.dados.ped_id_duplicar) {
                    carregarDadosAlt(manutencao.dados.ped_id_duplicar);
                } else if (!!manutencao.dados.ped_id) {
                    carregarDadosItems(manutencao.dados.ped_id);
                    if (!!manutencao.dados.itens) {
                        setDadosItens(manutencao.dados.itens);
                    }
                }
                if (clientes) {
                    setDadosItens(manutencao.dados.itens);
                }
                setDadosFormasPagamento(manutencao.dados.formaPgto)

                popularOperacaoFiscal(manutencao.dados.ope_id);
            }
            if (!!manutencao.dados && !!manutencao.dados.ped_datainclusao) {
                formulario.setFieldsValue({ ped_datainclusao: moment(manutencao.dados.ped_datainclusao) });
            } else if (!!!formulario.getFieldsValue().ped_datainclusao) {
                formulario.setFieldsValue({ ped_datainclusao: moment(new Date()) });
            }
        }
        if (exibirDrawerPedidos && !!manutencao.dados && !!manutencao.dados.pes_id) {
            let lista = [];
            if (!!manutencao.dados.pes_id) {
                lista.push({ name: "pes_id", campo: "Filtro", value: manutencao.dados.pes_id });
            }
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            formulario.setFieldsValue(manutencao.dados);
        }
    }, [exibirDrawerPedidos]);

    useEffect(() => {
        if (filiaisEmpresa.length > 0 && !!formulario.getFieldValue().loc_id_transferencia) {
            buscarDadosLocal(formulario.getFieldValue().loc_id_transferencia);
        }
    }, [filiaisEmpresa]);

    useEffect(() => {
        if (tipoPreco.length > 0) {
            api.get(`ParametroEmpresa/BuscarParametros`).then(retornoParametrosEmp => {
                if (retornoParametrosEmp.status === 200) {
                    let dados = {};
                    if (!!retornoParametrosEmp.data.par_custopadraonftransferencia) {
                        let custo = tipoPreco.filter((custo) => { return custo.key === retornoParametrosEmp.data.par_custopadraonftransferencia });
                        if (custo.length > 0) {
                            dados = custo[0];
                            if (!(!!formulario.getFieldValue().ped_precoutilizado)) {
                                formulario.setFieldsValue({ ped_precoutilizado: custo[0].key });
                            }
                        }
                    }
                    setCusto(dados);
                }
            }).catch((erro) => console.error(erro));
        }
    }, [tipoPreco]);

    useEffect(() => {
        if (!!proximaPag.proximaPag || proximaPag.proximaPag === 0) {
            validarTrocaPg(proximaPag.proximaPag);
        }
    }, [proximaPag.proximaPag]);


    useEffect(() => {
        if (dadosTelefone.length > 0) {
            dadosTelefone[0].pes_id = formulario.getFieldValue().pes_id;
            api.post(`PessoaTelefone/Incluir`, dadosTelefone[0]).then(
                (res) => {
                    if (res.status === 200) {
                        carregarDadosCliente(formulario.getFieldValue().pes_id);
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            ).finally(
                () => {
                    setDadosTelefone([]);
                }
            )
        }
    }, [dadosTelefone]);

    useEffect(() => {
        if (dadosEmail.length > 0) {
            dadosEmail[0].pes_id = formulario.getFieldValue().pes_id;
            api.post('PessoaEmail/Incluir', dadosEmail[0]).then(
                (res) => {
                    if (res.status === 200) {
                        carregarDadosCliente(formulario.getFieldValue().pes_id);
                    }
                }
            ).catch(
                (erro) => {
                    console.log(erro);
                }
            ).finally(
                () => {
                    setDadosEmail([]);
                }
            );
        }
    }, [dadosEmail]);

    useEffect(() => {
        if (emiteTransferencia) {
            setPessoaFisica(false);
        }
    }, [emiteTransferencia]);

    async function carregarDadosInciais() {
        let listaIndicadorPresenca = await api.get('Enum/Listar?nome=IndicadorPresenca');
        if (listaIndicadorPresenca.status === 200) {
            setListaIndicadorPresenca(listaIndicadorPresenca.data);
        }
        let retornoSerie = await api.get(`SerieFiscal/Listar?CodigoModeloDocFiscal=55&EmitenteDocumento=0`);
        if (retornoSerie.status === 200) {
            if (retornoSerie.data.items.length === 1) {
                formulario.setFieldsValue({ ser_id: retornoSerie.data.items[0].ser_id });
            }
        }
        api.get(`Enum/Listar?nome=TipoPrecoUtilizar`).then(
            retornoTipoPreco => {
                if (retornoTipoPreco.status === 200) {
                    retornoTipoPreco.data = retornoTipoPreco.data.filter((item) => { return item.key > 0 });
                    setTipoPreco(retornoTipoPreco.data);
                }
            }
        ).catch(
            (erro) => console.error(erro)
        );

        api.get(`Local/ListarLocalUsuario?codigoEmpresa=${getEmpresa()}`).then(
            retornoFiliais => {
                if (retornoFiliais.status === 200) {
                    setFiliaisEmpresa(retornoFiliais.data.items.filter((item) => { return item.loc_id !== parseInt(getLocal()) }));
                }
            }
        ).catch(
            (erro) => console.error(erro)
        );

        api.get(`dadosContribuinte/Buscar`).then(
            (res) => {
                if (res.status === 200) {
                    setDadosContribuinte(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    async function validarTrocaPg(proxPagina) {
        let paginaAtual = pages.tagPages;
        let listaValidacoes = [];

        if (paginaAtual < proxPagina) {
            switch (paginaAtual) {
                case 0:
                    listaValidacoes = emiteTransferencia ? [
                        { nome: 'loc_id_transferencia', obrigatorio: true, label: 'Local' },
                        { nome: 'ped_precoutilizado', obrigatorio: true, label: 'Preço' },
                        { nome: 'ope_id', obrigatorio: true, label: 'Operação' }
                    ] : [
                        { nome: 'enderecoCliente', obrigatorio: true, label: 'Endereço' },
                        { nome: 'pes_id', obrigatorio: true, label: 'Cliente' },
                        { nome: 'ope_id', obrigatorio: true, label: 'Operação' }
                    ];
                    break;
                case 1:
                    if (!!dadosItens && dadosItens.length === 0) {
                        notification.warning({ message: 'Aviso', description: 'Favor inserir ao menos um item no pedido!' });
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                    break;
                case 2:
                    if (compararDatas(formulario.getFieldValue().ped_dataprevisaoentrega, formulario.getFieldValue().ped_datainclusao, '<')) {
                        notification.warning({ message: 'Aviso', description: 'Data da previsa não pode ser menor que a data de emissão!' });
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                    await validarTransporte(dadosItens);
                    break;
            }
            if (validaForm(formulario, listaValidacoes)) {
                pages.setTagPages(proxPagina);
            } else {
                proximaPag.setProximaPag(paginaAtual);
            }
        } else {
            pages.setTagPages(proxPagina);
        }
    };

    function carregarDadosAlt(ped_id) {
        mostraBtnSalvar(false);
        api.get(`PedidoItem/ListarItensPedidoADuplicar?codigoPedido=${ped_id}`).then(
            (res) => {
                res.data.forEach(item => {
                    let grade = '';
                    let valorTotal = 0;
                    item.valorItemDesconto = parseFloat(item.pdi_valortotal);
                    valorTotal = item.pdi_valorunitario * item.pdi_quantidade;
                    item.percdesc = (valorTotal > 0 ? parseFloat(((item.pdi_valordesconto / valorTotal) * 100).toFixed(2)) : 0);
                    if (!!item.atributos && item.atributos.length > 0) {
                        item.atributos.forEach((atrib) => {
                            grade += atrib.mgp_descricao + ', ';
                        });
                        item.grade = grade.substr(0, grade.lastIndexOf(','));
                    }
                });
                setDadosItens(res.data);
                formulario.setFieldsValue({ ped_id: null });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function carregarDadosItems(ped_id) {
        api.get(`PedidoItem/ListarItensPedido?codigoPedido=${ped_id}`).then(
            (res) => {
                let pdi_valorfrete = 0;
                res.data.forEach(item => {
                    let grade = '';
                    let valorTotal = 0;
                    item.valorItemDesconto = parseFloat(item.pdi_valortotal);
                    valorTotal = item.pdi_valorunitario * item.pdi_quantidade;
                    item.percdesc = (valorTotal > 0 ? parseFloat(((item.pdi_valordesconto / valorTotal) * 100).toFixed(2)) : 0);
                    pdi_valorfrete += parseFloat(!!item.pdi_valorfrete ? item.pdi_valorfrete : 0);
                    if (!!item.atributos && item.atributos.length > 0) {
                        item.atributos.forEach((atrib) => {
                            grade += atrib.mgp_descricao + ', ';
                        });
                        item.grade = grade.substr(0, grade.lastIndexOf(','));
                    }
                });
                setDadosItens(res.data);
                formulario.setFieldsValue({ pdi_valorfrete: pdi_valorfrete });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    function validaValorPago(valores) {
        let retorno = false;
        let valorPago = 0;
        if (dadosFormasPagamento.length > 0) {
            dadosFormasPagamento.forEach(formaPgto => { valorPago += parseFloat(formaPgto.pfp_valor) });
            if (valorPago === parseFloat(valores.ped_valorTotal) || valorPago === parseFloat(formulario.getFieldValue().ped_valorTotal)) {
                retorno = true;
            }
        } else {
            retorno = true;
        }
        return retorno;
    };

    function incluirCliente(retorno) {
        if (retorno) {
            formulario.setFieldsValue({ pes_id: retorno });
            let lista = [];
            lista.push({ name: "pes_id", campo: "Filtro", value: retorno });
            if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
            carregarDadosCliente(retorno);
        }
    }

    async function salvarManutencao(valores) {
        if (!validaValorPago(valores)) {
            notification.warning({ message: 'Aviso', description: "Valor de pagamentos menor que total do pedido!" });
            return;
        }
        let endereco = {};
        valores.pedidoitens = dadosItens;
        if (dadosFormasPagamento.length > 0) {
            valores.pedidoformapgtos = dadosFormasPagamento;
        } else if (emiteTransferencia) {
            valores.pedidoformapgtos = [];
        } else {
            let arrayDados = [];
            let dados = {};
            dados.pfp_valor = parseFloat(formulario.getFieldValue().ped_valorTotal);
            dados.cpg_id = !!valores.cpg_id ? valores.cpg_id : null;
            dados.fpg_id = valores.fpg_id;
            arrayDados.push(dados);
            valores.pedidoformapgtos = arrayDados;
        }
        if (emiteTransferencia) {
            endereco = dadosEnderecoLocal;
            valores.pes_id = null;
            let filial = filiaisEmpresa.find((filial) => { return filial.loc_id === valores.loc_id_transferencia });
            valores.pes_telefone = !!filial.loc_telefone ? filial.loc_telefone : "";
            valores.pes_nroestabelecimento = filial.loc_numeroestabelecimento;
        } else {
            let cliente = (await api.get(`Cliente/BuscarByIdPessoa/${formulario.getFieldValue().pes_id}`)).data;
            endereco = dadosEndereco.filter(endereco => (endereco.pee_id === formulario.getFieldValue().enderecoCliente))[0];
            valores.pes_nroestabelecimento = (!!endereco.pee_numero ? endereco.pee_numero : 'SN');
            valores.pes_id = endereco.pes_id;
            valores.loc_id_transferencia = null;
            valores.pes_telefone = !!formulario.getFieldValue().pes_telefone ? formulario.getFieldValue().pes_telefone : !!formulario.getFieldValue().telefoneCliente ? formulario.getFieldValue().telefoneCliente : "";
            valores.cli_id = cliente.cli_id;
        }
        valores.emp_id = getEmpresa();
        valores.loc_id = getLocal();
        valores.ven_id = formulario.getFieldValue().ven_id;
        valores.ped_ativo = true;
        valores.ped_datainclusao = formulario.getFieldValue().ped_datainclusao;
        valores.ped_dataprevisaoentrega = formulario.getFieldValue().ped_dataprevisaoentrega;
        valores.ped_datavalidade = formulario.getFieldValue().ped_datainclusao;
        valores.ped_observacao = formulario.getFieldValue().ped_observacao;
        valores.ped_valorfrete = parseFloat(formulario.getFieldValue().pdi_valorfrete);
        valores.ped_valorTotalitens = parseFloat(totalPedido);
        valores.ped_valorTotalAcre = (!!formulario.getFieldValue().ped_valorTotalAcre ? parseFloat(formulario.getFieldValue().ped_valorTotalAcre) : 0);
        valores.ped_valorTotalDesc = (!!formulario.getFieldValue().ped_valorTotalDesc ? parseFloat(formulario.getFieldValue().ped_valorTotalDesc) : 0);
        valores.ped_valoroutrasdespesas = (!!formulario.getFieldValue().pdi_valoroutrasdespesas ? parseFloat(formulario.getFieldValue().pdi_valoroutrasdespesas) : 0);
        valores.ped_valorTotal = parseFloat(valores.ped_valorTotal);
        valores.valorFrete = parseFloat(formulario.getFieldValue().pdi_valorfrete);
        valores.valorDesconto = (!!formulario.getFieldValue().ped_valorTotalDesc ? parseFloat(formulario.getFieldValue().ped_valorTotalDesc) : 0);
        valores.valorAcrescimo = (!!formulario.getFieldValue().ped_valorTotalAcre ? parseFloat(formulario.getFieldValue().ped_valorTotalAcre) : 0);
        valores.valorOutrasDespesas = (!!formulario.getFieldValue().pdi_valoroutrasdespesas ? parseFloat(formulario.getFieldValue().pdi_valoroutrasdespesas) : 0);
        valores.pes_bairro = endereco.bai_nome;
        valores.pes_cep = endereco.cep_cep;
        valores.pes_cidade = endereco.cid_descricao;
        valores.pes_endereco = endereco.log_logradouro;
        valores.pes_uf = endereco.est_sigla;
        valores.ped_responsavelpedido = getNomeUsuario();
        valores.ped_modalidadefrete = formulario.getFieldValue().ped_modalidadefrete;
        valores.usu_id = parseInt(getIdUsuario());
        if (valores.duplicar) {
            valores.ped_id = null;
        }
        carregando(true);
        if (!!valores.ped_id && !valores.ped_id_duplicar) {
            valores.stp_id = formulario.getFieldValue().stp_id;
            api.put(`Pedido/Editar`, valores).then(
                (res) => {
                    notification.success({ message: 'Operação concluída', description: `Pedido editado com sucesso!` });
                    Modal.confirm({
                        title: "Imprimir?",
                        icon: <ExclamationCircleOutlined />,
                        content: `Deseja imprimir o pedido?`,
                        okText: 'Sim',
                        cancelText: 'Não',
                        okType: "primary",
                        centered: true,
                        onOk() {
                            imprimirRelatorioJasper(29, { idPedido: res.data.ped_id });
                        }
                    });
                    aoSalvar();
                    recarregarDados();
                }
            ).catch(
                (erro) => {
                    if (!!erro.response && !!erro.response.data && !!erro.response.data.Message) {
                        Modal.warning({
                            content: erro.response.data.Message,
                            title: 'Aviso',
                        });
                    }
                    console.log(erro);
                }
            ).finally(
                () => {
                    carregando(false);
                }
            );
        } else {
            if (!!valores.ped_id_duplicar) {
                if (dadosFormasPagamento.length > 0) {
                    valores.pedidoformapgtos.forEach((pgto) => {
                        pgto.pfp_id = null;
                    });
                }
                valores.pedidoitens.forEach((item) => {
                    item.ped_id = null;
                    item.pdi_id = null;
                })
            }
            api.post(`Pedido/Incluir`, valores).then(
                (res) => {
                    notification.success({ message: 'Operação concluída', description: `Pedido incluído com sucesso!` });
                    Modal.confirm({
                        title: "Imprimir?",
                        icon: <ExclamationCircleOutlined />,
                        content: `Deseja imprimir o pedido?`,
                        okText: 'Sim',
                        cancelText: 'Não',
                        okType: "primary",
                        centered: true,
                        onOk() {
                            imprimirRelatorioJasper(29, { idPedido: res.data.ped_id });
                        }
                    });
                    aoSalvar();
                    recarregarDados();
                }
            ).catch(
                (erro) => {
                    if (!!erro.response && !!erro.response.data) {
                        Modal.warning({
                            content: erro.response.data,
                            title: 'Aviso',
                        });
                    }
                    console.log(erro);
                }
            ).finally(
                () => {
                    carregando(false);
                }
            );
        }
    }

    function recarregarDados() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+ped_datainclusao' } });
    };

    async function carregarDadosCliente(codigo) {
        if (!!codigo) {
            let cliente = (await api.get(`Cliente/BuscarByIdPessoa/${codigo}`)).data;
            if (!!cliente) {
                formulario.setFieldsValue({ 'pej_inscricaoestadual': cliente.pej_inscricaoestadual });
                retornarDadosCPFCNPJ(cliente);
                if (cliente.emails.length > 0) {
                    setListaEmail(cliente.emails);
                    formulario.setFieldsValue({ dadosEmailCliente: cliente.emails[0].pem_email })
                }
                if (cliente.enderecos.length > 0) {
                    setDadosEndereco(cliente.enderecos);
                    formulario.setFieldsValue({ enderecoCliente: cliente.enderecos[0].pee_id })
                } else {
                    setDadosEndereco([]);
                    if (!!formulario.getFieldValue().pes_id) {
                        Modal.confirm({
                            title: 'Endereço não encontrado',
                            icon: <ExclamationOutlined />,
                            content: 'Deseja incluir um endereço para este cliente?',
                            okText: 'Sim',
                            cancelText: 'Não',
                            centered: true,
                            onOk() {
                                setAbreModalEndereco(true);
                            }
                        });
                    }
                }
                if (cliente.telefones.length > 0) {
                    setListaTelefone(cliente.telefones);
                    formulario.setFieldsValue({ telefoneCliente: cliente.telefones[0].psc_numero })
                }
            } else {
                limparCliente();
            }
        } else {
            limparCliente();
        }
    };

    function limparCliente() {
        formulario.setFieldsValue({
            cpf: null,
            cnpj: null,
            telefoneCliente: null,
            enderecoCliente: null,
            dadosEmailCliente: null
        })
        setDadosEndereco([]);
        setDadosEmail([]);
        setListaTelefone([]);
    }

    async function retornarDadosCPFCNPJ(cliente) {
        if (!!cliente) {
            if (!!cliente.pef_cpf) {
                setPessoaFisica(true);
                formulario.setFieldsValue({ cpf: cliente.pef_cpf, cli_id: cliente.cli_id });
            } else if (!!cliente.pej_cnpj) {
                setPessoaFisica(false);
                formulario.setFieldsValue({ cnpj: cliente.pej_cnpj, cli_id: cliente.cli_id });
            }
        }
    };
    function adicionarEndereco() {
        if (!!formulario.getFieldValue().pes_id) {
            setAbreModalEndereco(true);
        } else {
            notification.warning({ message: 'Aviso', description: "Favor informar um cliente!" })
        }
    };

    function onCloseModalEndereco(dados) {
        setAbreModalEndereco(false);
    };

    function adicionarTelefone() {
        if (!!formulario.getFieldValue().pes_id) {
            setAbreModalTelefone(true);
        } else {
            notification.warning({ message: 'Aviso', description: "Favor informar um cliente!" })
        }
    };

    function onCloseModalTelefone() {
        setAbreModalTelefone(false);
    };

    function onCloseModalEmail() {
        setAbreModalEmail(false);
    };

    function adicionarEmail() {
        if (!!formulario.getFieldValue().pes_id) {
            setAbreModalEmail(true);
        } else {
            notification.warning({ message: 'Aviso', description: "Favor informar um cliente!" });
        }
    };

    async function buscarDadosLocal(loc_id_transferencia) {
        if (!!loc_id_transferencia) {
            let filial = filiaisEmpresa.find((filial) => { return filial.loc_id === loc_id_transferencia });
            let retornoEndereco = await api.get(`LogradouroCidade/BuscarLogradouroCidade?codigoLogradouroCidade=${filial.lcd_id}&estrangeiro=0`)
            let endereco = "";
            if (retornoEndereco.status === 200) {
                setDadosEnderecoLocal(retornoEndereco.data);
                endereco = retornoEndereco.data.log_logradouro;
            }
            setEmiteTransferencia(true);
            formulario.setFieldsValue({ loc_email: filial.loc_email.endereco, loc_telefone: filial.loc_telefone, cnpj: filial.loc_cnpj.numero, log_logradouro: endereco });
        }
    };

    function alterarCusto(e) {
        if (!!e) {
            let custo = tipoPreco.find((custo) => { return custo.key === e });
            if (!!custo) {
                setCusto(custo);
            }
        }
    };

    function fecharDrawerVendedores() {
        setAbrirDrawerVendedores(false);
    };

    async function validarTransporte() {
        await rateioFrete();
        await recalcularImpostos();
    }

    async function recalcularImpostos() {
        if (dadosItens.length > 0) {
            let total = 0;
            let totalSt = 0;
            let totalIPI = 0;
            let possuiIe = !!formulario.getFieldValue().pej_inscricaoestadual ? true : false;
            let cpf = formulario.getFieldValue()?.cpf || '';
            let cnpj = formulario.getFieldValue()?.cnpj || '';
            let fisicaJuridica = cnpj.length === 14 ? 2 : cpf.length == 11 ? 1 : 3;
            let ufDestino = dadosEndereco && dadosEndereco.length > 0 ? dadosEndereco[0].est_sigla : dadosContribuinte.est_sigla
            let ufOrigem  = dadosContribuinte.est_sigla;
            for (const p of dadosItens) {
                try {
                    let impostos = await CalcularImpostoItem(p.orm_id, p.cst_id, p.cso_i, ufOrigem, ufDestino, p.pdi_quantidade, p.pdi_valorunitario, p.pdi_valorfrete, p.pdi_valoracrescimo,
                        p.pdi_valordesconto, p.impostos.retornoICMS.ret_aliquotaIcms, p.impostos.retornoICMS.ret_aliquotaIcms, p.impostos.retornoICMS.ret_percReducaoIcms,
                        p.impostos.retornoICMSST.ret_aliquotaIcmsSt, p.impostos.retornoICMSST.ret_percMva, p.impostos.retornoICMSST.ret_percReducaoIcmsST, null,
                        p.ncm_percentual, fisicaJuridica, formulario.getFieldValue().ntf_consumidorfinal, possuiIe, formulario.getFieldValue().ped_indicadorpresenca);
                    if (isObjetoDiffVazio(impostos)) {
                        p.pdi_valoricmsst = impostos.retornoICMSST.ret_valorIcmsSt;
                        p.pdi_valoripi = impostos.retornoIPI.ret_valorIpi;
                        p.total = (p.pdi_quantidade * p.pdi_valorunitario) - p.pdi_valordesconto + p.pdi_valoracrescimo + p.pdi_valoripi + p.pdi_valoricmsst;
                        p.impostos = impostos;
                    }
                    totalSt += parseFloat(p.pdi_valoricmsst);
                    totalIPI += parseFloat(p.pdi_valoripi);
                    total += parseFloat(p.total);
                } catch (error) {
                    console.error("Error item:", error);
                }
            }
            formulario.setFieldsValue({ valorTotalSt: totalSt, valorTotalIPI: totalIPI });
        }
    }

    async function rateioFrete() {
        let _numeroItem = 1;
        let _totalFrete = 0;
        if (dadosItens.length > 0) {
            let _valorFrete = parseFloat(formulario.getFieldValue().pdi_valorfrete);
            if (_valorFrete > 0) {
                // Ordena os itens em ordem de maior valor crescente
                var itens = dadosItens.sort((a, b) => a.total - b.total);
                // Calcular a soma da multiplicação dos campos
                let somaTotal = dadosItens.reduce((total, item) => {
                    let resultadoMultiplicacao = item.pdi_valorunitario * item.pdi_quantidade;
                    return total + resultadoMultiplicacao;
                }, 0);
                itens.forEach(item => {
                    let valorTotalItem = parseFloat(item.pdi_valorunitario * item.pdi_quantidade);
                    item.pdi_valorfrete = calculaValorProporcional(valorTotalItem, _valorFrete, somaTotal);
                    if (_numeroItem == dadosItens.length) {
                        item.pdi_valorfrete = parseFloat((_valorFrete - _totalFrete).toFixed(2))
                    }
                    _totalFrete += item.pdi_valorfrete;
                    _numeroItem++;
                });
            }
        }
        rateioAcrescimo(dadosItens);
    }

    async function rateioAcrescimo(dadosItens) {
        let _numeroItem = 1;
        let _totalAcrescimo = 0;
        if (dadosItens.length > 0) {
            let _valorAcrescimo = parseFloat(formulario.getFieldValue().pdi_valoroutrasdespesas);
            if (_valorAcrescimo > 0) {
                // Ordena os itens em ordem de maior valor crescente
                var itens = dadosItens.sort((a, b) => a.total - b.total);
                // Calcular a soma da multiplicação dos campos
                let somaTotal = dadosItens.reduce((total, item) => {
                    let resultadoMultiplicacao = item.pdi_valorunitario * item.pdi_quantidade;
                    return total + resultadoMultiplicacao;
                }, 0);
                itens.forEach(item => {
                    item.nfi_valorseguro = 0;
                    let valorTotalItem = parseFloat(item.pdi_valorunitario * item.pdi_quantidade);
                    let valoracresc = calculaValorProporcional(valorTotalItem, _valorAcrescimo, somaTotal);
                    //transforma o valor acrescimo em porcentagem
                    item.percacresc = (valoracresc * 100) / valorTotalItem;
                    item.pdi_percacresc = (valoracresc * 100) / valorTotalItem;
                    _totalAcrescimo += item.percacresc;
                    _numeroItem++;
                });
            }
        }
    }

    async function popularOperacaoFiscal(id) {
        if (!!id) {
            api.get(`OperacaoFiscal/Buscar/${id}`).then(
                res => {
                    if (res.status === 200) {
                        formulario.setFieldsValue({ ped_indicadorpresenca: res.data.ope_indicadorpresenca });
                    }
                }
            ).catch(
                error => { console.log(error); }
            )
        }
    };

    return (
        <div>
            <Form layout="vertical" form={formulario} name="manutencaoPedido" onFinish={salvarManutencao}>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
                        <Switch checked={emiteTransferencia} onClick={switchTransferencia} /> Emite Nota de Transferência
                    </Col>
                </Row>
                <Row gutter={[8, 0]} className="m-t-16">
                    <Form.Item hidden name="ped_id"></Form.Item>
                    <Form.Item hidden name="ped_id_duplicar"></Form.Item>
                    <Form.Item hidden name="trn_id"></Form.Item>
                    <Form.Item hidden name="pes_idtransportador"></Form.Item>
                    <Form.Item hidden name="pes_nometransportador"></Form.Item>
                    <Form.Item hidden name="trv_id"></Form.Item>
                    <Form.Item hidden name="trv_placa"></Form.Item>
                    <Form.Item hidden name="std_id"></Form.Item>
                    {emiteTransferencia && <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                        <Form.Item label="Local Destino" name="loc_id_transferencia" rules={[{ required: true, message: 'Informe o Local' }]}>
                            <Select allowClear placeholder="Selecione o Destinatário" onChange={(loc_id_transferencia) => { buscarDadosLocal(loc_id_transferencia) }} showSearch optionFilterProp="children">
                                {filiaisEmpresa.map((filial) => (
                                    <Select.Option value={filial.loc_id} key={filial.loc_id}>
                                        {filial.loc_descricao}
                                        {(!!filial.loc_cnpj ? <div>{MaskFormat(filial.loc_cnpj.numero, '', true)}</div> : '')}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    }
                    {emiteTransferencia && <Col xs={24} sm={12} md={4} lg={4} xl={4}>
                        <Form.Item label="Selecione o Preço" name="ped_precoutilizado" rules={[{ required: true, message: 'Informe o Preço a Utilizar' }]}>
                            <Select allowClear placeholder="Selecione o Preço a Utilizar" showSearch optionFilterProp="children" onChange={(e) => { alterarCusto(e) }}>
                                {tipoPreco.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>}
                    <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                        <Data disabled label="Data de Emissão" name="ped_datainclusao" />
                    </Col>
                    <Col xs={24} sm={8} md={6} lg={6} xl={6}>
                        <Form.Item label="Operação" name="ope_id" rules={[{ required: true, message: 'Informe a Operação' }]}>
                            <SelectPaginado url="OperacaoFiscal/ListarOperacaoFiscal?SomenteAtivo=true&EntradaSaida=1" placeholder="Selecione a Operação Fiscal" form={formulario} name="ope_id" onChangeFunction={(idOperacao) => popularOperacaoFiscal(idOperacao)} conteudo={
                                ope => (<Select.Option value={ope.ope_id} key={ope.ope_id}>{ope.ope_descricao}</Select.Option>)
                            }
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={6} lg={6} xl={6}>
                        <Form.Item label="Indicador de Presença de Comprador" name="ped_indicadorpresenca" rules={[{ required: true, message: "Informe o Indicador de Presença" }]}>
                            <Select showSearch optionFilterProp="children" placeholder="Selecione Indicador de Presença do Comprador">
                                {listaIndicadorPresenca?.map(item => (
                                    <Select.Option key={item.key} value={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={7} lg={8} xl={8}>
                        <Form.Item name="ven_id" label="Vendedor">
                            <SelectPaginado url="Vendedor/Listar" placeholder="Selecione um Vendedor" form={formulario} name="ven_id" conteudo={
                                pv => (<Select.Option value={pv.ven_id} key={pv.key}>{pv.pes_nome}</Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={[0, 16]}>
                    <Col span={24}>
                        <Steps size="small" type="navigation" current={pages.tagPages}>
                            <Steps.Step title="Cliente" />
                            <Steps.Step title="Itens" />
                            <Steps.Step title="Transporte" />
                            <Steps.Step title="Faturamento" />
                        </Steps>
                    </Col>
                    <Col span={24}>
                        {pages.tagPages === 0 &&
                            <div className="m-t-16">
                                <Row gutter={[8, 0]}>
                                    {!emiteTransferencia && <Col xs={24} sm={24} md={10} lg={10} xl={10}>
                                        <Form.Item label="Nome do Cliente" name="pes_id">
                                            <SelectPaginacao url="Cliente/Listar"
                                                allowClear
                                                placeholder="Selecione um Cliente"
                                                form={formulario}
                                                nameLabel="pes_nome"
                                                nameValue="pes_id"
                                                onChangeFunction={(dados) => carregarDadosCliente(dados)}
                                                funcaoIncluir={() => setOpenDrawerCliente(true)}
                                                conteudo={
                                                    pc => (<Select.Option value={pc.pes_id} key={pc.key}>{pc.cli_codigo} - {pc.pes_nome} <br />
                                                        {(!!pc.pej_cnpj ? `CNPJ.: ${MaskFormat(pc.pej_cnpj, '', true)}` : !!pc.pef_cpf ? `CPF.: ${MaskFormat(pc.pef_cpf, '', true)}` : '')}
                                                    </Select.Option>)
                                                }
                                            />
                                        </Form.Item>
                                    </Col>}
                                    {pessoaFisica && <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                                        <InputCpfCnpj cpf label="CPF" name="cpf" />
                                    </Col>}
                                    {!pessoaFisica && <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                                        <InputCpfCnpj label="CNPJ" name="cnpj" />
                                    </Col>}
                                    <Col xs={24} sm={6} md={6} lg={4} xl={4}>
                                        <Form.Item label="Inscrição Estadual" name="pej_inscricaoestadual">
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={8} md={6} lg={6} xl={6}>
                                        {emiteTransferencia ? <Form.Item name="loc_email" label="E-mail" >
                                            <Input disabled />
                                        </Form.Item> : <SelectIncluir dadosSelect={listaEmail}
                                            funcaoBotao={() => { adicionarEmail() }}
                                            campoDescricao={'pem_email'}
                                            campoValue={'pem_id'}
                                            campoKey={'pem_id'}
                                            placeHolder={'Selecione um E-mail'}
                                            labelFormItem={'E-mail'}
                                            nameFormItem={'dadosEmailCliente'} />}
                                    </Col>
                                    <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                                        {emiteTransferencia ? <Form.Item name="loc_telefone" label="Telefone" >
                                            <Input disabled />
                                        </Form.Item> : <SelectIncluir dadosSelect={listaTelefone}
                                            funcaoBotao={() => { adicionarTelefone() }}
                                            campoDescricao={'psc_numero'}
                                            campoValue={'psc_id'}
                                            campokey={'psc_id'}
                                            placeHolder={'Selecione um Telefone'}
                                            labelFormItem={'Telefone'}
                                            nameFormItem={'telefoneCliente'} />}
                                    </Col>
                                </Row>
                                <Row gutter={[8, 8]} className="m-t-16">
                                    <Col span={24}>
                                        <Typography.Title level={3}>
                                            <img src={require("../../assets/i-endereco.png").default} alt="Endereço" /> Endereço
                                        </Typography.Title>
                                        <Divider />
                                    </Col>
                                </Row>
                                <Row gutter={[8, 0]} className="m-t-8">
                                    <Col xs={24} sm={16} md={14} lg={14} xl={14}>
                                        {emiteTransferencia ? <Form.Item name="log_logradouro" label="Endereço" >
                                            <Input disabled />
                                        </Form.Item> : <SelectIncluir dadosSelect={dadosEndereco}
                                            funcaoBotao={() => { adicionarEndereco() }}
                                            campoDescricao={['log_logradouro', 'cid_descricao', 'est_sigla', 'tpe_descricao']}
                                            campoValue={'pee_id'}
                                            campoKey={'pee_id'}
                                            placeHolder={"Selecione um Endereço"}
                                            labelFormItem={'Endereço'}
                                            nameFormItem={'enderecoCliente'}
                                            rulesFormItem={[{ required: true, message: 'Informe o Endereço' }]}
                                        />}
                                    </Col>
                                </Row>
                            </div>
                        }
                        {pages.tagPages === 1 &&
                            <TabItens formulario={formulario}
                                dadosEndereco={emiteTransferencia ? dadosEnderecoLocal : dadosEndereco.filter(endereco => (endereco.pee_id === formulario.getFieldValue().enderecoCliente))[0]}
                                dadosTabela={{ dadosItens, setDadosItens }}
                                totalPedido={{ totalPedido, setTotalPedido }} custo={emiteTransferencia ? custo : {}}
                                dadosOperacao={formulario.getFieldValue().ope_id}
                            />
                        }
                        {pages.tagPages === 2 &&
                            <TabTransporte form={formulario} dadosVeiculos={{ dadosVeiculos, setDadosVeiculos }} valorTotalPedido={totalPedido} />
                        }
                        {pages.tagPages === 3 &&
                            <TabFaturamento form={formulario}
                                dadosOrcamento={dadosOrcamento}
                                valorTotalPedido={totalPedido}
                                listaFormaPagamento={dadosFormasPagamento}
                                setListaFormaPagamento={setDadosFormasPagamento}
                                emiteTransferencia={emiteTransferencia} />
                        }
                    </Col>
                </Row>
            </Form>

            <ModalEndereco form={formulario} listaEndereco={{ dadosEndereco, setDadosEndereco }} exibirModalEndereco={abreModalEndereco} fecharModalEndereco={() => { onCloseModalEndereco() }} />
            <ModalTelefone form={formularioTelefone} listaTelefones={{ dadosTelefone, setDadosTelefone }} exibirModalTelefone={abreModalTelefone} fecharModalTelefone={() => { onCloseModalTelefone() }} />
            <ModalEmail form={formularioEmail} listaEmail={{ dadosEmail, setDadosEmail }} exibirModalEmail={abreModalEmail} fecharModalEmail={() => { onCloseModalEmail() }} />
            <DrawerVendedores form={formVendedores} exibirDrawerVendedores={abrirDrawerVendedores} fecharDrawerVendedores={fecharDrawerVendedores} />
            <DrawerCliente setCodigoCliente={incluirCliente} openDrawer={openDrawerCliente} setOpenDrawer={setOpenDrawerCliente} dadosOrcamento={dadosOrcamento} />
        </div>
    );

}