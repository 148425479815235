import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Card, Col, Divider, Form, InputNumber, Modal, Row, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { FormasPagamento, InputPreco } from '../../components';
import { validarNumeros } from '../../services/funcoes';
import { useStateValue } from '../../state';

export default function FaturamentoNFE({ form, dadosItens, dadosOperacao }) {
    const [valorNota, setValorNota] = useState(0);
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaFormaPagamento, setListaFormaPagamento] = useState(!!manutencao.dados && !!manutencao.dados.faturamento && manutencao.dados.faturamento.length > 0 ? manutencao.dados.faturamento : [])
    const [controlModal, setControlModal] = useState(false);
    useEffect(() => {
        calcularFaturamento();
    }, [manutencao.dados])

    function calcularFaturamento() {
        let formulario = form.getFieldValue();
        let subTotal = 0;
        dadosItens.forEach((item) => {
            subTotal += item.total;
        });
        form.setFieldsValue({ subTotalNotaFiscal: subTotal });
        form.setFieldsValue({ valorFrete: validarNumeros(formulario.valorFrete) });
        form.setFieldsValue({ valorSeguro: validarNumeros(formulario.valorSeguro) });

        setValorNota(parseFloat(subTotal) + parseFloat(formulario.valorFrete) + parseFloat(formulario.valorSeguro) + parseFloat(formulario.valorOutrasDespesas));
    };

    useEffect(() => {
        recalcularTotal();
    }, [valorNota]);

    function calcularDesconto(campo) {
        let formulario = form.getFieldValue();
        let valor = validarNumeros(formulario.valorDesconto);
        let perc = validarNumeros(formulario.percentualDesconto);
        let tot = parseFloat(valorNota);
        let result = 0;

        if (listaFormaPagamento.length > 0 && !!!controlModal) {
            setControlModal(true);
            Modal.confirm({
                title: 'Aviso',
                icon: <ExclamationCircleOutlined />,
                content: 'Tem certeza que deseja adicionar desconto? As formas de pagamento terão que ser incluídas novamente.',
                okText: 'Sim',
                cancelText: 'Não',
                centered: true,
                onOk() {
                    setListaFormaPagamento([]);
                },
                onCancel() {
                    form.setFieldsValue({ valorDesconto: 0, percentualDesconto: 0, valorTotal: listaFormaPagamento[0].pfp_valor });
                }
            });
        }


        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ valorDesconto: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                form.setFieldsValue({ percentualDesconto: result.toFixed(2) });
            }

        }
        recalcularTotal();
    };

    function calcularAcrescimo(campo) {
        let formulario = form.getFieldValue();
        let valor = validarNumeros(formulario.valorAcrescimo);
        let perc = validarNumeros(formulario.percentualAcrescimo);
        let tot = (!!valorNota ? parseFloat(valorNota) : 0);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ valorAcrescimo: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                form.setFieldsValue({ percentualAcrescimo: result.toFixed(2) });
            }
        }
        recalcularTotal();
    };

    function recalcularTotal() {
        let formulario = form.getFieldValue();
        let valor = 0;
        if (!!formulario) {
            valor = valorNota - validarNumeros(formulario.valorDesconto) + validarNumeros(formulario.valorAcrescimo);
            form.setFieldsValue({ valorTotal: valor.toFixed(2) });
            let valorPago = 0;
            if (!!manutencao.dados && !!manutencao.dados.faturamento) {
                manutencao.dados.faturamento.forEach((dadosFat) => {
                    valorPago += parseFloat(dadosFat.pfp_valor);
                    if (listaFormaPagamento.length == 0 || form.getFieldValue().valorDesconto > 0);
                    {
                        valorPago = 0;
                    }
                });
            }
            form.setFieldsValue({ pfp_valor: (valor - valorPago).toFixed(2) });
        }
    };

    function vendedorDescMaxFinal() {
        let totalDesconto = 0;
        let totalValorDescontoItens = 0;
        dadosItens.forEach((itens) => {
            totalValorDescontoItens += parseFloat(itens.nfi_valordesconto);
        });
        let percentualDescontoConcedido = parseFloat(((totalValorDescontoItens * 100) / parseFloat(form.getFieldsValue().subTotalNotaFiscal)).toFixed(2));
        totalDesconto = percentualDescontoConcedido + parseFloat(form.getFieldValue().percentualDesconto);

    };

    return (
        <div className="m-t-16 faturar">
            <Row align="middle" justify="center" gutter={[8, 24]}>
                <Col xs={24} sm={24} md={24} lg={16} xl={14} xxl={12}>
                    <Card extra={
                        <Row justify="center">
                            <Col>
                                <Typography.Title level={3}>
                                    Totais da Nota Fiscal
                                </Typography.Title>
                            </Col>
                        </Row>
                    }>
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco disabled label="Subtotal (R$)" name="subTotalNotaFiscal" className="text-right" />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco disabled label="Valor do Frete (R$)" name="valorFrete" className="text-right" />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco disabled label="Valor do Seguro (R$)" name="valorSeguro" className="text-right" />
                            </Col>
                            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                <InputPreco disabled label="Outras Despesas (R$)" name="valorOutrasDespesas" className="text-right" />
                            </Col>
                        </Row>
                        <Row align="bottom" justify="space-between" gutter={[8, 0]}>
                            <Col span={10}>
                                <Form.Item label="Percentual de Desconto" name="percentualDesconto">
                                    <InputNumber
                                        defaultValue={0}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onBlur={() => { vendedorDescMaxFinal(); }}
                                        onChange={() => { calcularDesconto('PERC'); }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="text-center">
                                <b> ou </b>
                            </Col>
                            <Col span={10}>
                                <InputPreco label="Valor do Desconto (R$)" name="valorDesconto" onBlur={() => { vendedorDescMaxFinal(); }} onChange={() => { calcularDesconto('VALOR'); }} />
                            </Col>
                        </Row>
                        <Row align="bottom" justify="space-between" gutter={[8, 0]}>
                            <Col span={10}>
                                <Form.Item label="Percentual de Acréscimo" name="percentualAcrescimo">
                                    <InputNumber
                                        defaultValue={0}
                                        min={0}
                                        max={100}
                                        formatter={value => `${value}%`}
                                        parser={value => value.replace('%', '')}
                                        onChange={() => { calcularAcrescimo('PERC') }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={4} className="text-center">
                                <b> ou </b>
                            </Col>
                            <Col span={10}>
                                <InputPreco label="Valor do Acréscimo (R$)" name="valorAcrescimo" onChange={() => { calcularAcrescimo('VALOR') }} />
                            </Col>
                        </Row>
                        <Row justify="end" className="m-t-8">
                            <Col>
                                IPI (R$): <b>0,00</b>
                            </Col>
                        </Row>
                        <Row justify="end" gutter={[0, 16]}>
                            <Col>
                                Substituição Tributária (R$): <b>0,00</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Divider />
                            </Col>
                        </Row>
                        <Row justify="end">
                            <Col>
                                <Typography.Title level={4} className="m-0">
                                    Total da Nota (R$)
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Row justify="end" className="inpDis">
                            <InputPreco className="f-18" name="valorTotal" disabled />
                        </Row>
                    </Card>
                </Col>
            </Row>
            <FormasPagamento form={form} dadosOperacao={dadosOperacao} listaFormaPagamento={listaFormaPagamento} setListaFormaPagamento={setListaFormaPagamento} />
        </div>
    );
}