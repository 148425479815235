import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button, Input, InputNumber, Descriptions, Card, notification, Tooltip, Select, Modal } from "antd";
import { SearchOutlined, BarcodeOutlined, PlusOutlined, CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { InputPreco, InputPercentual, SelectProduto, SelectPaginacao } from "../../components";
import { ModalListaProduto, ModalManutencaoNota, ModalSenhaLiberacao } from "../modals";
import { useStateValue } from "../../state";
import { isObjetoDiffVazio } from "../../services/funcoes";
import { CalcularImpostoItem } from "../../services/calculaImposto";

export default function FiltroProdutoPdi({ form, dadosTabela, dadosEndereco = {}, custo = {}, dadosOperacao = {}, btnEditando, itemEditando }) {

    const [dadosDetalhes, setDadosDetalhes] = useState({});
    const [consultaProduto, setConsultaProduto] = useState(null);
    const [{ manutencao }, dispatch] = useStateValue();
    const [dadosContribuinte, setDadosContribuinte] = useState({});
    const [aux, setAux] = useState(false);
    const [dadosProduto, setDadosProduto] = useState({});
    const [abrirModalListaProd, setAbrirModalListaProd] = useState(false);
    const [listaDadosProdModal, setListaDadosProdModal] = useState([]);
    const [abrirModalManutencaoNota, setAbrirModalManutencaoNota] = useState(false);
    const [liberadoParaIncluir, setLiberadoParaIncluir] = useState(false);
    const [produtoBuscaEan, setProdutoBuscaEan] = useState({});
    const [listaSelectProd, setListaSelectProd] = useState([]);
    const [desabilitarProd_id, setDesabilitarProd_id] = useState(false);
    const [exibirDetalhes, setExibirDetalhes] = useState(false);
    const [buscarProdutosCodBarra, setBuscarProdutosCodBarra] = useState(null);
    const togglePesquisa = () => {
        setConsultaProduto(!consultaProduto);
    };
    const [abreModalLiberacao, setAbreModalLiberacao] = useState(false);
    const [liberacaoSenha, setLiberacaoSenha] = useState(null);
    const [dadosProdutoImpostos, setDadosProdutoImpostos] = useState({});

    useEffect(() => {
        if (btnEditando.editando && isObjetoDiffVazio(itemEditando)) {
            editandoItem(itemEditando);
            setDadosDetalhes(itemEditando);
            setExibirDetalhes(true);
        }
    }, [btnEditando])

    useEffect(() => {
        if (!!dadosProduto && isObjetoDiffVazio(dadosProduto)) {
            abrirModalManutencaoProd();
        }
    }, [dadosProduto]);

    useEffect(() => {
        popularDadosContribuintes();
    }, []);

    useEffect(() => {
        if (!!dadosDetalhes && !!liberadoParaIncluir && !!form.getFieldValue().pro_codigo) {
            adicionarItemCodBarras();
            setLiberadoParaIncluir(false);
            form.setFieldsValue({ pro_codigo: null });
        }
    }, [dadosDetalhes, liberadoParaIncluir]);

    useEffect(() => {
        if (!!manutencao.dados) {
            popularDadosContribuintes();
            setAux(true);//criado para carregar os dadosContribuintes antes de entrar no popularProduto
        }
    }, [manutencao.dados]);

    useEffect(() => {
        if (!!listaSelectProd && manutencao.dados?.editando) {
            let dados = { ...manutencao.dados };
            popularProduto(dados.pro_id, true, dados.prg_id);
            dados.pro_id = form.getFieldValue().pro_id;
            form.setFieldsValue(dados);
        }
    }, [listaSelectProd]);

    useEffect(() => {
        if (!!dadosContribuinte && dadosContribuinte.length > 0 && aux && !!manutencao.dados && !!manutencao.dados.pro_id) {
            popularProduto(manutencao.dados.pro_id, true, manutencao.dados.prg_id);
        }
    }, [dadosContribuinte]);

    function abrirModalManutencaoProd() {
        if (isObjetoDiffVazio(custo)) {//validacao da nota de transferencia
            if (!!!dadosProduto.icm_id || !!!dadosProduto.ncm_id || !!!dadosProduto.ump_id || !!!dadosProduto.pro_descricao) {
                setAbrirModalManutencaoNota(true);
            } else if (!!!dadosProduto.iod_ufdestino) {
                setAbrirModalManutencaoNota(true);
            }
        } else {
            if (dadosContribuinte.emp_regimetributario === 3 && (!!!dadosProduto.icm_id || !!!dadosProduto.ncm_id || !!!dadosProduto.ump_id ||
                !!!dadosProduto.pro_descricao || !!!dadosProduto.pis_id || !!!dadosProduto.iod_ufdestino)) {
                setAbrirModalManutencaoNota(true);
            } else if (dadosContribuinte.emp_regimetributario !== 3 && (!!!dadosProduto.icm_id || !!!dadosProduto.ncm_id || !!!dadosProduto.ump_id)) {
                setAbrirModalManutencaoNota(true);
            } else if (!!dadosProduto.cst_id && !!!dadosProduto.mva_ufdestino && (dadosProduto.cst_id === '10' || dadosProduto.cst_id === '30' || dadosProduto.cst_id === '70')) {
                setAbrirModalManutencaoNota(true);
            }
        }
    };

    function popularDadosContribuintes(callback) {
        api.get(`dadosContribuinte/Buscar`).then(
            (res) => {
                if (res.status === 200) {
                    setDadosContribuinte(res.data);
                    if (callback) {
                        callback();
                    }
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    function alterarValorTotal() {
        let total = 0;
        total = form.getFieldValue().pdi_quantidade * form.getFieldValue().pdi_valorunitario;
        form.setFieldsValue({ pdi_valortotal: total.toFixed(2) });
        calculaDesconto('ATT');
    };

    function calculaTotalItemDescAcr() {
        let valorTot = 0;
        let valorItens = !!form.getFieldValue().pdi_valortotal ? form.getFieldValue().pdi_valortotal : 0;
        let valorAcrescimo = !!form.getFieldValue().acrescimo ? form.getFieldValue().acrescimo : 0;
        let valorDesc = !!form.getFieldValue().desconto ? form.getFieldValue().desconto : 0;
        valorTot = parseFloat(valorItens) + parseFloat(valorAcrescimo) - parseFloat(valorDesc);
        form.setFieldsValue({ valorItemDesconto: valorTot.toFixed(2) });
    };

    function calculaDesconto(campo) {
        let valor = (!!form.getFieldValue().desconto ? form.getFieldValue().desconto : 0);
        let perc = (!!form.getFieldValue().percdesc ? form.getFieldValue().percdesc : 0);
        let tot = parseFloat(form.getFieldValue().pdi_valortotal);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ desconto: result.toFixed(2) });
            } else if (campo === 'ATT') {
                result = (tot * perc) / 100;
                form.setFieldsValue({ desconto: result.toFixed(2) });
                valor = (!!form.getFieldValue().desconto ? parseFloat(form.getFieldValue().desconto) : 0)
                result = (!!tot > 0 ? (valor * 100) / tot : 0);
                form.setFieldsValue({ percdesc: result.toFixed(2) });
            } else {
                if (valor > tot) {
                    form.setFieldsValue({ desconto: tot });
                    form.setFieldsValue({ percdesc: 100 });
                } else {
                    result = (valor * 100) / tot;
                    form.setFieldsValue({ percdesc: result.toFixed(2) });
                }
            }
            calculaTotalItemDescAcr();
        }
    };

    async function buscarProduto(produto) {
        let listaItens = [...listaSelectProd];
        let prod = listaItens.filter((item) => item.key === produto)[0];
        let buscaOK = await popularProduto(prod.pro_id, false, prod.prg_id ? prod.prg_id : null);
        if (buscaOK) {
            await buscarDadosProdutoPedido(prod.pro_id);
        } else {
            cancelar();
        }
    };

    async function buscarDadosProdutoPedido(produto) {
        let prod = !!produto ? produto : form.getFieldValue().pro_id;
        let destinoOperacao = (!!dadosEndereco.est_sigla && !!dadosContribuinte.est_sigla && (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) ? '1' : '2');
        let res = await api.get(`Produto/BuscarDadosProduto?IdProduto=${prod}&ufDestino=${!!dadosEndereco ? dadosEndereco.est_sigla : dadosContribuinte.est_sigla}`
            + `&operacaoCodigo=${form.getFieldValue().ope_id}&ufOrigem=${dadosContribuinte.est_sigla}&tipoContribuinte=${dadosContribuinte.loc_tipocontribuinte}`
            + `&empresaEnquadramento=${dadosContribuinte.emp_regimetributario}&destinoOperacao=${destinoOperacao}&indicadorPresenca=0`
        );
        if (res.status == 200) {
            let dados = res.data[0];
            setDadosProdutoImpostos(dados);
            setDadosDetalhes(dados);
            dados.pro_id = prod;
            let filtros = !!produto ? `idProduto=${produto}` : '';
            filtros += !!dados.prg_id ? `&idProdutoGrade=${dados.prg_id}` : '';
            filtros += !!form.getFieldValue().cli_id ? `&idCliente=${form.getFieldValue().cli_id}` : '';
            let listaPrecoTemp = (await api.get(`ListaPreco/ListaPrecoPadraoProduto?${filtros}`)).data;
            dados.ltp_id = listaPrecoTemp[0].ltp_id;
            let listaPrecoCliente = (await api.get(`ListaPrecoCliente/ListarListaPrecoCliente?${filtros}`)).data;
            if (!!listaPrecoCliente && listaPrecoCliente.length > 0) {
                let grade = !!dados.prg_id ? `&CodigoGrade=${dados.prg_id}` : "";
                let dadosPreco = await api.get(`ListaPrecoItem/BuscaPrecoItem?CodigoListaPreco=${listaPrecoCliente[0].ltp_id}&CodigoProduto=${produto}${grade}`);
                if (dadosPreco.data) {
                    form.setFieldsValue({
                        ltp_id: !!dadosPreco.ltp_id ? dados.ltp_id : listaPrecoCliente[0].ltp_id,
                        lpi_valorvenda: dadosPreco.data.lpi_valorvenda,
                        pdi_valorunitario: dadosPreco.data.lpi_valorvenda,
                        pdi_valortotal: form.getFieldValue().pdi_quantidade * dadosPreco.data.lpi_valorvenda,
                    });
                }
            } else {
                form.setFieldsValue({ ltp_id: (!!listaPrecoTemp[0].ltp_id ? listaPrecoTemp[0].ltp_id : '') });
            }
            setDadosProduto(dados);
            buscaPrecoListaPrecoSelecionada(dados);
        }
    };

    async function popularProduto(prod_id, editar = false, prg_id = null) {
        setExibirDetalhes(true);
        let dadosTemp = {};
        let listaprecoItem = null;
        let total = 0;
        let dados = manutencao.dados;
        let custoProd = isObjetoDiffVazio(custo) && !!custo.key ? "&custoTransferencia=" + custo.key : "";
        let produtoGrade = !!prg_id ? `&IdGrade=${prg_id}` : ("");
        let produto = !!prod_id ? prod_id : ("");
        let grade = !!prg_id ? `&CodigoGrade=${prg_id}` : ("");
        let filtros = `idProduto=${produto}`;
        if (form.getFieldValue().cli_id !== null && form.getFieldValue().cli_id !== undefined && form.getFieldValue().cli_id !== '') {
            filtros += `&idCliente=${form.getFieldValue().cli_id}`;
        } if (dados !== null && (dados.cli_id !== null && dados.cli_id !== undefined && dados.cli_id !== '')) {
            filtros += dados.cli_id;
        }
        let lpc = (await api.get(`ListaPrecoCliente/ListarListaPrecoCliente?${filtros}`)).data;
        if (lpc != null && lpc != 0) {
            listaprecoItem = (await api.get(`ListaPrecoItem/BuscaPrecoItem?CodigoListaPreco=${lpc[0].ltp_id}&CodigoProduto=${produto}${grade}`)).data;
        }
        let res = await api.get(`Produto/BuscaProdutoPedido?IdProduto=${(!!prod_id ? prod_id : (!!dados.pro_id ? dados.pro_id : ""))}
        &ufDestino=${(!!dadosEndereco ? dadosEndereco.est_sigla : dadosContribuinte.est_sigla)}&ufOrigem=${dadosContribuinte.est_sigla}${custoProd}${produtoGrade}`);
        if (lpc != null && listaprecoItem != null) {
            form.setFieldsValue({ ltp_id: lpc[0].ltp_id, ltp_nome: lpc[0].ltp_nome });
            res.data[0].dpr_precovenda = listaprecoItem.lpi_valorvenda;
            res.data[0].lpi_valorvenda = listaprecoItem.lpi_valorvenda;
        }
        if (res.status === 200) {
            let valorUnitario = 0;
            if (isObjetoDiffVazio(custo)) {
                valorUnitario = res.data[0].dpr_precovenda;
            } else {
                valorUnitario = res.data[0].lpi_valorvenda > 0 ? res.data[0].lpi_valorvenda : res.data[0].dpr_precovenda;
            }
            if (!editar) {
                form.setFieldsValue({ pdi_quantidade: 1 });
                form.setFieldsValue({ pdi_valorunitario: valorUnitario });
                form.setFieldsValue({ pdi_valortotal: valorUnitario });
                form.setFieldsValue({ valorItemDesconto: valorUnitario });
                form.setFieldsValue({ pro_descricao: res.pro_descricao });
            } else {
                valorUnitario = dados.pdi_valorunitario;
                total = valorUnitario * dados.pdi_quantidade;
                form.setFieldsValue({ pdi_quantidade: dados.pdi_quantidade });
                form.setFieldsValue({ pdi_valorunitario: valorUnitario });
                form.setFieldsValue({ pdi_valortotal: total });
                form.setFieldsValue({ valorItemDesconto: total - dados.pdi_valordesconto });
                form.setFieldsValue({ pdi_valorfrete: dados.pdi_valorfrete });
                form.setFieldsValue({ pdi_valoracrescimo: dados.pdi_valoracrescimo });
                form.setFieldsValue({ desconto: dados.pdi_valordesconto });
                form.setFieldsValue({ percdesc: dados.percdesc });
                form.setFieldsValue({ pro_descricao: res.pro_descricao });
            }
            form.setFieldsValue({ prg_id: prg_id });
            dadosTemp.prg_id = prg_id;
            dadosTemp.pro_descricao = res.data[0].pro_descricao;
            dadosTemp.pro_codigo = res.data[0].pro_codigo;
            if (res.data[0].pro_ean) {
                dadosTemp.pro_ean = res.data[0].pro_ean;
            } else {
                dadosTemp.pro_ean = res.data[0].prg_ean;
            }
            dadosTemp.ncm_codigo = res.data[0].ncm_codigo;
            dadosTemp.ump_id = res.data[0].ump_id;
            dadosTemp.pro_id = res.data[0].pro_id;
            dadosTemp.pdi_valorunitario = valorUnitario;
            setDadosDetalhes(dadosTemp);
            return true;
        }
        else {
            console.log(res.data);
        }
    };

    async function carregarParametrosLocal(item, listaItens, lista) {
        const codigo = item.pro_id
        const codigoGrade = item.prg_id
        const quantidade = item.pdi_quantidade
        let resParams = await api.get(`ParametroLocal/BuscarParametros`);
        let paramsEstoque = resParams.data.par_avisoestoqueinsuficiente;
        let dados = lista.filter(item => item.key === form.getFieldValue().pro_id || item.pro_id === form.getFieldValue().pro_id)[0];
        var dadosIncluido = listaItens
            .filter((prod) => (prod.pro_id === dados.pro_id && (!!!dados.prg_id || dados.prg_id === prod.prg_id)))
            .reduce((total, prod) => total + prod.pdi_quantidade, 0);

        if (paramsEstoque === null) {
            return;
        }

        let resEstoque = await api.get(`Estoque/BuscarEstoqueProduto?codigoProduto=${codigo}` + (codigoGrade ? `&codigoGrade=${codigoGrade}` : ''))
        let estoque = resEstoque.data.valorTotal;

        let valorEstoque = calcularValorEstoque(estoque, dadosIncluido, quantidade);

        return new Promise((resolve, reject) => {
            if (valorEstoque <= 0) {
                estoqueInsuficiente(paramsEstoque, valorEstoque, resolve, reject);
            } else {
                resolve();
            }
        });
    }

    function calcularValorEstoque(estoque, dadosIncluido, quantidade) {
        if (dadosIncluido !== 0) {
            var novosDadosIncluido = dadosIncluido + quantidade
        }
        if (novosDadosIncluido > estoque) {
            return estoque - novosDadosIncluido;
        } else if (quantidade > estoque) {
            return estoque - quantidade;
        } else {
            return estoque
        }
    }

    function estoqueInsuficiente(paramsEstoque, valorEstoque, resolve, reject) {
        if (paramsEstoque === 0) {
            Modal.confirm({
                title: 'Aviso!',
                icon: <ExclamationCircleOutlined />,
                content: `Este produto está sem estoque disponível (Estoque: ${valorEstoque}). Deseja continuar?`,
                okText: 'Continuar',
                cancelText: 'Cancelar',
                centered: true,
                onOk() {
                    resolve();
                },
                onCancel() {
                    reject();
                }
            });
        } else if (paramsEstoque === 1) {
            Modal.confirm({
                title: 'Aviso!',
                icon: <ExclamationCircleOutlined />,
                content: `Este produto está sem estoque disponível (Estoque: ${valorEstoque}). Deseja continuar? Será solicitado uma senha Gerencial!`,
                okText: 'Continuar',
                cancelText: 'Cancelar',
                centered: true,
                onOk() {
                    setAbreModalLiberacao(true);
                    setLiberacaoSenha({ resolve, reject });
                },
                onCancel() {
                    reject();
                }
            });
        } else if (paramsEstoque === 2) {
            Modal.warning({
                title: 'Atenção Sistema de Controle de Estoque!',
                icon: <ExclamationCircleOutlined />,
                content: `Produto com estoque ${valorEstoque}, sistema está configurado para bloquear vendas de produtos sem estoque!`,
                centered: true,
                onOk() {
                    reject();
                }
            });
        }
    }

    const onCloseModalLiberacao = (aprovado) => {
        setAbreModalLiberacao(false);
        if (aprovado && liberacaoSenha) {
            liberacaoSenha.resolve();
        } else if (liberacaoSenha) {
            liberacaoSenha.reject();
        }
        setLiberacaoSenha(null);
    };

    async function adicionarItem(item = {}) {
        let dadosFormulario = form.getFieldValue();
        let possuiIe = dadosFormulario.pej_inscricaoestadual ? true : false;
        let cnpj = dadosFormulario?.cnpj || '';
        let cpf = dadosFormulario?.cpf || '';
        let fisicaJuridica = cnpj.length === 14 ? 2 : cpf.length === 11 ? 1 : 3;
        let p = dadosProdutoImpostos;

        if (p.ncm_ativo == false) {
            notification.warning({ message: 'Aviso!', description: 'NCM do produto está inativo na base de dados do SEFAZ, altere o NCM no cadastro do produto para seguir com a operação!' });
            return;
        }
        if (!!!p.iod_ufdestino) {
            notification.warning({ message: 'Aviso!', description: 'Não encontrado Dados de Origem e Destino no cadastro de tributação para o UF destino, verifique no cadastro de tributação do produto' });
            return;
        }
        let impostos = await CalcularImpostoItem(p.orm_id, p.cst_id, p.cso_id, p.iod_uforigem, p.iod_ufdestino, form.getFieldValue().pdi_quantidade, form.getFieldValue().pdi_valorunitario, 0, 0,
            0, p.iod_percicms, p.iod_percdiferimentoicms, p.iod_percicmsreducaobc, p.mva_percaliquotaicms, p.mva_percmva, p.mva_percaliquotaicmsreducao, p.mva_ajustado, p.ncm_percentual, fisicaJuridica,
            0, possuiIe)
        let dadosItens = [...dadosTabela.dadosItens];
        let lista = [...listaSelectProd];
        if (!buscarProdutosCodBarra) {
            if (btnEditando.editando) {
                item = dadosItens.filter((prod) => (prod.pdi_numeroitem === dadosFormulario.pdi_numeroitem))[0];
            } else {
                item = { ...item, ...dadosFormulario, ...dadosDetalhes };
            }
        }
        if (!btnEditando.editando) {
            try {
                await carregarParametrosLocal(item, dadosItens, lista);
            } catch (error) {
                return;
            }
        }
        if (!!validarItem(dadosFormulario)) {
            let dados = lista.filter(item => item.key === form.getFieldValue().pro_id || item.pro_id === form.getFieldValue().pro_id)[0];
            if (dadosFormulario.pdi_numeroitem !== undefined && btnEditando.editando) {
                var dadosEditando = dadosItens.filter((prod) => (prod.pdi_numeroitem === dadosFormulario.pdi_numeroitem))[0];
            } else {
                var dadosEditando = dadosItens.filter((prod) => (prod.pro_id === dados.pro_id && (!!!dados.prg_id || dados.prg_id === prod.prg_id)))[0];
            }
            if (!!dadosEditando) {
                dadosEditando.impostos = impostos;
                dados = adicionarItemPedido(false, item, dadosEditando);
                if (!!btnEditando && btnEditando.editando) {
                    dados = adicionarItemPedido(true, item, dadosEditando);
                    btnEditando.setEditando(false);
                    if (dados.pdi_numeroitem === undefined) {
                        dados.pdi_numeroitem = dadosItens.length;
                    }
                    alterarItem(dados, dadosItens, dadosEditando)
                } else {
                    if (buscarProdutosCodBarra) {
                        dados.pdi_numeroitem = dadosItens.length + 1;
                        incluirItem(dados, dadosItens);
                    } else {
                        Modal.confirm({
                            title: 'Atualizar?',
                            icon: <ExclamationCircleOutlined />,
                            content: 'Item já cadastrado na nota, deseja atualizar suas informações?',
                            okText: 'Atualizar',
                            cancelText: 'Incluir',
                            centered: true,
                            onOk() {
                                dados = adicionarItemPedido(true, item, dadosEditando);
                                alterarItem(dados, dadosItens, dadosEditando);
                            },
                            onCancel() {
                                dados.pdi_numeroitem = dadosItens.length + 1;
                                incluirItem(dados, dadosItens)
                            }
                        })
                    };
                }
            } else {
                item.impostos = impostos;
                dados = adicionarItemPedido(false, item, dados);
                dados.pdi_numeroitem = dadosItens.length + 1;
                incluirItem(dados, dadosItens)
            }
            setDesabilitarProd_id(false);
            setDadosDetalhes({});
            setExibirDetalhes(false);
            limparCampos();
            btnEditando.setEditando(false);
        }
    };


    function validarItem(dados) {
        if (parseFloat(form.getFieldValue().pdi_quantidade) === 0) {
            notification.warning({ description: 'Quantidade de itens deve ser maior que zero!', message: 'Aviso' });
            return false;
        }
        if (parseFloat(dados.valorItemDesconto) === 0 || parseFloat(dados.valorUnitario) === 0) {
            notification.warning({ description: 'Valor do item deve ser maior que zero!', message: 'Aviso' });
            return false
        }
        return true;
    };

    function incluirItem(dados, dadosItens) {
        dadosItens.push(dados);
        dadosItens.sort((a, b) => b.pdi_numeroitem - a.pdi_numeroitem)
        dadosTabela.setDadosItens(dadosItens);
        notification.success({ message: 'Aviso', description: 'Item incluído com sucesso!' });
    }

    function alterarItem(dados, dadosItens, dadosEditando) {
        let indice = dadosItens.findIndex((item) => (item === dadosEditando));
        dadosItens.splice(indice, 1, { ...dados });
        dadosTabela.setDadosItens(dadosItens);
        notification.success({ message: 'Aviso', description: 'Item editado com sucesso!' });
    };

    function adicionarItemCodBarras() {
        let listaItens = [...dadosTabela.dadosItens];
        let codigoProduto = form.getFieldValue().pro_codigo;
        let dados = listaItens.find((item) => item.pro_ean == codigoProduto);
        if (!!dados) {
            dados.pdi_quantidade += 1;
            let valorTotal = dados.pdi_quantidade * dados.pdi_valorunitario;
            let valorDesc = valorTotal * (dados.percdesc / 100);
            dados.valorItemDesconto = valorTotal - valorDesc;
        } else {
            dados = produtoBuscaEan;
            adicionarItemPedido(false, dados);
            dados.descricaograde = produtoBuscaEan.descricaograde
            listaItens.push(dados);
            dadosTabela.setDadosItens(listaItens)
        }
        cancelar();
    };

    function cancelar() {
        setExibirDetalhes(false);
        setDadosDetalhes({});
        setDesabilitarProd_id(false);
        limparCampos();
        if (btnEditando.editando) {
            btnEditando.setEditando(false);
        }
    };

    function limparCampos() {
        form.setFieldsValue({ pro_id: null, pro_codigo: null, percdesc: 0, desconto: 0, percacresc: 0, acrescimo: 0, icm_id: null });
    };

    function adicionarItemPedido(editando = false, item, dadosEditando) {
        let itemPedido = {};
        let formulario = form.getFieldValue();
        let valorSt = 0;
        if (editando) {
            if (isObjetoDiffVazio(dadosEditando.impostos)) {
                valorSt = dadosEditando.impostos.retornoICMSST.ret_valorIcmsSt;
            }
            itemPedido = {
                ...dadosEditando,
                impostos: dadosEditando.impostos,
                iod_uforigem: dadosEditando.iod_uforigem,
                iod_ufdestino: dadosEditando.iod_ufdestino,
                orm_id: dadosEditando.orm_id,
                ltp_id: dadosEditando.ltp_id,
                pdi_quantidade: parseFloat(!!formulario.pdi_quantidade ? formulario.pdi_quantidade : item.pdi_quantidade),
                pdi_valorunitario: parseFloat(formulario.pdi_valorunitario),
                percdesc: parseFloat(!!formulario.percdesc ? formulario.percdesc : 0),
                pdi_valordesconto: parseFloat(!!formulario.desconto ? formulario.desconto : 0),
                pdi_valoracrescimo: parseFloat(!!formulario.acrescimo ? formulario.acrescimo : 0),
                valorItemDesconto: parseFloat(parseFloat(formulario.valorItemDesconto) + parseFloat(valorSt)),
                pdi_valortotal: parseFloat(formulario.pdi_valortotal),
                pdi_valorfrete: parseFloat(formulario.pdi_valorfrete ?? 0),
                pro_codigo: item.pro_codigo,
                pro_id: item.pro_id,
                pro_descricao: item.pro_descricao,
                pro_ean: item.pro_ean,
                prg_id: item.prg_id,
                pdi_basecalculoicms: 0,
                pdi_basecalculoicmsst: 0,
                pdi_basecalculoipi: 0,
                pdi_percentualicms: 0,
                pdi_percentualicmsst: 0,
                pdi_percentualipi: 0,
                descricaograde: dadosEditando.descricaograde
            }
        } else {
            if (isObjetoDiffVazio(item.impostos)) {
                valorSt = item.impostos.retornoICMSST.ret_valorIcmsSt;
            }
            itemPedido = {
                impostos: item.impostos,
                iod_ufdestino: item.iod_ufdestino,
                iod_uforigem: item.iod_uforigem,
                orm_id: item.orm_id,
                ltp_id: item.ltp_id,
                pdi_quantidade: parseFloat(!!formulario.pdi_quantidade ? formulario.pdi_quantidade : item.pdi_quantidade),
                pdi_valorunitario: parseFloat(formulario.pdi_valorunitario),
                percdesc: parseFloat(!!formulario.percdesc ? formulario.percdesc : 0),
                pdi_valordesconto: parseFloat(formulario.desconto),
                valorItemDesconto: parseFloat(parseFloat(formulario.valorItemDesconto) + parseFloat(valorSt) ?? 0),
                pdi_valorfrete: parseFloat(formulario.pdi_valorfrete ?? 0),
                pdi_valoracrescimo: parseFloat(formulario.acrescimo ?? 0),
                pdi_valortotal: parseFloat(formulario.pdi_valortotal),
                pro_codigo: !!item.pro_codigo ? item.pro_codigo : item.pro_codigo,
                pro_id: !!item.pro_id ? item.pro_id : dadosProduto.pro_id,
                pro_descricao: item.pro_descricao,
                pro_ean: item.pro_ean,
                prg_id: item.prg_id,
                pdi_basecalculoicms: 0,
                pdi_basecalculoicmsst: 0,
                pdi_basecalculoipi: 0,
                pdi_percentualicms: 0,
                pdi_percentualicmsst: 0,
                pdi_percentualipi: 0,
                pdi_numeroitem: !!dadosTabela.dadosItens && dadosTabela.dadosItens.length > 0 ? dadosTabela.dadosItens.length + 1 : 1,
                descricaograde: item.descricaograde,
                ncm_percentual: item.ncm_percentual,
                cso_id: item.cso_id ?? dadosProdutoImpostos.cso_id,
                cst_id: item.cst_id ?? dadosProdutoImpostos.cst_id,
            }
        }
        return itemPedido;
    };

    async function buscarProdutoCodBarra(campo) {
        let dados = await api.get(`Produto/ListaProdutoCodigo?filtro=${campo.target.value.trim()}`);
        if (dados.data?.length > 1) {
            setListaDadosProdModal(dados.data);
            setAbrirModalListaProd(true);
        } else {
            buscarDadosProdutoPedido(dados.data[0].pro_id);
            form.setFieldsValue({ pro_id: dados.data[0].pro_id });
            let retOk = await popularProduto(dados.data[0].pro_id, false, dados.data[0].prg_id);
            if (retOk) {
                setProdutoBuscaEan(dados.data[0]);
                setLiberadoParaIncluir(true);
            } else {
                cancelar();
            }
        }
    };

    async function buscaPrecoListaPrecoSelecionada(dados) {
        let proId = null
        let prg = null;
        if (dados === undefined || dados.pro_id == null || isNaN(dados.pro_id)) {
            proId = dadosProduto.pro_id;
            prg = dadosProduto.prg_id;
        } else {
            proId = dados.pro_id;
            prg = dados.prg_id;
        }
        let filtros = `&CodigoProduto=${proId}`;
        filtros += !!prg ? `&CodigoGrade=${prg}` : "";
        let listaPreco = !!form.getFieldValue().ltp_id ? form.getFieldValue().ltp_id : (dados.ltp_id ? dados.ltp_id : "");
        let dadosPreco = await api.get(`ListaPrecoItem/BuscaPrecoItem?CodigoListaPreco=${listaPreco}${filtros}`);
        if (dadosPreco.data) {
            form.setFieldsValue({
                pdi_valorunitario: dadosPreco.data.lpi_valorvenda,
                pdi_valortotal: form.getFieldValue().pdi_quantidade * dadosPreco.data.lpi_valorvenda,
            });
            alterarValorTotal();
        } else {
            notification.warning({ description: 'Item não encontrado na lista de preço selecionada!', message: 'Aviso' });
        }
    }

    async function buscarDadosProdImpostos(produto) {
        let prod = !!produto.pro_id ? produto.pro_id : form.getFieldValue().pro_id;
        let ope = form.getFieldValue().ope_id;
        let destinoOperacao = (!!dadosEndereco.est_sigla && !!dadosContribuinte.est_sigla && (dadosEndereco.est_sigla === dadosContribuinte.est_sigla) ? '1' : '2');
        let res = await api.get(`Produto/BuscarDadosProduto?IdProduto=${prod}&ufDestino=${!!dadosEndereco ? dadosEndereco.est_sigla : dadosContribuinte.est_sigla}`
            + `&operacaoCodigo=${ope}&ufOrigem=${dadosContribuinte.est_sigla}&tipoContribuinte=${dadosContribuinte.loc_tipocontribuinte}`
            + `&empresaEnquadramento=${dadosContribuinte.emp_regimetributario}&destinoOperacao=${destinoOperacao}&indicadorPresenca=0`
        );
        if (res.status == 200) {
            let dados = res.data[0];
            setDadosProdutoImpostos(dados);
            setDadosDetalhes(dados);

            let filtros = !!prod ? `idProduto=${prod}` : '';
            filtros += !!produto.prg_id ? `&idProdutoGrade=${produto.prg_id}` : '';
            filtros += !!form.getFieldValue().cli_id ? `&idCliente=${form.getFieldValue().cli_id}` : '';
            let listaPrecoTemp = (await api.get(`ListaPreco/ListaPrecoPadraoProduto?${filtros}`)).data;
            form.setFieldsValue({ ltp_id: listaPrecoTemp[0]?.ltp_id });
        }
    };

    function editandoItem(dadosEditando) {
        let dadosTemp = {};
        let dados = dadosEditando;
        buscarDadosProdImpostos(dados);

        form.setFieldsValue({
            pro_id: dados.pro_id,
            prg_id: dados.prg_id,
            descricaograde: dados.descricaograde,
            key: dados.key,
            lpi_id: dados.lpi_id,
            lpi_valorvenda: dados.lpi_valorvenda,
            pdi_numeroitem: dados.pdi_numeroitem,
            pdi_quantidade: dados.pdi_quantidade,
            pdi_valordesconto: dados.pdi_valordesconto,
            pdi_valorfrete: dados.pdi_valorfrete,
            pdi_valoracrescimo: dados.pdi_valoracrescimo,
            pdi_valortotal: dados.pdi_valortotal,
            pdi_valorunitario: dados.pdi_valorunitario,
            percdesc: dados.percdesc,
            prg_codigoetiqueta: dados.prg_codigoetiqueta,
            prg_ean: dados.prg_ean,
            pro_codigo: dados.pro_codigo,
            pro_codigoetiqueta: dados.pro_codigoetiqueta,
            pro_descricao: dados.pro_descricao,
            pro_ean: dados.pro_ean,
            pro_kitcomposicao: dados.pro_kitcomposicao,
            pro_referencia: dados.pro_referencia,
            pro_usabalanca: dados.pro_usabalanca,
            pro_usagrade: dados.pro_usagrade,
            ump_casasdecimais: dados.ump_casasdecimais,
            ump_id: dados.ump_id,
            valorItemDesconto: dados.valorItemDesconto
        });
        dadosTemp = { dados };
        setDadosDetalhes(dadosTemp);
    }

    return (
        <div>
            <div>
                <Row gutter={[8, 16]}>
                    {!!consultaProduto === false &&
                        <Col xs={22} sm={22} md={22} lg={22} xl={23} xxl={23}>
                            <Form.Item label="Selecione o Produto" name="pro_id">
                                <SelectProduto
                                    disabled={desabilitarProd_id} valueKey={true} detalhes={true}
                                    placeholder="Selecione o Produto" name="pro_id" form={form}
                                    onChange={(dados) => { buscarProduto(dados) }}
                                    ordem={"pro_descricao"}
                                    setListaDados={setListaSelectProd} />
                            </Form.Item>
                        </Col>
                    }
                    {!!consultaProduto &&
                        <Col xs={22} sm={22} md={22} lg={22} xl={23} xxl={23}>
                            <Form.Item label="Localize o Produto" name="pro_codigo">
                                <Input onPressEnter={(value) => {
                                    buscarProdutoCodBarra(value)
                                }} placeholder="Informe o Produto/Mercadoria" />
                            </Form.Item>
                        </Col>
                    }
                    <Col xs={2} sm={2} md={2} lg={2} xl={1} xxl={1} className="i-button">
                        <Tooltip title={!!consultaProduto ? 'Localize produto por EAN' : 'Selecione o produto'} placement="left">
                            <Button onClick={togglePesquisa} block>
                                {consultaProduto ? <SearchOutlined /> : <BarcodeOutlined />}
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>
            </div>
            {exibirDetalhes &&
                <div className="m-t-16">
                    <Card title={
                        <Row gutter={[8, 0]}>
                            <Col>
                                Produto: <b>{dadosDetalhes.pro_descricao} {!!dadosDetalhes.descricaograde ? dadosDetalhes.descricaograde : ''}</b>
                            </Col>
                            <Col>
                                Código Interno: <b>{dadosDetalhes.pro_codigo}</b>
                            </Col>
                            <Col>
                                Código de Barras: <b>{!!dadosDetalhes.prg_ean ? dadosDetalhes.prg_ean : dadosDetalhes.pro_ean}</b>
                            </Col>
                            <Col>
                                NCM: <b>{dadosDetalhes.ncm_codigo}</b>
                            </Col>
                        </Row>
                    }>
                        <Row gutter={[8, 0]}>
                            <Form.Item hidden label="Id da Grade do Produto" name="prg_id">
                                <Input></Input>
                            </Form.Item>
                            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                                <Form.Item label="Lista Preço" name="ltp_id">
                                    <SelectPaginacao url="ListaPreco/ListarListaPreco" placeholder="Selecione a Lista" form={form} nameLabel="ltp_nome" nameValue="ltp_id"
                                        onChangeFunction={(valor) => buscaPrecoListaPrecoSelecionada(valor.ltp_id)} conteudo={
                                            lp => (<Select.Option value={lp.ltp_id} key={lp.key}>{lp.ltp_id} - {lp.ltp_nome}</Select.Option>)
                                        } allowClear />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={2} md={2} lg={2} xl={2}>
                                <Form.Item label="Qtde." name="pdi_quantidade">
                                    <InputNumber decimalSeparator="," placeholder="Informe a Quantidade do Produto" onChange={() => { alterarValorTotal() }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={3} md={3} lg={3} xl={3}>
                                <InputPreco label="Vlr. Unitário (R$)" name="pdi_valorunitario" form={form} onChange={() => { alterarValorTotal() }} />
                            </Col>
                            <Col xs={24} sm={3} md={4} lg={3} xl={3}>
                                <InputPreco label="Vlr. Item (R$)" name="pdi_valortotal" form={form} disabled={true} />
                            </Col>
                            {!isObjetoDiffVazio(custo) && <Col xs={12} sm={4} md={3} lg={3} xl={3}>
                                <InputPercentual name="percdesc"
                                    label="Desc. (%)"
                                    max={100}
                                    min={0}
                                    onBlur={() => (calculaDesconto('PERC'))}
                                />
                            </Col>}
                            {!isObjetoDiffVazio(custo) && <Col xs={12} sm={4} md={3} lg={3} xl={3}>
                                <InputPreco label="Desc. (R$)" name="desconto" precision={2} onChange={() => (calculaDesconto('VALOR'))} />
                            </Col>}
                            {!isObjetoDiffVazio(custo) && <Col xs={12} sm={6} md={4} lg={4} xl={4}>
                                <InputPreco label="VALOR TOTAL (R$)" name="valorItemDesconto" disabled={true} />
                            </Col>}
                            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="m-t-8" justify="end">
                                <Row gutter={[8, 8]} justify="end">
                                    <Col xs={24} sm={4} md={4} lg={4} xl={4} justify="end">
                                        <Button icon={<CloseOutlined />} onClick={() => (cancelar())} block>
                                            Cancelar
                                        </Button>
                                    </Col>
                                    <Col xs={24} sm={4} md={4} lg={4} xl={4} justify="end">
                                        <Button icon={<PlusOutlined />} type="primary" onClick={() => (adicionarItem())} block>
                                            Adicionar
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </div>}
            <ModalManutencaoNota formulario={form} exibirModal={abrirModalManutencaoNota} fecharModal={() => { cancelar(); setAbrirModalManutencaoNota(false); }} dadosContribuinte={dadosContribuinte} dadosOperacao={dadosOperacao} dadosEndereco={dadosEndereco} dadosProduto={dadosProduto} />
            <ModalListaProduto exibirModalListaProduto={abrirModalListaProd} fecharModalListaProduto={() => { setAbrirModalListaProd(false); }} dadosItens={listaDadosProdModal} />
            <ModalSenhaLiberacao exibirModal={abreModalLiberacao} fecharModal={onCloseModalLiberacao} />
        </div >
    );
}