import React, { useEffect, useState } from "react";
import { Row, Col, Form, Typography, Divider, Input, Button, Tag, Alert, Select, Steps, Tooltip, Table, Checkbox, notification, Modal } from "antd";
import { QuestionCircleOutlined, RightOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { getLocal } from "../../services/auth";
import { FormatNumber } from "../../ValueObjects";
import { TabTransporte } from '../tabNotaFiscal/index';
import { TabNotasFiscais, TabItens, TabResumo } from "./tabPage";
import { selectPaginadoActions } from "../../actions";
import { useStateValue } from "../../state";
import { consultaCNPJ, isObjetoDiffVazio, novaAbaNavegador, retornaSoNumeros, validarCnpj, validarCpf, validarDadosObj, validaForm, info ,openPdfNewTab} from "../../services/funcoes";
import { ModalAntecipacaoDevolucao, ModalDevolucaoSubstituicaoTributaria } from "../../components/modals";
import { Data, InputCep, InputPhone, SelectPaginacao } from "../../components";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";

export default function NotaFiscalDevolucao({ formulario, carregando, aoSalvar, dadosNfe, pages, adicionarNotas, proximaPag }) {

    const [{ }, dispatch] = useStateValue();
    const [listaStatusNota, setListaStatusNota] = useState([]);
    const [ambiente, setAmbiente] = useState([]);
    const [serieFiscal, setSerieFiscal] = useState([]);
    const [listaNotaDevolucao, setListaNotaDevolucao] = useState([]);
    const [gerarNfSt, setGerarNfSt] = useState(false);
    const [openModalAntecipacao, setOpenModalAntecipacao] = useState(false);
    const [openDevolucaoSubstituicaoTributaria, setOpenDevolucaoSubstituicaoTributaria] = useState(false);
    const [listaNotasReferenciadas, setListaNotasReferenciadas] = useState([]);
    const [listaFinalidadeNota, setListaFinalidadeNota] = useState([]);
    const [listaVeiculos, setListaVeiculos] = useState([]);
    const [tipoFormaPagamento, setTipoFormaPagamento] = useState([]);
    const [dadosTransp, setDadosTransp] = useState({});
    const [resumoNota, setResumoNota] = useState({});
    const [listaPais, setListaPais] = useState([]);
    const [isEstrangeiro, setIsEstrangeiro] = useState(false);
    const [listaTipoEndereco, setListaTipoEndereco] = useState([]);
    const [possuiCliente, setPossuiCliente] = useState(false);
    const [tipoOperacao, setTipoOperacao] = useState(null);
    const [filtroAdd, setFiltroAdd] = useState(null);
    const [operacaoFiscal, setOperacaoFiscal] = useState({});
    const [dadosNotaDevSt, setDadosNotaDevSt] = useState({
        valorIcmsDev: 0,
        valorBaseIcmsDev: 0,
        valorIcmsStDev: 0,
        valorBaseIcmsStDev: 0
    });

    function retornaDescricaoStatus(ntf_status) {
        if (!!listaStatusNota) {
            let status = listaStatusNota.filter((statusNota) => (statusNota.key === ntf_status))[0];
            return !!status ? status.value : "";
        }
    };

    const listaValidacoes = [
        { nome: 'tpt_id', obrigatorio: true, label: 'Tipo de Telefone' },
        { nome: 'psc_numero', obrigatorio: true, label: 'Telefone' },
        { nome: 'tpe_id', obrigatorio: true, label: 'Tipo de Endereço' },
        { nome: 'bai_nome', obrigatorio: !isEstrangeiro, label: 'Bairro' },
        { nome: 'log_logradouro', obrigatorio: true, label: 'Endereço' },
        { nome: 'cid_descricao', obrigatorio: true, label: 'Cidade' },
        { nome: 'pse_id', obrigatorio: true, label: 'País' },
        { nome: 'cep_cep', obrigatorio: !isEstrangeiro, label: 'CEP' },
        { nome: 'cpfCnpj', obrigatorio: true, label: 'CPF/CNPJ' },
        { nome: 'ntf_nomedest', obrigatorio: true, label: 'Destinatário' }
    ];

    useEffect(() => {
        if (!!proximaPag.proximaPag || proximaPag.proximaPag === 0) {
            validarTrocaPg(proximaPag.proximaPag);
        }
    }, [proximaPag.proximaPag]);

    useEffect(() => {
        let lista = [];
        if (tipoOperacao != null)
            lista.push({ name: "ope_id", campo: "TipoOperacao", value: tipoOperacao });
        if (lista.length > 0)
            dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
    }, [tipoOperacao]);

    useEffect(() => {
        if (tipoOperacao != null)
            setFiltroAdd(` &TipoOperacao=${tipoOperacao}`);
    }, [tipoOperacao]);

    useEffect(() => {
        verificarAmbiente();
        listarSerieFiscal();

        api.get('Enum/Listar?nome=StatusNFe').then(
            (res) => {
                if (res.status === 200) {
                    setListaStatusNota(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );

        api.get(`Pais/Listar`).then(
            res => {
                if (res.status === 200)
                    setListaPais(res.data);
            }
        ).catch(
            (erro) => console.error(erro)
        );

        api.get(`TipoEndereco/Listar`).then(
            (res) => {
                if (res.status === 200) {
                    setListaTipoEndereco(res.data);
                }
            }
        ).catch(
            (erro) => console.error(erro)
        );

        api.get('Enum/Listar?nome=FinalidadeNfe').then(
            (res) => {
                if (res.status === 200) {
                    setListaFinalidadeNota(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );

        api.get('Enum/Listar?nome=TipoFormaPagamento').then(
            (res) => {
                if (!!res.data) {
                    setTipoFormaPagamento(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
        formulario.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
        popularListaNotaDevolucao();
    }, []);

    useEffect(() => {
        if (!!dadosNfe.dadosNfeDevolucao && isObjetoDiffVazio(dadosNfe.dadosNfeDevolucao)) {
            setPossuiCliente(!!dadosNfe?.dadosNfeDevolucao?.pes_id);
        }

    }, [dadosNfe.dadosNfeDevolucao]);

    function validarTrocaPg(pagina) {
        let paginaAtual = pages.tagPages;
        let validacoes = [];

        if (paginaAtual === 0) {
            if (pagina === 1 && !possuiCliente) {
                validacoes = listaValidacoes;
            } else {
                validacoes = [
                    { nome: 'ntf_dataemissao', obrigatorio: true, label: 'Data de Emissão' }
                ];
            }

            if (validaForm(formulario, validacoes)) {
                pages.setTagPages(pagina);
            } else {
                proximaPag.setProximaPag(paginaAtual);
            }
            return;
        } else {
            pages.setTagPages(pagina);
        }
    };

    function verificarAmbiente() {
        api.get(`Nfe/VerificarAmbiente?codigoLocal=${getLocal()}`).then(retornoAmbiente => {
            if (!!retornoAmbiente.data) {
                setAmbiente(retornoAmbiente.data);
            }
        }).catch((erro) => console.error(erro));
    };

    function listarSerieFiscal() {
        api.get(`SerieFiscal/Listar?EmitenteDocumento=0&CodigoModeloDocFiscal=55&SomenteAtivos=true`).then(res => {
            if (!!res.data.items) {
                setSerieFiscal(res.data.items);
                if (res.data.items.length === 1) {
                    formulario.setFieldsValue({ ser_id: res.data.items[0].ser_id });
                }
            }
        }).catch((erro) => console.error(erro));
    };

    function popularListaNotaDevolucao() {
        let dadosNfeDevolucao;
        api.get(`dadosContribuinte/Buscar`).then(
            (res) => {
                if (!!res.data) {
                    dadosNfeDevolucao = { ...dadosNfe.dadosNfeDevolucao };
                    dadosNfeDevolucao.dadosContribuinte = res.data;
                    dadosNfeDevolucao.ntf_status = 1;
                    dadosNfeDevolucao.ntf_dataemissao = new Date();
                    dadosNfeDevolucao.ntf_datainclusao = new Date();
                    dadosNfeDevolucao.ntf_dhsaient = new Date();
                    dadosNfeDevolucao.dadosDestinatario = {};
                    dadosNfeDevolucao.dadosDestinatario.ntf_cepdest = dadosNfeDevolucao.ntf_cepdest;
                    dadosNfeDevolucao.dadosDestinatario.ntf_municipiodest = dadosNfeDevolucao.ntf_municipiodest;
                    dadosNfeDevolucao.dadosDestinatario.ntf_bairrodest = dadosNfeDevolucao.ntf_bairrodest;
                    dadosNfeDevolucao.dadosDestinatario.ntf_logradourodest = dadosNfeDevolucao.ntf_logradourodest;
                    dadosNfeDevolucao.dadosDestinatario.ntf_numeroenderecodest = dadosNfeDevolucao.ntf_numeroenderecodest;
                    dadosNfeDevolucao.dadosDestinatario.ntf_ufdest = dadosNfeDevolucao.ntf_ufdest;
                    dadosNfeDevolucao.dadosDestinatario.ntf_codigoibgemundest = dadosNfeDevolucao.ntf_codigoibgemundest;
                    dadosNfeDevolucao.dadosDestinatario.ntf_paisdest = dadosNfeDevolucao.ntf_paisdest;
                    dadosNfeDevolucao.dadosDestinatario.cid_id = dadosNfeDevolucao.cid_id;
                    dadosNfeDevolucao.dadosDestinatario.ntf_telefonedest = dadosNfeDevolucao.ntf_telefonedest;
                    dadosNfeDevolucao.dadosDestinatario.pes_id = dadosNfeDevolucao.pes_id;
                    dadosNfeDevolucao.dadosDestinatario.ntf_nomedest = dadosNfeDevolucao.ntf_nomedest;
                    dadosNfeDevolucao.dadosDestinatario.cli_regimetributariofiscal = dadosNfeDevolucao.cli_regimetributariofiscal;
                    dadosNfeDevolucao.dadosDestinatario.for_regimetributariofiscal = dadosNfeDevolucao.for_regimetributariofiscal;
                    dadosNfeDevolucao.dadosDestinatario.pes_fisicajuridica = dadosNfeDevolucao.pes_fisicajuridica;
                    if (dadosNfeDevolucao.dadosDestinatario.pes_fisicajuridica === 2) { //?
                        if (!!dadosNfeDevolucao.cli_regimetributariofiscal) {
                            dadosNfeDevolucao.dadosDestinatario.cli_regimetributariofiscal = !!dadosNfeDevolucao.cli_regimetributariofiscal.key ? dadosNfeDevolucao.cli_regimetributariofiscal.key : dadosNfeDevolucao.cli_regimetributariofiscal;
                        }
                        if (!!dadosNfeDevolucao.for_regimetributariofiscal) {
                            dadosNfeDevolucao.dadosDestinatario.for_regimetributariofiscal = !!dadosNfeDevolucao.for_regimetributariofiscal.key ? dadosNfeDevolucao.for_regimetributariofiscal.key : dadosNfeDevolucao.for_regimetributariofiscal;
                        }
                    }
                    dadosNfeDevolucao.dadosDestinatario.ntf_iedest = dadosNfeDevolucao.ntf_iedest;
                    dadosNfeDevolucao.dadosDestinatario.ntf_cnpjcpfdest = dadosNfeDevolucao.ntf_cnpjcpfdest;
                    dadosNfe.setDadosNfeDevolucao(dadosNfeDevolucao);
                    formulario.setFieldsValue({
                        ntf_modalidadefrete: 9,
                        ntf_nomedest: dadosNfeDevolucao.dadosDestinatario.ntf_nomedest,
                        cep_cep: dadosNfeDevolucao.dadosDestinatario.ntf_cepdest,
                        cid_descricao: dadosNfeDevolucao.dadosDestinatario.ntf_municipiodest,
                        log_logradouro: dadosNfeDevolucao.dadosDestinatario.ntf_logradourodest,
                        pee_numero: dadosNfeDevolucao.dadosDestinatario.ntf_numeroenderecodest,
                        bai_nome: dadosNfeDevolucao.dadosDestinatario.bai_nome,
                        psc_numero: dadosNfeDevolucao.dadosDestinatario.ntf_telefonedest
                    });
                    carregaDadosNota(dadosNfeDevolucao);
                }
            }
        ).catch(
            (erro) => {
                console.log(erro);
            }
        );
    };

    async function carregaDadosNota(dadosNota, notasReferenciadas = [], salvar = true) {
        let dadosNotaDev = { ...dadosNotaDevSt };
        let dadosNotaReferenciada = {};
        dadosNotaReferenciada.ntf_id = dadosNota.ntf_id;
        dadosNotaReferenciada.ntf_numero = dadosNota.ntf_numero;
        dadosNotaReferenciada.ntf_nomedest = dadosNota.ntf_nomedest;
        dadosNotaReferenciada.ntf_chavenfe = dadosNota.ntf_chavenfe;
        dadosNotaReferenciada.ntf_dataemissao = dadosNota.ntf_dataemissao;
        dadosNotaReferenciada.ntf_id = dadosNota.ntf_id;
        dadosNotaReferenciada.ntf_status = dadosNota.ntf_status;
        dadosNotaReferenciada.ntf_tipooperacao = dadosNota.ntf_tipooperacao;
        dadosNotaReferenciada.ntf_finalidade = dadosNota.ntf_finalidade;
        dadosNotaReferenciada.valorFrete = dadosNota.valortotalfrete ?? 0;
        dadosNotaReferenciada.valorDesconto = dadosNota.valortotaldesconto ?? 0;
        dadosNotaReferenciada.totalNfe = dadosNota.valortotalnota;
        dadosNotaReferenciada.ser_id = dadosNota.ser_id;
        dadosNotaReferenciada.ser_serie = dadosNota.ser_serie;

        if (dadosNota.ntf_finalidade === 1) {
            dadosNotaReferenciada.ntf_finalidadeNfe = 'NF-e Normal';
        } else if (dadosNota.ntf_finalidade === 2) {
            dadosNotaReferenciada.ntf_finalidadeNfe = 'NF-e Complementar';
        } else if (dadosNota.ntf_finalidade === 3) {
            dadosNotaReferenciada.ntf_finalidadeNfe = 'NF-e de Ajuste'
        } else if (dadosNota.ntf_finalidade === 4) {
            dadosNotaReferenciada.ntf_finalidadeNfe = 'Devolução'
        } else {
            dadosNotaReferenciada.ntf_finalidadeNfe = '';
        }

        if (dadosNota.ntf_tipooperacao === 0) {
            dadosNotaReferenciada.tip_descricao = 'Entrada'
            setTipoOperacao(4);
        } else {
            dadosNotaReferenciada.tip_descricao = 'Saída'
            setTipoOperacao(3);
        }

        dadosNotaReferenciada.itens = [];

        for (const item of dadosNota.itensNf) {
            item.remover = false;
            let retorno = await api.get(`CorrelacaoCFOP/CorrelacaoCFOP?tipoNota=${dadosNota.ntf_tipooperacao}&tipoCorrelacao=2&cfop=${item.nfi_cfop}`);
            if (!!retorno.data) {
                item.nfi_cfop = retorno.data;
            }

            if (item.prg_id) {
                item.grade = item.descricaoGrade;
            }
            item.nfi_valorseguro = (item.nfi_valorseguro ?? 0);
            item.nfi_valorfrete = (item.nfi_valorfrete ?? 0);
            item.nfi_valoroutros = (item.nfi_valoroutros ?? 0);
            item.nfi_valordesconto = (item.nfi_valordesconto ?? 0);
            item.nig_valorbc = (item.nig_valorbc ?? 0);
            item.nig_valoricms = (item.nig_valoricms ?? 0);
            item.nig_valorbcst = (item.nig_valorbcst ?? 0);
            item.nig_valoricmsst = (item.nig_valoricmsst ?? 0);
            item.npi_valorbcipi = (item.npi_valorbcipi ?? 0);
            item.npi_valoripi = (item.npi_valoripi ?? 0);
            item.nig_percreducaobc = (item.nig_percreducaobc ?? 0);
            item.nig_percicms = (item.nig_percicms ?? 0);
            item.nig_percreducaobcst = (item.nig_percreducaobcst ?? 0);
            item.nig_percmvast = (item.nig_percmvast ?? 0);
            item.npi_percaliquotaipi = (item.npi_percaliquotaipi ?? 0);
            item.qtdeOriginal = item.qtdeRestante;

            item.valorSeguroRateio = (parseFloat(item.nfi_valorseguro) / item.nfi_qtde) * item.qtdeRestante;
            item.valorFreteRateio = (parseFloat(item.nfi_valorfrete) / item.nfi_qtde) * item.qtdeRestante;
            item.valorOutrosRateio = (parseFloat(item.nfi_valoroutros) / item.nfi_qtde) * item.qtdeRestante;
            item.valorDescontoRateio = (parseFloat(item.nfi_valordesconto) / item.nfi_qtde) * item.qtdeRestante;

            item.bcIcms = item.nig_valorbc;
            item.nig_valorbc = (parseFloat(item.nig_valorbc) / item.nfi_qtde) * item.qtdeRestante;
            item.valorIcms = item.nig_valoricms;
            item.nig_valoricms = (parseFloat(item.nig_valoricms) / item.nfi_qtde) * item.qtdeRestante;
            item.bcIcmsSt = item.nig_valorbcst;
            item.nig_valorbcst = (parseFloat(item.nig_valorbcst) / item.nfi_qtde) * item.qtdeRestante;
            item.valorIcmsSt = item.nig_valoricmsst;
            item.nig_valoricmsst = (parseFloat(item.nig_valoricmsst) / item.nfi_qtde) * item.qtdeRestante;
            item.bcIpi = item.npi_valorbcipi;
            item.npi_valorbcipi = (parseFloat(item.npi_valorbcipi) / item.nfi_qtde) * item.qtdeRestante;
            item.valorIpi = item.npi_valoripi;
            item.npi_valoripi = (parseFloat(item.npi_valoripi) / item.nfi_qtde) * item.qtdeRestante;

            //dadosNotaReferenciada.totalNfe += (item.valorFreteRateio + item.valorSeguroRateio + (item.npi_valoripi + item.nig_valoricmsst) + ((item.nfi_qtde * item.nfi_valorunitario) - item.valorDescontoRateio));

            if (item.nig_cst === "10" || item.nig_cst === "30" || item.nig_cst === "70") {
                setGerarNfSt(true);
                dadosNotaDev.valorIcmsDev += item.nig_valoricms;
                dadosNotaDev.valorBaseIcmsDev += item.nig_valorbc;
                dadosNotaDev.valorIcmsStDev += item.nig_valoricmsst;
                dadosNotaDev.valorBaseIcmsStDev += item.nig_valorbcst;
            }
            dadosNotaDev.ntf_dataemissao = new Date();

            dadosNotaReferenciada.itens.push(item);
        }
        setDadosNotaDevSt(dadosNotaDev);
        dadosNotaReferenciada.dadosDestinatario = dadosNota.dadosDestinatario;
        dadosNotaReferenciada.dadosContribuinte = dadosNota.dadosContribuinte;
        notasReferenciadas.push(dadosNotaReferenciada);
        if (salvar) {
            setListaNotaDevolucao(notasReferenciadas);
        }
        if (dadosNotaReferenciada.ntf_docfiscal == false) {
            formulario.setFieldsValue({ ser_id: null });
        }
    };

    useEffect(() => {
        if (adicionarNotas.adicionarNotas) {
            listarNotasReferenciadas();
        }
    }, [adicionarNotas.adicionarNotas]);

    function listarNotasReferenciadas(filtro = '') {
        let listaNfeSelecionada = [];
        let count = 1;
        let n = "";
        listaNotaDevolucao.forEach((nota) => {
            if (count == listaNotaDevolucao.length)
                n += nota.ntf_id;
            else
                n += nota.ntf_id + ",";
            count++;
        });
        api.get(`NotaFiscalDevolucao/ListarDevolucao?IdPessoa=${dadosNfe.dadosNfeDevolucao.pes_id}&docFiscal=${dadosNfe.dadosNfeDevolucao.ntf_docfiscal}&Filtro=${filtro}&listaNfeSelecionada=${n}`).then(retorno => {
            if (!!retorno.data) {
                setListaNotasReferenciadas(retorno.data.map((x, idx) => ({ key: idx, ...x })));
            }
        }).catch((erro) => console.error(erro));
    };

    function selecionarNota(key) {
        let lista = [...listaNotasReferenciadas];
        let nota = lista.find((item) => { return item.key === key });
        nota.checked = !nota.checked;
        setListaNotasReferenciadas(lista);
    };

    async function adicionarNotasSelecionadas() {
        let notasSelecionadas = listaNotasReferenciadas.filter((nota) => { return nota.checked });
        let notasReferenciadas = [...listaNotaDevolucao];
        for (const [i, nota] of notasSelecionadas.entries()) {
            let retorno = await api.get(`NotaFiscalDevolucao/ListarItemDevolucao?IdNotaFiscal=${nota.ntf_id}`);
            if (!!retorno.data) {
                nota.itensNf = retorno.data;
                carregaDadosNota(nota, notasReferenciadas, i === notasSelecionadas.length - 1);
            }
        }
    };

    function validarFormaPagamento() {
        setOpenModalAntecipacao(true);
    };

    async function salvarNotaDevolucao() {
        let values = formulario.getFieldValue();
        let notaDevolucaoFinal = {};
        notaDevolucaoFinal.gerarAntecipacao = values.antecipacaoEstorno === 1 ? true : false;
        if (!notaDevolucaoFinal.gerarAntecipacao)
            notaDevolucaoFinal.ctc_id = values.ctc_id;
        notaDevolucaoFinal.pdv_id = null;
        notaDevolucaoFinal.ntf_docfiscal = false;
        notaDevolucaoFinal.ntf_modalidadefrete = values.ntf_modalidadefrete;
        notaDevolucaoFinal.valorFrete = 0;
        notaDevolucaoFinal.valorDesconto = 0;
        notaDevolucaoFinal.valorSeguro = 0;
        notaDevolucaoFinal.valorAcrescimo = 0;
        notaDevolucaoFinal.notaFiscalTransportadora = preencherDadosTransportadores(values);
        notaDevolucaoFinal.ope_id = formulario.getFieldValue().ope_id;
        notaDevolucaoFinal.ntf_consumidorfinal = dadosNfe.dadosNfeDevolucao?.ntf_consumidorfinal;
        notaDevolucaoFinal.ntf_infcomplementar = values.ntf_infcomplementar;
        

        notaDevolucaoFinal.notaFiscalDestinatario = tratarDadosDestinatario(dadosNfe.dadosNfeDevolucao.dadosDestinatario, values);

        if (!!formulario.getFieldValue()?.ser_id) {
            notaDevolucaoFinal.ser_id = formulario.getFieldValue().ser_id;
            notaDevolucaoFinal.ntf_docfiscal = true;
        }
        notaDevolucaoFinal.documentosReferenciados = [];
        notaDevolucaoFinal.notaFiscalFaturamento = !!dadosNfe.dadosNfeDevolucao.formaPgto ? dadosNfe.dadosNfeDevolucao.formaPgto : [];
        notaDevolucaoFinal.notafiscalitens = [];
        let numeroItem = 0;

        for (const notaDevolucao of listaNotaDevolucao) {
            notaDevolucaoFinal.documentosReferenciados.push({
                ntf_chavenfe: notaDevolucao.ntf_chavenfe,
                ntf_id: notaDevolucao.ntf_id
            });
            if (!(!!notaDevolucaoFinal.ntf_nomedest)) {
                notaDevolucaoFinal.ntf_id = null;
                notaDevolucaoFinal.ntf_numero = notaDevolucao.ntf_numero;
                notaDevolucaoFinal.ntf_chavenfe = '';
                notaDevolucaoFinal.ntf_nomedest = notaDevolucao.ntf_nomedest;
                notaDevolucaoFinal.ntf_dataemissao = !!values.ntf_dataemissao ? values.ntf_dataemissao : notaDevolucao.ntf_dataemissao;
                notaDevolucaoFinal.ntf_status = notaDevolucao.ntf_status;
                notaDevolucaoFinal.ntf_tipooperacao = notaDevolucao.ntf_tipooperacao;
                notaDevolucaoFinal.tip_descricao = notaDevolucao.tip_descricao;
                notaDevolucaoFinal.numeroNotaDevolver = notaDevolucao.ntf_numero;
                notaDevolucaoFinal.dadosContribuinte = notaDevolucao.dadosContribuinte;
                notaDevolucaoFinal.dadosDestinatario = notaDevolucao.dadosDestinatario;
            }
            let dadosGerais = await popularItemsValoresTotais(notaDevolucao.itens, numeroItem);
            // Verifique se dadosGerais existe e se itensPreenchidos está definido dentro dele
            if (dadosGerais && dadosGerais.itensPreenchidos) {
                // Iterar sobre os itensPreenchidos e adicioná-los à lista notaDevolucaoFinal.notafiscalitens
                dadosGerais.itensPreenchidos.forEach(item => {
                    notaDevolucaoFinal.notafiscalitens.push(item);
                });
            }

            //notaDevolucaoFinal.notafiscalitens.push(dadosGerais?.itensPreenchidos);
            notaDevolucaoFinal.valorDesconto += dadosGerais?.totalDesconto;
            notaDevolucaoFinal.valorFrete += dadosGerais?.totalFrete;
            notaDevolucaoFinal.valorAcrescimo += dadosGerais?.valorAcrescimo;
            notaDevolucaoFinal.valorSeguro += dadosGerais?.totalSeguro;
        }
        if (notaDevolucaoFinal.notafiscalitens.length == 0)
            info('Nota de devolução sem itens, verifique!');
        else {
            carregando(true);
            api.post(`NotaFiscalDevolucao/IncluirDevolucao`, notaDevolucaoFinal).then(
                retornoNF => {
                    if (operacaoFiscal?.ope_gerarespelho == true) {
                        openPdfNewTab(`NotaFiscal/GerarEspelhoDanfeNFe?IdNotaFiscal=${retornoNF.data.ntf_id}`)
                    } else {
                        if (retornoNF.data.ntf_status === 1 && !!retornoNF.data.ntf_chavenfe) {
                            if (notaDevolucaoFinal.ntf_docfiscal) {
                                novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${retornoNF.data.ntf_chavenfe}`);
                            }
                        }
                    }
                    // if (gerarNfSt) {
                    //     setOpenDevolucaoSubstituicaoTributaria(true);
                    // } else {
                    //     aoSalvar();
                    //     notification.success({ message: 'Sucesso', description: `NF adicionada com sucesso!` });
                    // }
                    aoSalvar();
                    notification.success({ message: 'Sucesso', description: `NF adicionada com sucesso!` });
                }
            ).catch(
                erro => {
                    if (!!erro.response && !!erro.response.data) {
                        Modal.warning({
                            content: erro.response.data,
                            title: 'Aviso',
                            onOk: () => {
                                aoSalvar();
                            }
                        })
                    }
                    console.log(erro);
                }
            ).finally(() => {
                carregando(false);
            });
        }
    };

    function tratarDadosDestinatario(dadosDestinatario, dadosFormulario) {
        if (!(!!dadosDestinatario.pes_id)) {
            dadosDestinatario.pes_fisicajuridica = dadosFormulario.pes_fisicajuridica;
            dadosDestinatario.ntf_nomedest = dadosFormulario.ntf_nomedest;
            dadosDestinatario.ntf_cnpjcpfdest = dadosFormulario.cpfCnpj.replace(/\D/g, "");
            dadosDestinatario.ntf_iedest = dadosFormulario.ntf_iedest ?? '';
            dadosDestinatario.ntf_idestrangeirodest = dadosFormulario.pes_fisicajuridica === 3 ? dadosFormulario.ntf_cnpjcpfdest : '';
            dadosDestinatario.ntf_logradourodest = dadosFormulario.log_logradouro;
            dadosDestinatario.ntf_numeroenderecodest = dadosFormulario.pee_numero;
            dadosDestinatario.ntf_bairrodest = dadosFormulario.bai_nome;
            dadosDestinatario.ntf_cepdest = dadosFormulario.ntf_cepdest;
            dadosDestinatario.ntf_ufdest = dadosFormulario.est_sigla;
            dadosDestinatario.ntf_telefonedest = dadosFormulario.psc_numero;
            dadosDestinatario.ntf_emaildest = dadosFormulario.pem_email;
        }
        return dadosDestinatario;
    }

    async function salvarNotaAjuste(dadosNotaDevSt) {
        dadosNotaDevSt.dadosDestinatario = dadosNfe.dadosNfeDevolucao.dadosDestinatario;
        dadosNotaDevSt.dadosContribuinte = dadosNfe.dadosNfeDevolucao.dadosContribuinte;

        let retornoNFAjusteAdjudicacao;
        let retornoNFAjusteRessarcimento;
        if (dadosNotaDevSt.valorIcmsDev > 0) {
            dadosNotaDevSt.operacao = dadosNotaDevSt.operacaocredito;
            retornoNFAjusteAdjudicacao = await api.post(`NotaFiscalDevolucao/SalvarNotaAjusteAdjudicacao`, dadosNotaDevSt);
            if (retornoNFAjusteAdjudicacao.data.ntf_status === 1 && !!retornoNFAjusteAdjudicacao.data.ntf_chavenfe) {
                novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${retornoNFAjusteAdjudicacao.data.ntf_chavenfe}`);
            }
        }
        if (dadosNotaDevSt.valorIcmsStDev > 0) {
            dadosNotaDevSt.operacao = dadosNotaDevSt.operacaodebito;
            retornoNFAjusteRessarcimento = await api.post(`NotaFiscalDevolucao/SalvarNotaAjusteRessarcimento`, dadosNotaDevSt);
            if (retornoNFAjusteRessarcimento.data.ntf_status === 1 && !!retornoNFAjusteRessarcimento.data.ntf_chavenfe) {
                novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${retornoNFAjusteRessarcimento.data.ntf_chavenfe}`);
            }
        }

        aoSalvar();
        carregando(false);
        notification.success({ message: 'Sucesso', description: `NF adicionada com sucesso!` });
    };

    async function popularItemsValoresTotais(dados, numeroItem) {
        let dadosGerais = {
            totalSeguro: 0,
            totalDesconto: 0,
            valorAcrescimo: 0,
            totalFrete: 0,
            itensPreenchidos: []
        };
        for (const item of dados) {
            numeroItem = numeroItem + 1
            let itemReferenciado = {
                nfi_id_origem: item.nfi_id,
                ntf_id: item.ntf_id,
            };
            let novoItem = {
                pro_id: item.pro_id,
                prg_id: item.prg_id,
                nfi_numeroitem: numeroItem,
                nfi_qtde: item.qtdeRestante,
                nfi_valorunitario: item.nfi_valorunitario,
                nfi_valordesconto: item.nfi_valordesconto,
                nfi_valoroutros: item.nfi_valoroutros,
                nfi_valorfrete: item.nfi_valorfrete,
                nfi_valorseguro: item.nfi_valorseguro,
                nfi_numeropedidocompra: item.nfi_numeropedidocompra,
                nfi_itempedidocompra: item.nfi_itempedidocompra,
                nfi_informacaoadicional: item.nfi_informacaoadicional,
                nfi_cfop: item.nfi_cfop?.toString(),
                icm_id: item.icm_id,
                pis_id: item.pis_id,
                cso_id: item.cso_id,
                ocp_id: item.ocp_id,
                cst_id: item.cst_id,
                nfi_ncm: item.nfi_ncm,
                nfectereferenciadaitens: []
            }
            novoItem.nfectereferenciadaitens.push(itemReferenciado);
            dadosGerais.totalDesconto += novoItem.nfi_valordesconto;
            dadosGerais.totalSeguro += novoItem.nfi_valorseguro;
            //no banco o campo de acrescimo esta com o nome de nfi_valoroutros
            dadosGerais.valorAcrescimo += novoItem.nfi_valoroutros;
            dadosGerais.totalFrete += novoItem.nfi_valorfrete;
            dadosGerais.itensPreenchidos.push(novoItem);
        }
        return dadosGerais;
    }

    function preencherDadosTransportadores(dados) {
        let dadosTransporte = {
            trn_id: dados.trn_id,
            trv_id: dados.trv_id,
            trv_marca: dados.trv_marca,
            trv_modelo: dados.trv_modelo,
            trv_placa: dados.trv_placa,
            trv_rntc: dados.trv_rntc,
            trv_uf: dados.trv_uf,
            trv_observacao: dados.trv_observacao,
            nvl_especievolume: dados.nvl_especievolume,
            nvl_marcavolume: dados.nvl_marcavolume,
            nvl_numeracaovolume: dados.nvl_numeracaovolume ? dados.nvl_numeracaovolume.toString() : dados.nvl_numeracaovolume,
            nvl_pesobruto: dados.nvl_pesobruto,
            nvl_pesoliquido: dados.nvl_pesoliquido,
            nvl_qtdevolumes: dados.nvl_qtdevolumes,
            ntf_modalidadefrete: dados.ntf_modalidadefrete
        };
        return dadosTransporte;
    };

    function validarFisicoJuridico(event) {
        let valorCampo = event.currentTarget.value.replace(/\D/g, '');
        if (valorCampo.length === 11) {
            if (!validarCpf(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
                formulario.setFieldsValue({ cpfCnpj: null });
                return false;
            }
            formulario.setFieldsValue({ pes_fisicajuridica: 1 });
        } else if (valorCampo.length === 14) {
            if (!validarCnpj(valorCampo)) {
                notification.warning({ message: 'Aviso', description: 'CNPJ informado é inválido' });
                formulario.setFieldsValue({ cpfCnpj: null });
                return false;
            }
            formulario.setFieldsValue({ pes_fisicajuridica: 2 });
        }
    };

    function listarClienteCpfCnpj(cpfCnpj) {
        let dados = {};
        if (!!!cpfCnpj) {
            return false;
        }

        formulario.setFieldsValue({
            pes_id: null, est_sigla: null, cid_id: null, ntf_nomedest: null, cep_cep: null,
            cid_descricao: null, log_logradouro: null, pee_numero: null, bai_nome: null,
            psc_numero: "", tpt_id: null
        });
        api.get(`Cliente/BuscarClienteCpfCnpj/${cpfCnpj}`).then(
            res => {
                dados = res.data;
                if (!!dados && isObjetoDiffVazio(dados)) {
                    let endereco = dados.enderecos.filter((end) => { return end.pee_enderecoprincipal });
                    let email = dados.emails.filter((em) => { return em.pem_emailprincipal });
                    let telefone = dados.telefones.filter((tel) => { return tel.psc_principal });
                    endereco = endereco.length > 0 ? endereco[0] : {};
                    telefone = telefone.length > 0 ? telefone[0] : {};
                    email = email.length > 0 ? email[0] : {};
                    if (isObjetoDiffVazio(endereco)) {
                        formulario.setFieldsValue({
                            est_sigla: endereco.est_sigla, cid_id: endereco.cid_id, pes_id: dados.pes_id, ntf_nomedest: dados.pes_nome, pse_id: endereco.pse_id, ntf_cepdest: endereco.cep_cep,
                            cid_descricao: endereco.cid_descricao, log_logradouro: endereco.log_logradouro, pee_numero: endereco.pee_numero, bai_nome: endereco.bai_nome,
                            psc_numero: telefone.psc_numero, tpt_id: telefone.tpt_id, cpfCnpj: cpfCnpj, pes_fisicajuridica: dados.pes_fisicajuridica, ntf_iedest: dados.pej_inscricaoestadual ?? (dados.pej_inscricaomunicipal ?? '')
                        });
                    }
                    if (isObjetoDiffVazio(email))
                        formulario.setFieldsValue({ pem_email: email.pem_email });

                    setPossuiCliente(true);
                } else {

                    setPossuiCliente(false);
                }
            }
        ).catch(
            (erro) => {
                if (!!erro.response.data && !!erro.response.data.Message && erro.response.data.Message.indexOf("CNPJ não localizado!") >= 0) {
                    consultaCNPJ(cpfCnpj).then((retorno) => {
                        let dados = retorno;
                        if (dados.status === "OK" && dados.situacao === "ATIVA") {
                            let endereco = {};
                            endereco.pse_id = null;
                            endereco.pee_numero = dados.numero;
                            endereco.cep_cep = retornaSoNumeros(dados.ntf_cepdest);
                            endereco.bai_nome = dados.bairro;
                            endereco.log_logradouro = dados.logradouro;
                            endereco.cid_descricao = dados.municipio;
                            endereco.est_sigla = dados.uf;

                            formulario.setFieldsValue({
                                est_sigla: endereco.est_sigla, cid_id: endereco.cid_id, pes_id: dados.pes_id, ntf_nomedest: dados.nome, pse_id: endereco.pse_id, ntf_cepdest: endereco.cep_cep,
                                cid_descricao: endereco.cid_descricao, log_logradouro: endereco.log_logradouro, pee_numero: endereco.pee_numero, bai_nome: endereco.bai_nome,
                                psc_numero: retornaSoNumeros(dados.telefone).substring(0, 10), cpfCnpj: cpfCnpj, pes_fisicajuridica: 2
                            });

                            setPossuiCliente(true);
                        }
                    }, (erro) => {
                        console.log('ERRO:', erro);
                        setPossuiCliente(false);
                    });
                }
            }
        );
    };

    function alterarStatusEstrangeiro(pse_id) {
        let pais = listaPais.find((pais) => { return pais.pse_id === pse_id });
        if (!!pais && pais.pse_codigobacen != 1058) {
            setIsEstrangeiro(true);
        } else {
            setIsEstrangeiro(false);
        }
    };

    const verificaCep = (cep) => {
        if (!!cep) {
            api.get(`Cidade/ListarCidadeCep?cep=${cep.match(/\d+/g).join('')}`).then(
                (res) => {
                    if (res.status === 200) {
                        formulario.setFieldsValue({ cid_descricao: res.data.length > 0 ? res.data[0].cid_descricao : '', cid_id: res.data.length > 0 ? res.data[0].cid_id : null, est_sigla: res.data.length > 0 ? res.data[0].est_sigla : '' });
                    } else {
                        api.get(`Cidade/ListarCidadeCep?cep=${cep.match(/\d+/g).join('')}`).then(
                            (res) => {
                                if (res.status === 200) {
                                    formulario.setFieldsValue({ cid_descricao: res.data.cidade, cid_id: null, est_sigla: res.data.estado });
                                }
                            })
                    }
                }
            );
        }
    };
    async function popularOperacaoFiscal(id) {
        if (!!id) {
            api.get(`OperacaoFiscal/Buscar/${id}`).then(
                res => {
                    if (res.status === 200) {
                        setOperacaoFiscal(res.data);
                    }
                }
            ).catch(
                error => { console.log(error); }
            )
        }
    };

    return (
        <div className="pages-col">
            <Form layout="vertical" form={formulario} onFinish={validarFormaPagamento}>
                {!adicionarNotas.adicionarNotas ? <div>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={24}>
                            {ambiente === 2 &&
                                <Row align="middle" justify="center" gutter={[8, 8]} className="m-b-16">
                                    <Col>
                                        <Alert message="ATENÇÃO: Sistema de Emissão de Nota Fiscal em Ambiente de Homologação" type="error" showIcon />
                                    </Col>
                                    <Col>
                                        <Tooltip title="Notas Fiscais emitidas em ambiente de homologação não tem validade fiscal ou jurídica">
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={8} md={6} lg={3} xl={3} xxl={4}>
                            <Form.Item label="Série" name="ser_id">
                                <Select placeholder="Selecione uma Série" allowClear showSearch optionFilterProp="children">
                                    {serieFiscal.map(serie => (
                                        <Select.Option value={serie.ser_id} key={serie.ser_id}>{serie.ser_serie}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={4} lg={3} xl={4} xxl={3}>
                            <Data label="Data de Emissão" name="ntf_dataemissao" />
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={8}>
                            <Form.Item name="ope_id" label="Operação Fiscal" rules={[{ required: true, message: 'Informe a operação fiscal.' }]}>
                                <SelectPaginacao url={`OperacaoFiscal/ListarOperacaoFiscal`} placeholder="Selecione a Operação Fiscal" form={formulario} onChangeFunction={(idOperacao) => popularOperacaoFiscal(idOperacao)} nameValue="ope_id" nameLabel="ope_descricao" filtroExtra={filtroAdd}
                                    conteudo={
                                        op => (<Select.Option value={op.ope_id} key={op.key}>{op.ope_descricao}</Select.Option>)
                                    } />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                        {!!dadosNfe.dadosNfeDevolucao.pes_id &&
                            <Col xs={24} sm={8} md={10} lg={10} xl={10} xxl={10}>
                                <Form.Item label="Destinatário">
                                    <div className="in-form">{dadosNfe.dadosNfeDevolucao.ntf_nomedest}</div>
                                </Form.Item>
                            </Col>
                        }
                        {!!!dadosNfe.dadosNfeDevolucao.pes_id &&
                            <Col xs={24} sm={8} md={4} lg={4} xl={4} xxl={4}>
                                <Form.Item label="CPF/CNPJ" name="cpfCnpj" rules={[{ required: true, message: 'Informe o CPF ou CNPJ.' }]}>
                                    <CpfCnpj className='ant-input ant-input-sm'
                                        placeholder="Informe o CPF ou CNPJ"
                                        onBlur={valor => {
                                            validarFisicoJuridico(valor); listarClienteCpfCnpj(valor.currentTarget.value.replace(/\D/g, ''));
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        }
                        {!!!dadosNfe.dadosNfeDevolucao.pes_id &&
                            <Col xs={24} sm={pages.tagPages === 0 ? 12 : 24} md={pages.tagPages === 0 ? 6 : 14} lg={pages.tagPages === 0 ? 7 : 13} xl={pages.tagPages === 0 ? 7 : 13} xxl={pages.tagPages === 0 ? 7 : 13}>
                                <Form.Item label="Destinatário" name="ntf_nomedest" rules={[{ required: true }]}>
                                    <Input disabled={possuiCliente} />
                                </Form.Item>
                            </Col>
                        }
                        {(!!!dadosNfe.dadosNfeDevolucao.pes_id && pages.tagPages === 0 && !possuiCliente) &&
                            <>
                                <Form.Item name="pes_id" hidden>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="cid_id" hidden>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="est_sigla" hidden>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="pes_fisicajuridica" hidden>
                                    <Input />
                                </Form.Item>
                                {!isEstrangeiro && <Col xs={24} sm={6} md={3} lg={3} xl={3} xxl={3}>
                                    <InputCep label="CEP" name="ntf_cepdest" rules={[{ required: true, message: 'Informe o CEP' }]} onBlur={(cep) => verificaCep(cep.target.value)} onPressEnter={(cep) => verificaCep(cep.target.value)} />
                                </Col>}
                                <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Item label="Cidade" name="cid_descricao" rules={[{ required: true }]}>
                                        <Input disabled={!isEstrangeiro} placeholder="Informe a Cidade" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={6} md={4} lg={4} xl={3} xxl={3}>
                                    <Form.Item label="País" name="pse_id" rules={[{ required: true }]}>
                                        <SelectPaginacao url={"Pais/Listar"} allowClear form={formulario} showSearch placeholder="Informe o País" name="pse_id" nameValue="pse_id" nameLabel="pse_nome"
                                            conteudo={
                                                tipoEnd => (<Select.Option value={tipoEnd.pse_id}>{tipoEnd.pse_nome}</Select.Option>)
                                            }
                                        />
                                        {/* <Select
                                            showSearch
                                            optionFilterProp="children"
                                            placeholder="Informe o País"
                                            allowClear
                                            onChange={alterarStatusEstrangeiro}
                                        >
                                            {listaPais.map(pais => (
                                                <Select.Option value={pais.pse_id} key={pais.pse_id}>{pais.pse_nome}</Select.Option>
                                            ))}
                                        </Select> */}
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={16} md={7} lg={6} xl={7} xxl={7}>
                                    <Form.Item label="Endereço" name="log_logradouro" rules={[{ required: true, message: 'Informe o Endereço' }]}>
                                        <Input placeholder="Informe o Endereço" />
                                    </Form.Item>
                                </Col>
                            </>
                        }
                        {(!!!dadosNfe.dadosNfeDevolucao.pes_id && pages.tagPages === 0 && !possuiCliente) &&
                            <>
                                <Col xs={24} sm={6} md={4} lg={6} xl={3} xxl={3}>
                                    <Form.Item label="Número" name="pee_numero">
                                        <Input placeholder="Informe o Número" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={6} md={5} lg={6} xl={3} xxl={3}>
                                    <Form.Item label="Bairro" name="bai_nome" rules={[{ required: !isEstrangeiro, message: 'Informe o Bairro.' }]}>
                                        <Input placeholder="Informe o Bairro" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={4} xxl={4}>
                                    <Form.Item label="Tipo de Endereço" name="tpe_id" rules={[{ required: true, message: 'Tipo de Endereço' }]}>
                                        <SelectPaginacao url={"TipoEndereco/Listar"} allowClear form={formulario} showSearch placeholder="Informe o Tipo de Endereço" name="tpe_id" nameValue="tpe_id" nameLabel="tpe_descricao"
                                            conteudo={
                                                tipoEnd => (<Select.Option value={tipoEnd.tpe_id}>{tipoEnd.tpe_descricao}</Select.Option>)
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={6} md={4} lg={4} xl={4} xxl={4}>
                                    <InputPhone label="Telefone" name="psc_numero" rules={[{ required: true, message: 'Informe o Número do Telefone' }]} />
                                </Col>
                                {/* <Col xs={24} sm={6} md={6} lg={6} xl={4} xxl={4}>
                                    <Form.Item label="Tipo de Telefone" name="tpt_id" rules={[{ required: true }]}>
                                        <SelectPaginacao url={"TipoTelefone/Listar"} form={formulario} placeholder="Selecione um tipo de telefone" name="tpt_id" nameValue="tpt_id" nameLabel="tpt_descricao"
                                            conteudo={
                                                tp => (<Select.Option value={tp.tpt_id} key={tp.key}>{tp.tpt_descricao}</Select.Option>)
                                            } />
                                    </Form.Item>
                                </Col> */}
                            </>
                        }
                        {(!!!dadosNfe.dadosNfeDevolucao.pes_id && pages.tagPages === 0 && !possuiCliente) &&
                            <Col xs={24} sm={12} md={8} lg={8} xl={6} xxl={6}>
                                <Form.Item label="Informe o E-mail" name="pem_email">
                                    <Input placeholder="Informe o e-mail" />
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={24}>
                            <Steps size="small" type="navigation" current={pages.tagPages}>
                                <Steps.Step title="Notais Fiscais" />
                                <Steps.Step title="Itens" />
                                <Steps.Step title="Transporte" />
                                <Steps.Step title="Resumo" />
                            </Steps>
                        </Col>
                        <Col span={24}>
                            {pages.tagPages === 0 &&
                                <TabNotasFiscais listaNotaDevolucao={{ listaNotaDevolucao, setListaNotaDevolucao }} retornaDescricaoStatus={retornaDescricaoStatus} adicionarNotas={adicionarNotas} />
                            }
                            {pages.tagPages === 1 &&
                                <TabItens listaNotaDevolucao={{ listaNotaDevolucao, setListaNotaDevolucao }} adicionarNotas={adicionarNotas} dadosNfe={dadosNfe} formulario={formulario} />
                            }
                            {pages.tagPages === 2 &&
                                <TabTransporte form={formulario} veiculos={{ listaVeiculos, setListaVeiculos }} dadosTransp={{ dadosTransp, setDadosTransp }} />
                            }
                            {pages.tagPages === 3 &&
                                <TabResumo form={formulario} listaNotaDevolucao={{ listaNotaDevolucao, setListaNotaDevolucao }} resumoNota={{ resumoNota, setResumoNota }} />
                            }
                        </Col>
                    </Row>
                </div> :
                    <Row align="middle" gutter={[8, 16]}>
                        <Col span={24} >
                            <Row gutter={[8, 16]}>
                                <Col span={24}>
                                    <Typography.Title level={3}>
                                        <img src={require("../../assets/i-info.png").default} alt="Notas Fiscais" /> Notas Fiscais
                                    </Typography.Title>
                                    <Divider orientation="left" plain />
                                </Col>
                                <Col span={21}>
                                    <Form.Item>
                                        <Input.Search placeholder="Destinatário / Nº da Nota Fiscal / Chave de Acesso da NF-e ..." enterButton onSearch={(value) => listarNotasReferenciadas(value)} allowClear />
                                    </Form.Item>
                                </Col>
                                <Col span={3}>
                                    <Button type="primary" block onClick={() => { adicionarNotas.setAdicionarNotas(false); adicionarNotasSelecionadas(); }}>
                                        Avançar <RightOutlined />
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="tabela">
                                        <Table dataSource={listaNotasReferenciadas} columns={[
                                            {
                                                title: 'Nº Nota',
                                                align: 'center',
                                                width: 90,
                                                render: ({ ntf_numero }) => (
                                                    <div>
                                                        <b>{ntf_numero}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Status',
                                                align: 'center',
                                                width: 100,
                                                render: ({ ntf_status }) => (
                                                    <div>
                                                        <Tag color={((ntf_status === 0) ? 'default' : (ntf_status === 1 ? 'green' : (ntf_status === 2 ? 'gold' : (ntf_status === 3 ? 'orange' : (ntf_status === 4 ? 'red' : (ntf_status === 5 ? 'lime' : ''))))))}>
                                                            <b>
                                                                {retornaDescricaoStatus(ntf_status)}
                                                            </b>
                                                        </Tag>
                                                    </div>
                                                ),
                                            }, {
                                                title: 'Outras Informações',
                                                render: ({ ntf_tipooperacao, ser_serie, ntf_dataemissao, ntf_finalidade, ntf_chavenfe, ntf_nomedest }) => (
                                                    <div>
                                                        <Row align="middle" gutter={[8, 0]}>
                                                            <Col>
                                                                <b>{ntf_nomedest}</b>
                                                            </Col>
                                                        </Row>
                                                        <Row align="middle" gutter={[8, 0]}>
                                                            <Col>
                                                                Série: <b>{(!!ser_serie ? ser_serie : '')}</b>
                                                            </Col>
                                                            <Col>
                                                                Emissão: <b>{moment(ntf_dataemissao).format('DD/MM/YYYY')}</b>
                                                            </Col>
                                                            <Col>
                                                                Nota de: <b>{ntf_tipooperacao === 0 ? 'Entrada' : 'Saída'}</b>
                                                            </Col>
                                                            <Col>
                                                                Finalidade: <b>{listaFinalidadeNota[ntf_finalidade - 1].value}</b>
                                                            </Col>
                                                            {!(!!ntf_chavenfe) && <Col>
                                                                <b>NF-e não gerada</b>
                                                            </Col>}
                                                            {!!ntf_chavenfe && <Col>
                                                                NF-e: <b>{ntf_chavenfe}</b>
                                                            </Col>}
                                                        </Row>
                                                    </div>
                                                ),
                                            },
                                            {
                                                title: 'Total (R$)',
                                                width: 220,
                                                align: 'right',
                                                render: ({ valortotalnota }) => (
                                                    <div>
                                                        <b className="f-18">{FormatNumber(valortotalnota, true)}</b>
                                                    </div>
                                                ),
                                            },
                                            {
                                                dataIndex: '',
                                                key: 'x',
                                                align: 'center',
                                                fixed: 'right',
                                                width: 35,
                                                render: ({ checked, key }) => (
                                                    <div>
                                                        <Row align="middle" justify="center">
                                                            <Col>
                                                                <Checkbox checked={checked} onClick={() => { selecionarNota(key) }} />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                ),
                                            }
                                        ]} scroll={{ x: 900 }} />
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>}
                <ModalDevolucaoSubstituicaoTributaria exibirModal={openDevolucaoSubstituicaoTributaria} fecharModal={() => { setOpenDevolucaoSubstituicaoTributaria(false) }} aoSalvar={(dadosNotaDevSt) => { setOpenDevolucaoSubstituicaoTributaria(false); salvarNotaAjuste(dadosNotaDevSt) }} dadosNfe={dadosNfe} serieFiscal={serieFiscal} dadosNotaDevSt={dadosNotaDevSt} />
                <ModalAntecipacaoDevolucao form={formulario} exibirModal={openModalAntecipacao} cancelar={() => { formulario.setFieldsValue({ antecipacaoEstorno: 1 }); setOpenModalAntecipacao(false) }} aoSalvar={() => { setOpenModalAntecipacao(false); salvarNotaDevolucao() }} />
            </Form>
        </div>
    );
}