import React, { useState, useEffect } from 'react';
import { Data, FormGW, SelectPaginacao, SelectPaginado } from '../../components';
import { Col, Form, Input, Row, Select, Spin, Steps, notification } from 'antd';
import { useStateValue } from '../../state';
import moment from 'moment';
import api from '../../services/api';
import FornecedorNFE from './fornecedorNFE';
import FaturamentoNFE from './faturamentoNFE';
import ResumoNFE from './resumoNFE';
import { novaAbaNavegador, validaForm, validarNumeros } from '../../services/funcoes';
import ItensNFE from './itensNFE';
import TransportadorNFE from './transportadorNFE';
import { manutencaoActions } from '../../actions';
import NotasBlocoNFE from './notasBlocoNFE';


export default function NFEntrada({ form, showDrawer, carregando, aoSalvar, pages, proximaPag }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [editando, setEditando] = useState(false);
    const [carregarPagina, setCarregarPagina] = useState(false);
    const [listaSerie, setListaSerie] = useState([]);
    const [dadosItens, setDadosItens] = useState([]);
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [dadosEndereco, setDadosEndereco] = useState({});
    const [operacaoFiscal, setOperacaoFiscal] = useState({});
    const [dadosFornecedor, setDadosFornecedor] = useState({});
    const [listaVeiculos, setListaVeiculos] = useState([]);
    const [dadosTransp, setDadosTransp] = useState({});
    const listaValidacoesForn = [
        { nome: 'ope_id', obrigatorio: true, label: 'Operação Fiscal' },
        { nome: 'for_id', obrigatorio: true, label: 'Fornecedor' }
    ];
    const pagamentosCodigos = {
        dinheiro: 1,
        cheque: 2,
        cartaoCredito: 3,
        cartaoDebito: 4,
        crediarioLoja: 5,
        valeAlimentacao: 6,
        valeRefeicao: 7,
        valeCombustivel: 9,
        antecipacao: 12,
        valePresente: 12,
        boleto: 15,
        depositoBancario: 16,
        pix: 17,
        transfBancaria: 18,
        semPagamento: 90,
        outro: 99
    };

    const popularOperacaoFiscal = (id) => {
        if (!!id) {
            api.get(`OperacaoFiscal/Buscar/${id}`).then(
                res => {
                    if (res.status === 200) {
                        setOperacaoFiscal(res.data);
                        form.setFieldsValue({ ntf_indicadorpresenca: res.data.ope_indicadorpresenca });
                    }
                }
            ).catch(
                error => { console.log(error); }
            )
        }
    }

    useEffect(() => {
        if (showDrawer) {
            carregarDados();
            if (!!manutencao.dados && !!manutencao.dados.ntf_id) {
                form.setFieldsValue(manutencao.dados);
                setEditando(true);
            } else {
                setEditando(false);
            }
            if (!!!form.getFieldValue().ntf_dataemissao) {
                form.setFieldsValue({ ntf_dataemissao: moment(new Date()) });
            }
        }
    }, [showDrawer]);

    useEffect(() => {
        if (!!proximaPag.proximaPag || proximaPag.proximaPag === 0) {
            validarTrocaPg(proximaPag.proximaPag);
        }
    }, [proximaPag.proximaPag]);

    useEffect(() => {
        if (!!manutencao.dados) {
            if (!!manutencao.dados.faturamento) {
                setListaFormaPagamento(manutencao.dados.faturamento);
            }

            if (!!manutencao.dados.formaPgto && !!!manutencao.dados.faturamento) {
                manutencao.dados.formaPgto.forEach((formas) => {
                    formas.pfp_valor = parseFloat(formas.nfp_valor);
                });
                let dadosFormaPgto = [...manutencao.dados.formaPgto];
                dadosFormaPgto = manutencao.dados.formaPgto.filter((dados) => dados.fpg_tipopagamento !== pagamentosCodigos.crediarioLoja && !dados.fpg_quitacaoantecipacao);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ faturamento: dadosFormaPgto } } } });
            }
            /*if (!!manutencao.dados.pes_id && (!!!manutencao.dados.destinatario || (manutencao.dados.destinatario.pes_id !== manutencao.dados.pes_id))) {
                let lista = [];
                lista.push({ name: "pes_id", campo: "Filtro", value: manutencao.dados.pes_id });
                lista.push({ name: "ope_id", campo: "CodigoOperacao", value: manutencao.dados.ope_id });
                if (lista.length > 0) dispatch({ type: selectPaginadoActions.CHANGE, data: { itens: lista } });
                buscarDadosCliente(manutencao.dados.pes_id);
                popularOperacaoFiscal(manutencao.dados.ope_id);
            }*/
            if (!!manutencao.dados && !!manutencao.dados.itensNf) {
                if (!!manutencao.dados.itensNf[0].ntf_id) {
                    calcularFrete(manutencao.dados.itensNf);
                }
                if (dadosItens.length === 0) {
                    setDadosItens(manutencao.dados.itensNf);
                }
            }
        }
    }, [manutencao.dados]);

    async function carregarDados() {
        setCarregarPagina(true);
        let listaSerie = await api.get('SerieFiscal/Listar?EmitenteDocumento=0&CodigoModeloDocFiscal=55&SomenteAtivos=true');
        let serie = [];
        if (listaSerie.status === 200) {
            serie = listaSerie.data.items;
            setListaSerie(serie);
            if (serie.length > 0) {
                form.setFieldsValue({ ser_id: serie[0].ser_id });
            }
        }
        setCarregarPagina(false);
    }

    async function validarTrocaPg(pagina) {
        let paginaAtual = pages.tagPages;
        let listaValidacoes = [];
        switch (paginaAtual) {
            case 0:
                listaValidacoes = listaValidacoesForn;
                if (!await validarFornecedor()) {
                    proximaPag.setProximaPag(paginaAtual);
                    return false;
                }
                break;
            case 1:
                if (paginaAtual < pagina) {
                    if (!!dadosItens && dadosItens.length === 0) {
                        notification.warning({ message: 'Aviso', description: 'Nota Fiscal deve ter ao menos 1 item!' });
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;

            case 3:
                if (paginaAtual < pagina) {
                    listaValidacoes = [];
                    let validaFaturamento = await validarFaturamento();
                    if (validaFaturamento === false && listaFormaPagamento.length === 0) {
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
        }
        if (validaForm(form, listaValidacoes)) {
            pages.setTagPages(pagina);
        } else {
            proximaPag.setProximaPag(paginaAtual);
        }
    };

    async function validarFaturamento() {
        let formulario = form.getFieldsValue();
        let retorno = true;
        let valorTotal = 0;
        let operacao = operacaoFiscal;
        if (listaFormaPagamento.length > 0) {
            listaFormaPagamento.forEach(forma => {
                valorTotal += parseFloat(forma.pfp_valor);
                if (forma.editando == true) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Favor salvar as alterações em andamento nas parcelas(forma pagamento) para continuar!' });
                }
            });
            if (parseFloat(formulario.valorTotal) === 0) {
                retorno = false;
                notification.warning({ message: 'Aviso!', description: 'Valor da Nota Inválido!' });
            } else {
                if ((validarNumeros(formulario.valorTotal) == !parseFloat(valorTotal.toFixed(2))) || operacao.tip_id == !5) {
                    notification.warning({ message: 'Aviso', description: 'Valor informado nas formas de pagamento é menor que o valor total da nota!' });
                }
            }
        } else {
            if (!!formulario.fpg_id) {
                let formaPagamento = (await api.get(`FormaPagamento/Get?id=${formulario.fpg_id}`)).data;
                if (!listaFormaPagamento.cpg_id) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Favor preencher as formas de pagamento!' });
                }
                else if (formaPagamento.fpg_tipoavistaaprazo === 1) {
                    retorno = false;
                    notification.warning({ message: 'Aviso', description: 'Favor preencher as formas de pagamento!' });
                }
            } else {
                retorno = false;
                notification.warning({ message: 'Aviso', description: 'Favor preencher as formas de pagamento!' });
            }
        }
        return retorno;
    }

    async function validarFornecedor() {
        return true;
    }

    async function ValidarNota(dados) {
        dados = await popularDados();
        dados.gerarBoleto = false;
        dados.ntf_ufdest = dados.notaFiscalDestinatario?.ntf_ufdest;
        dados.notaFiscalFaturamento.forEach((formasPgto) => {
            if (formasPgto.fpg_tipopagamento === pagamentosCodigos.boleto) {
                dados.gerarBoleto = true;
            }
        });
        dados.ntf_modalidadefrete = !!dados.notaFiscalTransportadora ? dados.notaFiscalTransportadora.ntf_modalidadefrete : 9;
        if (!!form.getFieldValue().ntf_id) {
            dados.ntf_id = form.getFieldValue().ntf_id;
        } else {
            dados.ntf_dhsaient = moment(new Date());
        }
        SalvarNota(dados);
    }

    async function popularDados() {
        let valores = form.getFieldsValue();
        let dados = form.getFieldValue();
        valores.ntf_docfiscal = !!dados.ser_id;
        valores.notafiscalitens = popularDadosItens(dados);
        valores.notaFiscalFaturamento = preencherDadosFaturamento(dados);
        valores.notaFiscalDestinatario = await preencherDadosDestinatario(dados);
        valores.notaFiscalTransportadora = preencherDadosTransportadores(dados);
        valores.tipooperacao = operacaoFiscal;
        valores.serieFiscal = preencherDadosSerieFiscal(dados);
        return valores;
    };

    function popularDadosItens(dados) {
        let valorFrete = dados.valorFrete;
        let valorSeguro = dados.valorSeguro;
        let qtdTotalItens = 0;
        let freteUn = 0;
        let seguroUn = 0;
        dadosItens.forEach((itens) => {
            qtdTotalItens += itens.nfi_qtde;
            itens.nfi_numeropedidocompra = !!itens.nfi_numeropedidocompra ? itens.nfi_numeropedidocompra.toString() : '';
        });
        freteUn = valorFrete / qtdTotalItens;
        seguroUn = valorSeguro / qtdTotalItens;
        dadosItens.forEach((itens) => {
            itens.nfi_valorfrete = freteUn * dados.nfi_qtde;
            itens.nfi_valorseguro = seguroUn * dados.nfi_qtde;
        });
        return dadosItens;
    };

    function preencherDadosFaturamento() {
        let dadosForma = [];
        let recebimentoCartao = !!manutencao.dados.recebimentoCartao ? manutencao.dados.recebimentoCartao[0] : null;
        listaFormaPagamento.forEach(forma => {
            let aux = { ...forma };
            aux.fpg_id = forma.fpg_id;
            aux.cpg_id = forma.cpg_id;
            if (forma.fpg_tipopagamento === pagamentosCodigos.cheque && !!manutencao.dados.dadosModalCheque) {
                aux.ctc_id = manutencao.dados.dadosModalCheque.contaCorrente;
                aux.cheques = manutencao.dados.dadosModalCheque.cheques;
            }
            aux.nfp_valor = parseFloat(forma.pfp_valor);
            aux.bce_id = !!recebimentoCartao ? recebimentoCartao.bce_id : null;
            aux.rcc_nsu = !!recebimentoCartao ? recebimentoCartao.rcc_nsu : null;
            if (forma.fpg_tipopagamento === pagamentosCodigos.antecipacao) {
                aux.antecipacoes = forma.formaPagamento.antecipacoes;
            } else {
                aux.antecipacoes = [];
            }
            dadosForma.push(aux);
        });
        return dadosForma;
    };

    async function preencherDadosDestinatario(form) {
        let dadosDestinatario = {};
        let enderecoDest = dadosEndereco;
        let telefoneDest = dadosFornecedor.telefones?.filter(pt => pt.psc_id === form.telefoneDestinatario)[0];
        let emailDest = dadosFornecedor.emails?.filter(pem => pem.pem_id === form.ntf_emaildestinatario)[0];
        // dadosDestinatario.pes_id = dadosFornecedord.pes_id;
        dadosDestinatario.pes_fisicajuridica = dadosFornecedor.pes_fisicajuridica;
        dadosDestinatario.ntf_nomedest = dadosFornecedor.pes_nome;
        dadosDestinatario.ntf_cnpjcpfdest = (!!dadosFornecedor.pej_cnpj ? dadosFornecedor.pej_cnpj : dadosFornecedor.pef_cpf);
        dadosDestinatario.ntf_iedest = form.pej_inscricaoestadual;
        dadosDestinatario.ntf_idestrangeirodest = dadosFornecedor.pes_idestrangeiro;
        dadosDestinatario.ntf_logradourodest = enderecoDest?.log_logradouro;
        dadosDestinatario.ntf_numeroenderecodest = enderecoDest?.pee_numero;
        dadosDestinatario.ntf_complementodest = enderecoDest?.complemento;
        dadosDestinatario.ntf_bairrodest = enderecoDest?.bai_nome;
        dadosDestinatario.ntf_cepdest = enderecoDest?.cep_cep;
        dadosDestinatario.ntf_ufdest = enderecoDest?.est_sigla;
        dadosDestinatario.ntf_telefonedest = telefoneDest?.psc_numero ?? '';
        dadosDestinatario.ntf_emaildest = emailDest?.pem_email ?? null;
        return dadosDestinatario;
    };

    function preencherDadosTransportadores(form) {
        let dadosTransportador = {};
        let veiculoTransp = listaVeiculos.filter((veiculo) => (veiculo.trv_id = form.trv_id))[0];
        dadosTransportador.ntf_modalidadefrete = form.ntf_modalidadefrete;
        dadosTransportador.trn_id = !!dadosTransp && !!dadosTransp.trn_id ? dadosTransp.trn_id : null;
        dadosTransportador.trv_id = !!veiculoTransp ? veiculoTransp.trv_id : null;
        dadosTransportador.trv_observacao = !!veiculoTransp ? veiculoTransp.trv_observacao : null;
        dadosTransportador.trv_placa = !!veiculoTransp ? veiculoTransp.trv_placa : null;
        dadosTransportador.trv_uf = !!veiculoTransp ? veiculoTransp.trv_uf : null;
        dadosTransportador.trv_marca = !!veiculoTransp ? veiculoTransp.trv_marca : null;
        dadosTransportador.trv_modelo = !!veiculoTransp ? veiculoTransp.trv_modelo : null;
        dadosTransportador.trv_rntc = !!veiculoTransp ? veiculoTransp.trv_rntc : null;
        dadosTransportador.nvl_pesoliquido = form.nvl_pesoliquido;
        dadosTransportador.nvl_qtdevolumes = form.nvl_qtdevolumes;
        dadosTransportador.nvl_pesobruto = form.nvl_pesobruto;
        dadosTransportador.nvl_especievolume = form.nvl_especievolume;
        dadosTransportador.nvl_marcavolume = form.nvl_marcavolume;
        return dadosTransportador;
    };
    function preencherDadosSerieFiscal(dados) {
        let dadosSerieFiscal = listaSerie.filter((item) => (item.ser_id === dados.ser_id))[0];
        return dadosSerieFiscal;
    }

    async function SalvarNota(dados) {
        carregando(true);
        let res;
        let editando = false;
        /*
        if (!!dados.ntf_id) {
            res = await api.put("NotaFiscal/Editar", dados);
        } else {
            res = await api.post("NotaFiscal/Incluir", dados);
        }
        if (res.status == 200) {
            notification.success({ message: 'Sucesso', description: `Nota ${editando ? 'editada' : 'incluída'} com sucesso!` });

            if (res.data.ntf_status === 1 && !!res.data.ntf_chavenfe && !!res.data.ntf_docfiscal) {
                novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${res.data.ntf_chavenfe}`);
            }
        }*/
        carregando(false);
    }

    function calcularFrete() {
        let valorFrete = 0;
        manutencao.dados.itensNf.forEach((item) => {
            if (!!item.ped_id) {
                valorFrete += parseFloat(!!item.pdi_valorfrete ? item.pdi_valorfrete : 0);
            } else {
                valorFrete += parseFloat(!!item.nfi_valorfrete ? item.nfi_valorfrete : 0);
            }
        });
        form.setFieldsValue({ valorFrete: valorFrete });
    };

    return (
        <div className="pages-col">
            <Spin spinning={carregarPagina} tip="Carregando...">
                <FormGW layout="vertical" name="formNotaFiscal" form={form} onFinish={ValidarNota}>
                    <Row gutter={[8, 0]}>
                        {!!editando && <Col xs={24} sm={8} md={3} lg={3} xl={3}>
                            <Form.Item label="Nº da Nota" name="ntf_numero">
                                <Input disabled />
                            </Form.Item>
                        </Col>}
                        <Col xs={24} sm={8} md={3} lg={4} xl={4}>
                            <Form.Item label="Série" name="ser_id">
                                <Select disabled={editando} allowClear placeholder="Selecione uma Série">
                                    {listaSerie.map((ser) => (
                                        <Select.Option key={ser.ser_id} value={ser.ser_id}>{ser.ser_serie}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={editando ? 8 : 6} md={4} lg={4} xl={4}>
                            <Data label="Data de Emissão" disabled={editando} name="ntf_dataemissao" initialValue={new Date()} />
                        </Col>
                        <Col xs={24} sm={editando ? 12 : 10} md={editando ? 6 : 8} lg={editando ? 6 : 8} xl={editando ? 6 : 8}>
                            <Form.Item label="Operação" name="ope_id" rules={[{ required: true, message: 'Informe a Operação' }]}>
                                <SelectPaginacao url="OperacaoFiscal/ListarOperacaoFiscal?SomenteAtivo=true&EntradaSaida=0" placeholder="Selecione a Operação Fiscal" form={form} nameValue="ope_id" nameLabel="ope_descricao" onChangeFunction={(idOperacao) => popularOperacaoFiscal(idOperacao)} conteudo={
                                    op => (<Select.Option value={op.ope_id} key={op.key}>{op.ope_descricao}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[0, 24]}>
                        <Col span={24}>
                            <Steps size="small" type="navigation" onChange={(e) => {
                                proximaPag.setProximaPag(e);
                            }} current={pages.tagPages}>
                                <Steps.Step title="Fornecedor" />
                                <Steps.Step title="Notas" />
                                <Steps.Step title="Itens" />
                                <Steps.Step title="Transporte" />
                                <Steps.Step title="Faturamento" />
                                <Steps.Step title="Resumo" />
                            </Steps>
                        </Col>
                    </Row>
                    <Row gutter={[8, 24]}>
                        <Col span={24}>
                            {pages.tagPages === 0 &&
                                <FornecedorNFE form={form} dadosEndereco={setDadosEndereco} carregandoPagina={setCarregarPagina} setDadosFornecedor={setDadosFornecedor} />
                            }
                            {/* {pages.tagPages === 1 &&
                                <NotasBlocoNFE form={form} />
                            } */}
                            {pages.tagPages === 2 &&
                                <ItensNFE form={form} dadosItens={{ dadosItens, setDadosItens }} dadosEndereco={dadosEndereco} dadosOperacao={operacaoFiscal} />
                            }
                            {pages.tagPages === 3 &&
                                <TransportadorNFE form={form} veiculos={{ listaVeiculos, setListaVeiculos }} dadosTransp={{ dadosTransp, setDadosTransp }} />
                            }
                            {pages.tagPages === 4 &&
                                <FaturamentoNFE form={form} dadosItens={dadosItens} dadosOperacao={operacaoFiscal} />
                            }
                            {pages.tagPages === 5 &&
                                <ResumoNFE form={form} itensNota={dadosItens} />
                            }

                        </Col>
                    </Row>
                </FormGW>
            </Spin>
        </div>
    );
}
