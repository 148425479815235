import React, { useState, useEffect } from "react";
import { Row, Col, Form, Divider, Input, Select, Button, Table, Tag, notification } from "antd";
import { InputCep, SelectPaginacao } from "../../components";
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import api from "../../services/api";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";

export default function TabDadosManifestos({ formManifesto, listaDocs, tipoEmitente, setEstadoInicio, setEstadoFinal, listaMotorista, setListaMotorista }) {
    const [veiculoSelecionado, setVeiculoSelecionado] = useState(false);
    const [reboque1Selecionado, setReboque1Selecionado] = useState(null);
    const [reboque2Selecionado, setReboque2Selecionado] = useState(null);
    const [cpfCnpj, setCpfCnpj] = useState(null);
    const [listaCidadesCarregamento, setListaCidadesCarregamento] = useState(false);
    const [listaCidadesDescarrega, setListaCidadesDescarrega] = useState(false);
    const [filtroCddCarregamento, setFiltroCddCarregamento] = useState(null);
    const [filtroCddDescarrega, setFiltroCddDescarrega] = useState(null);
    const [listaEstados, setListaEstados] = useState([]);
    const [listaVeiculos, setListaVeiculos] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [estadosRes, veiculosRes] = await Promise.all([
                    api.get(`estado/listar?PageSize=50&Order=est_id`),
                    api.get(`Veiculo/listar`)
                ]);

                setListaEstados(estadosRes.data.items);
                setListaVeiculos(veiculosRes.data.items);
                if (listaDocs.length > 0) {
                    const dadosDoc = listaDocs[0];
                    if (dadosDoc.numero && listaDocs.length === 1) {
                        if (dadosDoc.cepDestino) {
                            api.get(`Cidade/BuscaCidadeCep?cep=${dadosDoc.cepDestino}`).then(
                                res => {
                                    let dadosDestino = res.data[0];
                                    formManifesto.setFieldsValue({
                                        pme_cepcarrega: dadosDestino.cep_cep,
                                        mca_id: dadosDestino.cid_descricao,
                                        mdf_ufinicio: dadosDestino.est_descricao
                                    });
                                    setEstadoFinal(dadosDestino.est_sigla);
                                }
                            ).catch(
                                error => {
                                    console.log(error);
                                }
                            )
                        }
                        if (dadosDoc.cepOrigem) {
                            api.get(`Cidade/BuscaCidadeCep?cep=${dadosDoc.cepOrigem}`).then(
                                res => {
                                    let dadosOrigem = res.data[0];
                                    formManifesto.setFieldsValue({
                                        pme_cepdescarrega: dadosOrigem.cep_cep,
                                        mdf_uffim: dadosOrigem.est_descricao,
                                        mde_id: dadosOrigem.cid_descricao
                                    });
                                    setEstadoInicio(dadosOrigem.est_sigla);
                                }
                            ).catch(
                                error => {
                                    console.log(error);
                                }
                            )
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();

        if (listaDocs[0].doc_modelodoc === "55") {
            formManifesto.setFieldsValue({
                mdf_tipoemitente: 2
            })
        }

    }, [listaDocs])

    function reboque1(value) {
        setReboque1Selecionado(value);
        setReboque2Selecionado(null)
        formManifesto.setFieldsValue({ vei_id_reboque2: undefined, vei_id_reboque3: undefined })
    };

    function reboque2(value) {
        setReboque2Selecionado(value);
        formManifesto.setFieldsValue({ vei_id_reboque3: undefined })
    };

    function validarCpfCnpj(cpfCnpj) {
        if (cpfCnpj) {
            if (cpfCnpj.length !== 14 && cpfCnpj.length !== 11) {
                notification.warning({ message: 'Aviso!', description: 'O CPF/CNPJ informado é inválido. Deve conter 11 ou 14 dígitos' });
                formManifesto.setFieldsValue({
                    rod_cpfcnpjciot: ""
                })
            }
        }
    }

    function verificaCepCarregamento(cep) {
        const cepNumeros = cep.match(/\d+/g)?.join('');

        if (cepNumeros && cepNumeros.length === 8) {
            api.get(`Cidade/BuscaCidadeCep?cep=${cepNumeros}`).then(
                res => {
                    let dadosOrigem = res.data[0]
                    formManifesto.setFieldsValue({
                        mdf_ufinicio: dadosOrigem.est_descricao,
                        mca_id: dadosOrigem.cid_descricao
                    });
                    setEstadoInicio(dadosOrigem.est_sigla);
                }
            ).catch(error => {
                notification.warning({ message: 'Atenção!', description: 'Erro ao buscar o CEP' });
                console.error(error);
            })
        } else {
            notification.warning({ message: 'Atenção!', description: 'CEP inválido' });
            formManifesto.setFieldsValue({
                mdf_ufinicio: '',
                mca_id: ''
            });
            setEstadoInicio('');
        }
    }

    function verificaCepDescarrega(cep) {
        const cepNumeros = cep.match(/\d+/g)?.join('');

        if (cepNumeros && cepNumeros.length === 8) {
            api.get(`Cidade/BuscaCidadeCep?cep=${cepNumeros}`).then(
                res => {
                    let dadosDestino = res.data[0]
                    formManifesto.setFieldsValue({
                        mdf_uffim: dadosDestino.est_descricao,
                        mde_id: dadosDestino.cid_descricao
                    });
                    setEstadoFinal(dadosDestino.est_sigla);
                }
            ).catch(error => {
                notification.warning({ message: 'Atenção!', description: 'Erro ao buscar o CEP' });
                console.error(error);
            })
        } else {
            notification.warning({ message: 'Atenção!', description: 'CEP inválido' });
            formManifesto.setFieldsValue({
                mdf_uffim: '',
                mde_id: ''
            });
            setEstadoFinal('');
        }
    }

    function removeMotorista(record) {
        let index = listaMotorista.findIndex(mot => mot.sequencia_motorista === record.sequencia_motorista)
        let dadosTemp = [...listaMotorista];
        dadosTemp.splice(index, 1)

        dadosTemp.forEach((item, index) => {
            item.sequencia_motorista = index + 1;
        })

        setListaMotorista(dadosTemp);
    }

    function adicionarMotorista() {
        let motorista = formManifesto.getFieldValue().fun_id;

        let listaTemp = [...listaMotorista];
        if (motorista !== undefined) {
            api.get(`Funcionario/BuscarFuncionario/${motorista}`).then(
                res => {
                    let retorno = res.data;

                    listaTemp.push({
                        fun_id: motorista,
                        sequencia_motorista: listaMotorista.length + 1,
                        nome: retorno.pes_nome || 'Não informado',
                        cpf: retorno.pef_cpf || 'Não informado',
                        numeroTelefone: retorno.telefones[0].psc_numero || 'Não informado'
                    })

                    setListaMotorista(listaTemp);
                    formManifesto.setFieldsValue({ fun_id: undefined });
                    notification.success({ message: 'Atenção', description: 'Motorista adicionado com sucesso' });
                }
            ).catch(
                error => {
                    console.log(error);
                    notification.error({ message: 'Atenção!', description: 'Dados do motorista não encontrado' });
                }
            )
        } else {
            notification.warning({ message: 'Alerta!', description: 'É necessário selecionar o motorista' });
        }
    }

    return (
        <div className="pages-col">
            <Form form={formManifesto}>
                <Row gutter={[8, 0]} style={{ marginBottom: '15px' }}>
                    <Col xs={24} sm={12} md={12} lg={7} xl={7} >
                        <InputCep label="CEP Origem" name="pme_cepcarrega" onBlur={(cep) => verificaCepCarregamento(cep.target.value)} rules={[{ required: true }]} />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item label="Carregamento" name="mca_id" rules={[{ required: true }]}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item label="Início de Carga" name="mdf_ufinicio" rules={[{ required: true }]}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={7}>
                        <InputCep label="CEP Destino" name="pme_cepdescarrega" onBlur={(cep) => verificaCepDescarrega(cep.target.value)} rules={[{ required: true }]} />
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item label="Descarregamento" name="mde_id" rules={[{ required: true }]}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item label="Final de Descarga" name="mdf_uffim" rules={[{ required: true }]}>
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={7}>
                        <Form.Item label="Tipo Emitente" name="mdf_tipoemitente" rules={[{ required: true }]}>
                            <Select placeholder="Selecione o Tipo Emitente" showSearch allowClear>
                                {tipoEmitente.map((item) => (
                                    <Select.Option value={item.key} key={item.key}>{item.value}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item label="CIOT" name="rod_ciot">
                            <Input maxLength={16} placeholder="Código Identificador da Operação de Transporte" />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item label="CPF/CNPJ CIOT" name="rod_cpfcnpjciot">
                            <CpfCnpj
                                className="inputCpfCnpj"
                                placeholder="Informe o CPF ou CNPJ"
                                id="cpf"
                                value={cpfCnpj}
                                onBlur={valor => validarCpfCnpj(valor.currentTarget.value.replace(/\D/g, '')) }
                                onChange={(event) => {
                                    setCpfCnpj(event.target.value);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Veículo</Divider>
                <Row gutter={[8, 0]} style={{ marginBottom: '20px' }}>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item label="Veículo" name="vei_id" rules={[{ required: true }]}>
                            <Select placeholder="Selecione o Veículo" allowClear onSelect={() => setVeiculoSelecionado(true)} onClear={() => setVeiculoSelecionado(false)} >
                                {listaVeiculos.map(vei => (
                                    <Select.Option value={vei.vei_id} key={vei.vei_id}> {vei.vei_descricao} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item label="Reboque 1" name="vei_id_reboque1">
                            <Select placeholder="Selecione o Reboque" allowClear onSelect={(value) => reboque1(value)} onClear={() => reboque1(null)} disabled={!veiculoSelecionado} >
                                {listaVeiculos.filter(vei => vei.vei_reboque).map(vei => (
                                    <Select.Option value={vei.vei_id} key={vei.vei_id}> {vei.vei_descricao} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item label="Reboque 2" name="vei_id_reboque2">
                            <Select placeholder="Selecione o Reboque" allowClear onSelect={(value) => reboque2(value)} onClear={() => reboque2(null)} disabled={!reboque1Selecionado} >
                                {listaVeiculos.filter(vei => vei.vei_reboque && vei.vei_id !== reboque1Selecionado).map(vei => (
                                    <Select.Option value={vei.vei_id} key={vei.vei_id}> {vei.vei_descricao} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item label="Reboque 3" name="vei_id_reboque3">
                            <Select placeholder="Selecione o Reboque" allowClear disabled={!reboque2Selecionado} >
                                {listaVeiculos.filter(vei => vei.vei_reboque && vei.vei_id !== reboque1Selecionado && vei.vei_id !== reboque2Selecionado).map(vei => (
                                    <Select.Option value={vei.vei_id} key={vei.vei_id}> {vei.vei_descricao} </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Divider orientation="left">Motorista</Divider>
                <Row gutter={[8, 0]}>
                    <Col xs={24} sm={12} md={12} lg={7} xl={6}>
                        <Form.Item label="Motorista" name="fun_id">
                            <SelectPaginacao url="Funcionario/Listar" placeholder="Selecione o Transportador" form={formManifesto} nameValue="fun_id" nameLabel="pes_nome" allowClear={true} conteudo={
                                fun => (<Select.Option value={fun.fun_id} key={fun.fun_id}>{fun.pes_nome}</Select.Option>)
                            } />
                        </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={3} xxl={3} className="add-button">
                        <Button icon={<PlusOutlined />} type="primary" onClick={() => adicionarMotorista()} >
                            Adicionar
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Table dataSource={listaMotorista} pagination={false} columns={[
                            {
                                title: 'Sequência',
                                width: 60,
                                align: 'center',
                                render: ({ sequencia_motorista }) => (
                                    <Tag color="processing" className="w-100">
                                        <b>{sequencia_motorista}</b>
                                    </Tag>
                                )
                            },
                            {
                                title: 'ID',
                                align: 'center',
                                width: 80,
                                render: ({ fun_id }) => (
                                    <b>{fun_id}</b>
                                )
                            },
                            {
                                title: 'Nome',
                                align: 'left',
                                width: 400,
                                render: ({ nome }) => (
                                    <b>{nome}</b>
                                )
                            },
                            {
                                title: 'CPF',
                                align: 'center',
                                width: 150,
                                render: ({ cpf }) => (
                                    <b>{cpf}</b>
                                )
                            },
                            {
                                title: 'Telefone',
                                align: 'center',
                                width: 150,
                                render: ({ numeroTelefone }) => (
                                    <b>{numeroTelefone}</b>
                                )
                            },
                            {
                                title: 'Ações',
                                align: 'center',
                                fixed: 'right',
                                dataIndex: '',
                                key: 'x',
                                width: 65,
                                render: (record) => {
                                    return (
                                        <div>
                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                <Col>
                                                    <Button icon={<DeleteOutlined />} onClick={() => removeMotorista(record)} />
                                                </Col>
                                            </Row>
                                        </div>
                                    )
                                }
                            }
                        ]}
                            scroll={{ y: 100 }}
                        />
                    </Col>
                </Row>
            </Form>
        </div>
    );
}