import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, InputNumber, Checkbox, Modal } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import InputPreco from "../../inputPreco";
import SelectPaginacao from "../../selects/selectPaginacao";
import { manutencaoActions } from "../../../actions";
import { useStateValue } from "../../../state";

export default function ModalListaPreco({ form, listaPreco, setListaPreco, exibirModal, fecharModal }) {
    const [{ listaSelectPaginacao }, dispatch] = useStateValue();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                form.resetFields();
                fecharModal();
            }
        });
    };

    const salvarListaDePreco = () => {
        const newData = [...listaPreco];
        let values = form.getFieldsValue();
        if (!!form.getFieldValue().key || form.getFieldValue().key === 0) {
            let item = newData.filter((item) => (item.key === values.key))[0];
            item.lpi_valorvenda = values.lpi_valorvenda;
            item.lpi_perccomissao = values.lpi_perccomissao;
            item.lpi_naocalculacomissao = values.lpi_naocalculacomissao;
        } else {
            let listasPrecos = [...listaSelectPaginacao.itens];
            let nomeLista = listasPrecos.find(t => t.ltp_id === values.ltp_id);
            values.ltp_nome = nomeLista.ltp_nome;
            values.ltp_principal = false;
            values.key = newData.length;
            newData.push(values);
        }
        setListaPreco(newData);
        fecharModal();
    };

    function verificaListaPrecoExistente(lp) {
        let dados = {};
        dados = listaPreco.filter(l => l.ltp_id === lp);
        form.setFieldsValue(dados[0]);
    };

    return (
        <Modal centered
            title="Cadastro Lista de Preço deste Produto"
            visible={exibirModal}
            onCancel={onCloseModal}
            onOk={salvarListaDePreco}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formListaPreco" form={form}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={16} md={16} lg={16} xl={15}>
                            <Form.Item hidden name="key"></Form.Item>
                            <Form.Item label="Lista de Preço" name="ltp_id">
                                <SelectPaginacao
                                    form={form}
                                    url="ListaPreco/ListarTodasListaPreco"
                                    disabled={form.getFieldValue().lpi_id ? true : false}
                                    nameLabel="ltp_nome"
                                    nameValue="ltp_id"
                                    onChangeFunction={(lp) => {
                                        verificaListaPrecoExistente(lp);
                                    }}
                                    placeholder="Selecione uma Lista"
                                    conteudo={(l) => (<Select.Option key={l.key} value={l.ltp_id}>{l.ltp_nome}</Select.Option>)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={9}>
                            <InputPreco rules={[{ required: true, message: 'Informe o valor de venda da lista de preço.' }]} label="Valor de Venda Lista (R$)" name="lpi_valorvenda" />
                        </Col>
                        <Col xs={24} sm={8} md={6} lg={8} xl={8}>
                            <Form.Item label="Percentual de Comissão" name="lpi_perccomissao">
                                <InputNumber
                                    defaultValue={0}
                                    min={0}
                                    max={100}
                                    formatter={value => `${value}%`}
                                    parser={value => value.replace('%', '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item name="lpi_naocalculacomissao" valuePropName="checked" className="t-mob-573">
                                <Checkbox>Não Calcula Comissão?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}