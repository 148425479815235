import React, { useState, useEffect } from 'react';
import { FiltroProdutoNfi } from '../../components';
import { useStateValue } from '../../state';
import { Button, Col, Modal, Row, Table, Tag } from 'antd';
import { FormatNumber } from '../../ValueObjects';
import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';


export default function ItensNFE({ form, dadosItens, dadosEndereco, dadosOperacao, carregarPagina }) {
    const [quantidadeItens, setQuantidadeItens] = useState(0);
    const [valorTotal, setValorTotal] = useState(0);
    const [editando, setEditando] = useState(false);
    const [dadosEditando, setDadosEditando] = useState({});

    useEffect(() => {
        if (dadosItens.dadosItens.length > 0) {
            let total = 0;
            setQuantidadeItens(dadosItens.dadosItens.length);
            dadosItens.dadosItens.forEach((item) => {
                total += parseFloat(item.total);
            });
            setValorTotal(total);
        } else {
            setQuantidadeItens(0);
            setValorTotal(0);
        }
    }, [dadosItens.dadosItens]);

    function editarItemNota(registro) {
        setDadosEditando(registro);
        setEditando(true);
    };

    function removerItemNota(record, indice) {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja remover o item da nota?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                let dadosTemp = [...dadosItens.dadosItens];
                let dadosRet = dadosTemp.filter((item) => item.nfi_numeroitem !== record.nfi_numeroitem);
                dadosItens.setDadosItens(dadosRet);
            }
        });
    };

    return (
        <div className="m-t-16">
            <FiltroProdutoNfi form={form} dadosTabela={dadosItens} dadosEndereco={dadosEndereco} dadosOperacao={dadosOperacao} btnEditando={{ editando, setEditando }} itemEditando={dadosEditando} carregarPagina={carregarPagina} />
            <div className="tabela mt-dif">
                {!!dadosItens.dadosItens && dadosItens.dadosItens.length > 0 && <Table columns={[
                    {
                        title: 'ITEM',
                        width: 220,
                        render: ({ pro_codigo, pro_descricao, nfi_ean, grade }) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <Tag color="processing" className="w-75">
                                            <b>{pro_codigo}</b>
                                        </Tag>
                                        <b> {pro_descricao}</b>
                                    </Col>
                                </Row>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        EAN: <b>{nfi_ean}</b>
                                    </Col>
                                    <Col hidden={!!!grade}>
                                        Grade: <b>{grade}</b>
                                    </Col>
                                </Row>
                            </div>
                        )
                    },
                    {
                        title: 'CFOP',
                        align: 'center',
                        width: 30,
                        render: ({ nfi_cfop }) => (
                            <div>
                                <b>{nfi_cfop}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Qtde.',
                        width: 40,
                        align: 'center',
                        render: ({ nfi_qtde }) => (
                            <div>
                                <b>{FormatNumber(nfi_qtde, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Unt.(R$)',
                        align: 'right',
                        width: 50,
                        render: ({ nfi_valorunitario }) => (
                            <div>
                                <b>{FormatNumber(nfi_valorunitario, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Desc.',
                        align: 'center',
                        width: 40,
                        render: ({ percdesc }) => (
                            <div>
                                <b>{percdesc}%</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Acres.',
                        align: 'center',
                        width: 40,
                        render: ({ percacresc }) => (
                            <div>
                                <b>{percacresc}%</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr Total (R$)',
                        align: 'right',
                        width: 60,
                        render: ({ total }) => (
                            <div>
                                <b>{FormatNumber(total, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 40,
                        fixed: 'right',
                        render: (record, e, indice) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button icon={<EditOutlined />} onClick={() => editarItemNota(record)} />
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => removerItemNota(record, indice)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} dataSource={dadosItens.dadosItens} expandable={({ nig_valorbc, nig_percicms, nig_valoricms, npi_valorbcipi, npi_percaliquotaipi, npi_valoripi, nig_valorbcst, nig_percmvast, nig_valoricmsst }) => {
                    return (
                        <div>
                            <Row align="middle" gutter={[16, 0]}>
                                <Col span={8}>
                                    ICMS Base de Cálculo: <b> {nig_valorbc}</b>
                                </Col>
                                <Col span={8}>
                                    Alíquota: <b>{nig_percicms}</b>
                                </Col>
                                <Col span={8} className="text-right">
                                    Valor (R$): <b>{nig_valoricms}</b>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[16, 0]}>
                                <Col span={8}>
                                    IPI Base de Cálculo: <b> {npi_valorbcipi}</b>
                                </Col>
                                <Col span={8}>
                                    Alíquota: <b>{npi_percaliquotaipi}</b>
                                </Col>
                                <Col span={8} className="text-right">
                                    Valor (R$): <b>{npi_valoripi}</b>
                                </Col>
                            </Row>
                            <Row align="middle" gutter={[16, 0]}>
                                <Col span={8}>
                                    ICMS ST Base de Cálculo: <b> {nig_valorbcst}</b>
                                </Col>
                                <Col span={8}>
                                    Alíquota: <b>{nig_percmvast}</b>
                                </Col>
                                <Col span={8} className="text-right">
                                    Valor (R$): <b>{nig_valoricmsst}</b>
                                </Col>
                            </Row>
                        </div>
                    )
                }} scroll={{ x: 900 }} />}
            </div>
            <div>
                <Row justify="end" gutter={[8, 0]}>
                    <Col className="f-16 m-t-3">
                        <b>Quantidade Itens:</b>
                    </Col>
                    <Col className="f-21">
                        <b>{quantidadeItens}</b>
                    </Col>
                </Row>
                <Row justify="end" gutter={[8, 0]}>
                    <Col className="f-16 m-t-3">
                        <b>Valor Total dos Itens (R$):</b>
                    </Col>
                    <Col className="f-21">
                        <b>{FormatNumber(valorTotal, true)}</b>
                    </Col>
                </Row>
            </div>
        </div>
    )
}