import React, { useEffect, useState } from "react";
import { Data, FormGW } from "../../components";
import { Row, Col, Form, Select, Steps, Input, Divider, notification, Typography, Button, Modal, Table, Tag } from 'antd';

import api from "../../services/api";
import { validaForm } from "../../services/funcoes";
import { useStateValue } from "../../state";
import { TabDadosManifestos, TabPercursoMDF, TabSegEProdPredominante } from "./tabPage";
import moment from "moment";
import ModalDocsFiscais from "../../components/modals/modalDocsFiscais";
import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { FormatNumber } from "../../ValueObjects";

export default function ManutencaoManifestoEletronico({ formManifesto, carregando, aoSalvar, pages, proximaPag }) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [permiteValidar, setPermiteValidar] = useState(true);
    const [percursoOk, setPercursoOk] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [estadoInicioSelect, setEstadoInicioSelect] = useState(null);
    const [estadoFinalSelect, setEstadoFinalSelect] = useState(null);
    const [serie, setSerie] = useState([]);
    const [listaPercurso, setListaPercurso] = useState([]);
    const [listaSeguro, setListaSeguro] = useState([]);
    const [listaDocs, setListaDocs] = useState([]);
    const [listaMotorista, setListaMotorista] = useState([]);
    const [tipoEmitente, setTipoEmitente] = useState([]);
    const [tipoCarga, setTipoCarga] = useState([]);
    const [unidadePeso, setUnidadePeso] = useState([]);
    const [responsavelSeguro, setResponsavelSeguro] = useState([]);

    useEffect(() => {
        if (ui.showDrawer) {
            carregarDados();
            if (!formManifesto.getFieldValue().mdf_dataemissao) {
                formManifesto.setFieldsValue({ mdf_dataemissao: moment(new Date()) });
            }
        }
    }, [ui.showDrawer])

    useEffect(() => {
        api.get(`Enum/Listar?nome=TipoEmitente`).then(res => {
            if (res.status === 200) {
                setTipoEmitente(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=ResponsavelSeguro`).then(res => {
            if (res.status === 200) {
                setResponsavelSeguro(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=TipoCarga`).then(res => {
            if (res.status === 200) {
                setTipoCarga(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=CodigoUnidadePeso`).then(res => {
            if (res.status === 200) {
                setUnidadePeso(res.data);
            }
        }).catch((erro) => console.error(erro));
    }, [])

    useEffect(() => {
        if ((!!proximaPag.proximaPag || proximaPag.proximaPag === 0) && permiteValidar) {
            validarTrocaPg(proximaPag.proximaPag);
        } else {
            setPermiteValidar(true);
        }
    }, [proximaPag.proximaPag])

    async function carregarDados() {
        let listaSerie = await api.get('SerieFiscal/Listar?CodigoModeloDocFiscal=58&EmitenteDocumento=0&SomenteAtivos=true');
        let serie = [];
        if (listaSerie.status === 200) {
            serie = listaSerie.data.items;
            setSerie(serie);
            if (serie.length > 0) {
                formManifesto.setFieldsValue({ ser_id: serie[0].ser_id });
            }
        }
    }

    async function validarTrocaPg(pagina) {
        let paginaAtual = pages.tagPages;
        let listaValidacoes = [];
        switch (paginaAtual) {
            case 0:
                if (paginaAtual < pagina) {
                    if (listaDocs.length === 0) {
                        notification.warning({ message: 'Atenção!', description: 'Para prosseguir, deve ter pelo menos um Documento Fiscal.' })
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
            case 1:
                if (paginaAtual < pagina) {
                    let f = form.getFieldValue();
                    if (f.pme_cepcarrega === undefined || f.mca_id === undefined || f.mdf_ufinicio === undefined || f.pme_cepdescarrega === undefined || f.mde_id === undefined ||
                        f.mdf_uffim === undefined || f.vei_id === undefined
                    ) {
                        notification.warning({ message: 'Atenção!', description: 'Preencha todos os campos obrigatórios.' })
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                    if (listaMotorista.length === 0) {
                        notification.warning({ message: 'Atenção!', description: 'Informe pelo menos um motorista' });
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
            case 2:
                if (paginaAtual < pagina) {
                    if (listaPercurso.length === 0 && !percursoOk) {
                        notification.warning({ message: 'Atenção!', description: 'Para prosseguir, um percurso deve ser definido.' })
                        setPermiteValidar(false);
                        proximaPag.setProximaPag(paginaAtual);
                        return false;
                    }
                }
                break;
        }
        if (validaForm(formManifesto, listaValidacoes)) {
            pages.setTagPages(pagina);
        } else {
            setPermiteValidar(false);
            proximaPag.setProximaPag(paginaAtual);
        }
    }

    const modalExcluir = (record) => {
        const index = listaDocs.findIndex(doc => doc.doc_sequencia === record.doc_sequencia)
        Modal.confirm({
            title: 'Excluir',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja excluir o Documento Fiscal?',
            okText: 'Excluir',
            cancelText: 'Cancelar',
            centered: true,
            onOk() {
                removerDoc(index)
            }
        });
    }

    const removerDoc = (index) => {
        let dadosTemp = [...listaDocs];
        dadosTemp.splice(index, 1)

        dadosTemp.forEach((item, index) => {
            item.doc_sequencia = index + 1;
        })

        setListaDocs(dadosTemp);
    }

    async function emitirMDFe() {
        debugger;
        carregando(true)
        const formManifes = formManifesto.getFieldValue();
        const dadosNewForm = form.getFieldValue();
        if (dadosNewForm.pme_descricao !== undefined && dadosNewForm.pme_tipocarga !== undefined && dadosNewForm.mdf_unidadepeso !== undefined) {
            let dados = {
                ser_id: formManifes.ser_id,
                mdf_infadicionalfisco: dadosNewForm.mdf_infadicionalfisco || null,
                mdf_infcomplentar: dadosNewForm.mdf_infcomplentar || null,
                mdf_ufinicio: estadoInicioSelect,
                mdf_uffim: estadoFinalSelect,
                mdf_tipoemitente: dadosNewForm.mdf_tipoemitente,
                mdf_unidadepeso: dadosNewForm.mdf_unidadepeso,

                produtopredominantemdfe: {
                    pme_ncm: dadosNewForm.pme_ncm,
                    pme_descricao: dadosNewForm.pme_descricao,
                    pme_ean: dadosNewForm.pme_ean,
                    pme_tipocarga: dadosNewForm.pme_tipocarga,
                    pme_cepcarrega: dadosNewForm.pme_cepcarrega,
                    pme_cepdescarrega: dadosNewForm.pme_cepdescarrega
                },
                modalrodoviariomdfe: {
                    vei_id: dadosNewForm.vei_id,
                    rod_ciot: dadosNewForm.rod_ciot,
                    rod_cpfcnpjciot: dadosNewForm.rod_cpfcnpjciot,
                    vei_id_reboque1: dadosNewForm.vei_id_reboque1 || null,
                    vei_id_reboque2: dadosNewForm.vei_id_reboque2 || null,
                    vei_id_reboque3: dadosNewForm.vei_id_reboque3 || null
                },

                motoristasmdfe: listaMotorista.map(motorista => ({ fun_id: motorista.fun_id })),
                seguromdfe: [...listaSeguro],
                percursomdfe: [...listaPercurso],
                docreferenciadomdfe: [...listaDocs],
            };

            api.post(`ManifestoEletronico/Incluir`, dados).then(
                res => {
                    let teste = res;
                }
            ).catch(
                erro => {
                    console.log(erro)
                }
            ).finally(
                () => {
                    carregando(false);
                    aoSalvar()
                }
            )
        } else {
            notification.warning({ message: 'Atenção!', description: 'Preencha os campos obrigatórios' });
            carregando(false);
        }
    }

    return (
        <div className="pages-col">
            <FormGW layout="vertical" form={formManifesto} name="formManifesto" onFinish={emitirMDFe}>
                <Row gutter={[8, 0]} hidden={pages.tagPages == 2}>
                    <Col xs={24} sm={8} md={3} lg={3} xl={3}>
                        <Form.Item label="Número" name="mdf_numero">
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={3} lg={4} xl={3}>
                        <Form.Item label="Série" name="ser_id">
                            <Select allowClear placeholder="Selecione uma Série">
                                {serie.map((ser) => (
                                    <Select.Option key={ser.ser_id} value={ser.ser_id}>{ser.ser_serie}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6} md={4} lg={4} xl={4}>
                        <Data label="Data de Emissão" name="mdf_dataemissao" />
                    </Col>
                </Row>
                <Row gutter={[0, 24]}>
                    <Col span={24}>
                        <Steps size="small" type="navigation" onChange={(e) => {
                            proximaPag.setProximaPag(e);
                        }} current={pages.tagPages}>
                            <Steps.Step title="Documentos Fiscais" />
                            <Steps.Step title="Dados do Manifesto" />
                            <Steps.Step title="Percurso" />
                            <Steps.Step title="Seguro/Produto Predominante" />
                        </Steps>
                    </Col>
                </Row>
                <Row gutter={[8, 24]}>
                    <Col span={24}>
                        {pages.tagPages === 0 &&
                            <div className="m-t-16">
                                <Row align="middle">
                                    <Col span={24}>
                                        <Typography.Title level={3}>
                                            <img src={require("../../assets/i-info.png").default} alt="Adicione os Documentos Fiscais do Manifesto" />
                                            Adicione os Documentos Fiscais do Manifesto
                                        </Typography.Title>
                                        <Divider orientation="left" plain>Adicione os Documentos Fiscais do Manifesto</Divider>
                                    </Col>
                                </Row>
                                <Form layout="vertical" form={form}>
                                    <Row align="middle" gutter={[8, 0]}>
                                        <Col style={{ marginTop: '14px' }}>
                                            <Button type="primary" size="small" icon={<PlusOutlined />} onClick={() => { form.resetFields(); setOpenModal(true); }} style={{ marginBottom: '-3px' }}> ADICIONAR DOCUMENTO </Button>
                                        </Col>
                                    </Row>
                                </Form>
                                <div className="tabela mt-dif">
                                    <Form form={form} component={false}>
                                        <Table dataSource={listaDocs} columns={[
                                            {
                                                title: 'Sequência',
                                                width: 75,
                                                align: 'center',
                                                render: ({ doc_sequencia }) => (
                                                    <Tag color="processing" className="w-100">
                                                        <b>{doc_sequencia}</b>
                                                    </Tag>
                                                )
                                            },
                                            {
                                                title: 'Chave',
                                                render: ({ doc_chaveacesso }) => (
                                                    <b>{doc_chaveacesso}</b>
                                                )
                                            },
                                            {
                                                title: 'Modelo',
                                                width: 75,
                                                align: 'center',
                                                render: ({ doc_modelodoc }) => (
                                                    <b>{doc_modelodoc}</b>
                                                )
                                            },
                                            {
                                                title: 'Peso',
                                                width: 100,
                                                align: 'center',
                                                render: ({ doc_pesobruto }) => (
                                                    <b>{doc_pesobruto}</b>
                                                )
                                            },
                                            {
                                                title: 'Valor Total',
                                                width: 130,
                                                align: 'right',
                                                render: ({ doc_valortotal }) => (
                                                    <div>
                                                    <b>R$ {FormatNumber(doc_valortotal, true)}</b>
                                                    </div>
                                                )
                                            },
                                            {
                                                title: 'Ações',
                                                dataIndex: '',
                                                align: 'center',
                                                key: 'x',
                                                width: 65,
                                                fixed: 'right',
                                                render: (record) => {
                                                    return (
                                                        <div>
                                                            <Row align="middle" justify="center" gutter={[5, 0]}>
                                                                <Col>
                                                                    <Button onClick={() => modalExcluir(record)} icon={<DeleteOutlined />} />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    );
                                                },
                                            },
                                        ]} locale={{
                                            emptyText: (
                                                <Row>
                                                    <Col span={24}>
                                                        <Divider orientation="center">
                                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                                        </Divider>
                                                    </Col>
                                                </Row>
                                            )
                                        }} />
                                    </Form>
                                </div>
                                <ModalDocsFiscais form={form} exibirModal={openModal} fecharModal={() => setOpenModal(false)} listaDocs={listaDocs} setListaDocs={setListaDocs} />
                            </div>
                        }
                        {pages.tagPages === 1 &&
                            <TabDadosManifestos formManifesto={form} listaDocs={listaDocs} setListaDocs={setListaDocs} tipoEmitente={tipoEmitente} setEstadoInicio={setEstadoInicioSelect}
                                setEstadoFinal={setEstadoFinalSelect} listaMotorista={listaMotorista} setListaMotorista={setListaMotorista} />
                        }
                        {pages.tagPages === 2 &&
                            <TabPercursoMDF estadoInicio={estadoInicioSelect} estadoFinal={estadoFinalSelect} listaPercurso={listaPercurso} setListaPercurso={setListaPercurso} setPercursoOk={setPercursoOk} />
                        }
                        {pages.tagPages === 3 &&
                            <TabSegEProdPredominante formManifesto={form} listaSeguro={listaSeguro} setListaSeguro={setListaSeguro} responsavelSeguro={responsavelSeguro} tipoCarga={tipoCarga} unidadePeso={unidadePeso} listaDocs={listaDocs} />
                        }
                    </Col>
                </Row>
            </FormGW>
        </div>
    );
}